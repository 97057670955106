import { React, useState } from "react";
import "./Validation.css";
import { useStateValue } from "./StateProvider";
import { db } from "./firebase";
import { useHistory } from "react-router-dom";
import { auth } from "./firebase";
import { Helmet } from "helmet";



function UserDetails() {
  const [info, setInfo] = useState([]);
  const [storename, Setstorename] = useState("");
  const history = useHistory();
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);


  window.onload = function () {
    Fetchdata();
  };

  // Fetch the required data using the get() method
  const Fetchdata = () => {
    db.collection("shopUsers")
      .where("Email", "in", ["grahamg@broadmoor.nhs"])
      .get()
      .then((querySnapshot) => {
        //db.collection("All").get().then((querySnapshot) => {
        // Loop through the data and store
        // it in array to display
        querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        querySnapshot.forEach((element) => {
          var data = element.data();
          setInfo((arr) => [...arr, data]);
        });
        unsub();
      });

  };

  const [search, setSearch] = useState("");
  const [contacts, setContacts] = useState([]); //used just to store contacts from api
  const [contactsData, setContactsData] = useState(contacts); //iterate this in table

  const unsub = db.collection("users").onSnapshot(() => {});

  <div id="some-id"></div>;

  // Display the result on the page
  return (
    <div>
      {info.map((data) => (
        <Frame
          Designation={data.Designation}
          id={data.id}
          Password={data.Password}
          Email={data.Email}
          oldpassword={data.oldpassword}
          timevalue={data.timevalue}
          WardName={data.WardName}
          MadePurchase={data.MadePurchase}
        />
      ))}
    </div>
  );
}

// Define how each display entry will be structured
const Frame = ({
  id,
  Designation,
  Password,
  Email,
  oldpassword,
  timevalue,
  WardName,
  MadePurchase,
}) => {
  // const [{basket,user}, dispatch] = useStateValue();
  const [{ user }] = useStateValue();
  const selectShortlistedApplicant = (e) => {
    const checked = e.target.checked;

    if (checked) {
      //checked
      //alert("Selected");
    } else {
      //unchecked  setDisable(e.target.value);
      alert("Select a size first!");
      // alert(" Successfully unticked");
    }
  };

  const [wardName, SetWardName] = useState("");

  function update(variable1) {
    console.log(variable1);
    console.log(wardName);
    let collectionRef = db
      .collection("users")
      .where("Email", "in", [variable1]);

    collectionRef.get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        doc.ref
          .update({
            WardName: wardName,
          })
          .catch(function (error) {
            console.error("Error removing document: ", error);
          });
      });
    });
  }

  function yesbutton(val) {
    console.log(val);

    db.collection("shopUsers")
      .where("id", "in", [val])
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          doc.ref
            .update({
              //just update the price of item
              MadePurchase: "YesNew",
            })
            .then((docRef) => {
              //   window.location.reload();
            })

            .catch(function (error) {
              console.error("Error updating document: ", error);
            });
        });
      });
  }

  function nobutton(val) {
    console.log(val);
    db.collection("shopUsers")
      .where("id", "in", [val])
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          doc.ref
            .update({
              //just update the price of item
              MadePurchase: "No",
            })
            .then((docRef) => {
              window.location.reload();
            })

            .catch(function (error) {
              console.error("Error updating document: ", error);
            });
        });
      });
  }

  function yesbstaffpat(val) {
    console.log(val);
    db.collection("shopUsers")
      .where("id", "in", [val])
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          doc.ref
            .update({
              //just update the price of item
              Designation: "Patient",
            })
            .then((docRef) => {
              window.location.reload();
            })

            .catch(function (error) {
              console.error("Error updating document: ", error);
            });
        });
      });
  }

  function yesbstaffstaff(val) {
    console.log(val);
    db.collection("shopUsers")
      .where("id", "in", [val])
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          doc.ref
            .update({
              //just update the price of item
              Designation: "Staff",
            })
            .then((docRef) => {
              window.location.reload();
            })

            .catch(function (error) {
              console.error("Error updating document: ", error);
            });
        });
      });
  }

  function yesuserid(val) {
    console.log(val);
    db.collection("shopUsers")
      .where("id", "in", [val])
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          doc.ref
            .update({
              //just update the price of item
              id: "a7ylJkSNIWcWa4HvA2LSz4vUCYl2",
            })
            .then((docRef) => {
              window.location.reload();
            })

            .catch(function (error) {
              console.error("Error updating document: ", error);
            });
        });
      });
  }

  //a7ylJkSNIWcWa4HvA2LSz4vUCYl2

  return (
    <div className="mainly1">
      <p>{Email}</p>
      <td>{}</td>
      <p>{id}</p>
      <td>{}</td>
      <td>{}</td>
      <p>{Designation}</p>

      <p>{Password}</p>

      <p> {oldpassword} </p>
      <p> {WardName} </p>

      <p> {MadePurchase} </p>

      <button
        onClick={() => yesbutton(id)}
        className="allPaidProducts__updateproducts"
      >
        Yes Purchase
      </button>

      <button
        onClick={() => nobutton(id)}
        className="allPaidProducts__backinfo1"
      >
        No Purchase
      </button>

      <button
        onClick={() => yesbstaffpat(id)}
        className="allPaidProducts__updateproducts"
      >
        Yes Patient
      </button>

      <button
        onClick={() => yesbstaffstaff(id)}
        className="allPaidProducts__updateproducts"
      >
        Yes Staff
      </button>

      <button
        onClick={() => yesuserid(id)}
        className="allPaidProducts__updateproducts"
      >
        Yes Staff
      </button>
    </div>
  );
};

export default UserDetails;

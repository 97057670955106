import React from "react";
import "./Order.css";
import moment from "moment";
import CheckoutProduct from "./CheckoutProduct";
import CurrencyFormat from "react-currency-format";
import { Helmet } from "helmet";

function Order({ order }) {
  var formatter = new Intl.NumberFormat("gbp", {
    style: "currency",
    currency: "GBP",

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  return (
    <div className="order">
      <h2>Order</h2>
      <p>{order.data.dateview}</p>
      <p className="order__id">
        <small>Payment id - {order.id}</small>
      </p>
      <p className="order__id">
        <small>{order.storename}</small>
      </p>

      {order.data.basket?.map((item) => (
        <CheckoutProduct
          id={item.id}
          storename={item.storename}
          title={item.title}
          size={item.size}
          image={item.image}
          price={item.price}
          rating={item.rating}
          status={item.status}
          process={item.status}
          hideButton
        />
      ))}
      <CurrencyFormat
        renderText={(value) => (
          <h3 className="order__total">Order Total: {value}</h3>
        )}
        decimalScale={2}
        value={formatter.format(order.data.amount)}
        displayType={"text"}
        thousandSeparator={true}
        prefix={"£"}
      />
      <h5 className="order__total">
        The total includes delivery fees and £1.50 service fee
      </h5>
    </div>
  );
}

export default Order;

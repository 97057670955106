import React from 'react';
import Modal from 'react-modal';
import "./Searchstuff.css";
import { useStateValue } from "./StateProvider";
import { db } from "./firebase";
import { auth } from "./firebase";


Modal.setAppElement('#root'); // Set the root element to enable accessibility

 

 

function ImageModal({ isOpen, closeModal, image, description,sizepuddle,trouser32L,Prodinfo,id,
trouser32R,
trouser32S,
trouser34L,
trouser34R,
trouser34S,
trouser36L,
trouser36R,
trouser36S,
trouser38L,
trouser38R,
trouser38S,
trouser40L,
trouser40R,
trouser40S,
trouser42L,
trouser42R,
trouser42S,
trouser44S,
trouser44L,
trouser44R,
sizelarge,
sizemed,
sizesm,
sizexs,
sizexl,
sizexxl,
sizexxxl,
sizexxxxl,
size,
storename,
shoe7,
shoe6,
shoe8,
shoe9,
shoe10,
shoe11,
shoe12,
shoe13,
shoe6half,
shoe7half,
shoe8half,
shoe9half,
shoe10half,
shoe11half,
shoe12half,
shoe13half ,
onefitall,
price,
rating,
title,
  Stddvd,
  Bluedvd,
  MusicCd,
  Electricalitem,
  status,
prodinfo,
colour, 
handleImageClick,}) {


 const [{ user }] = useStateValue();
 const selectShortlistedApplicant = (e) => {
    const checked = e.target.checked;

    if (checked) {
      //checked
console.log("got this far")
console.log(id)
console.log(size)
console.log(storename)
console.log(title)
console.log(image)
console.log(price)
console.log(rating)
console.log(Prodinfo)
console.log(colour)
console.log(size)
handleImageClick(id , size,storename,title,image,price,rating,Prodinfo,colour)
//addtocart(id);



     
      //alert("Selected");
    } else {
      console.log(id);
      let collectionRef = db
      .collection("cart")
      .doc(user?.uid)
      .collection("orders");

    collectionRef
      .where("id", "==", id)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          doc.ref
            .delete()
            .then(() => {
              console.log("Document successfully deleted!");
            })
            .catch(function (error) {
              console.error("Error removing document: ", error);
            });
        });
      });



    //unchecked  setDisable(e.target.value);
    alert("Removed from basket!");
    // alert(" Successfully unticked");
  }
  };






  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      handleImageClick={handleImageClick}
      contentLabel="Image Modal"
    >
    <div className="Modal__content"> 
  <img className="Modal__content img" src={image} alt="Large Image" />
     <div className="container_modal">
          <h1>Store - {storename} </h1>
          <h1> {title} </h1>
        </div>
    
       <div className="container_desc">   
       <p>{Prodinfo}</p>
       </div>
       <p>{id}</p>
       

       <div className="item_rating">
        {Array(rating)
          .fill()
          .map((_, i) => (
            <p>🌟</p>
          ))}
      </div>
        <h1> Price -  £{price}</h1>
        

 <div className="item_size">
      {onefitall === "Yes" ? (
        <p>
          {" "}
          One Size Fits All{" "}
          <input
            type="checkbox"
            className="checkbox_values"
            value="1"
            onClick={(e) => {
              size = "One Size Fits All";
              selectShortlistedApplicant(e);
            }}
          />{" "}
        </p>
      ) : null}

      {trouser32S === "yes" ? (
        <p>
          {" "}
          Size 32S:{" "}
          <input
            type="checkbox"
            className="checkbox_values"
            value="1"
            onClick={(e) => {
              size = "32 Short";
              selectShortlistedApplicant(e);
            }}
          />{" "}
        </p>
      ) : null}
      {trouser32R === "yes" ? (
        <p>
          {" "}
          Size 32R:{" "}
          <input
            type="checkbox"
            className="checkbox_values"
            value="1"
            onClick={(e) => {
              size = "32 Regular";
              selectShortlistedApplicant(e);
            }}
          />{" "}
        </p>
      ) : null}

      {trouser32L === "yes" ? (
        <p>
          {" "}
          Size 32L:{" "}
          <input
            type="checkbox"
            className="checkbox_values"
            value="1"
            onClick={(e) => {
              size = "32 Long";
              selectShortlistedApplicant(e);
            }}
          />{" "}
        </p>
      ) : null}
      {trouser34S === "yes" ? (
        <p>
          {" "}
          Size 34S:{" "}
          <input
            type="checkbox"
            className="checkbox_values"
            value="1"
            onClick={(e) => {
              size = "34 Short";
              selectShortlistedApplicant(e);
            }}
          />{" "}
        </p>
      ) : null}

      {trouser34R === "yes" ? (
        <p>
          {" "}
          Size 34R:{" "}
          <input
            type="checkbox"
            className="checkbox_values"
            value="1"
            onClick={(e) => {
              size = "34 Regular";
              selectShortlistedApplicant(e);
            }}
          />{" "}
        </p>
      ) : null}

      {trouser34L === "yes" ? (
        <p>
          {" "}
          Size 34L:{" "}
          <input
            type="checkbox"
            className="checkbox_values"
            value="1"
            onClick={(e) => {
              size = "34 Long";
              selectShortlistedApplicant(e);
            }}
          />{" "}
        </p>
      ) : null}

      {trouser36S === "yes" ? (
        <p>
          {" "}
          Size 36S:{" "}
          <input
            type="checkbox"
            className="checkbox_values"
            value="1"
            onClick={(e) => {
              size = "36 Short";
              selectShortlistedApplicant(e);
            }}
          />{" "}
        </p>
      ) : null}
      {trouser36R === "yes" ? (
        <p>
          {" "}
          Size 36R:{" "}
          <input
            type="checkbox"
            className="checkbox_values"
            value="1"
            onClick={(e) => {
              size = "36 Regular";
              selectShortlistedApplicant(e);
            }}
          />{" "}
        </p>
      ) : null}
    </div>

    <div className="item_size">
      {trouser36L === "yes" ? (
        <p>
          {" "}
          Size 36L:{" "}
          <input
            type="checkbox"
            className="checkbox_values"
            value="1"
            onClick={(e) => {
              size = "36 Long";
              selectShortlistedApplicant(e);
            }}
          />{" "}
        </p>
      ) : null}

      {trouser38S === "yes" ? (
        <p>
          {" "}
          Size 38S:{" "}
          <input
            type="checkbox"
            className="checkbox_values"
            value="1"
            onClick={(e) => {
              size = "38 Short";
              selectShortlistedApplicant(e);
            }}
          />{" "}
        </p>
      ) : null}
      {trouser38R === "yes" ? (
        <p>
          {" "}
          Size 38R:{" "}
          <input
            type="checkbox"
            className="checkbox_values"
            value="1"
            onClick={(e) => {
              size = "38 Regular";
              selectShortlistedApplicant(e);
            }}
          />{" "}
        </p>
      ) : null}

      {trouser38L === "yes" ? (
        <p>
          {" "}
          Size 38L:{" "}
          <input
            type="checkbox"
            className="checkbox_values"
            value="1"
            onClick={(e) => {
              size = "38 Long";
              selectShortlistedApplicant(e);
            }}
          />{" "}
        </p>
      ) : null}
      {trouser40S === "yes" ? (
        <p>
          {" "}
          Size 40S:{" "}
          <input
            type="checkbox"
            className="checkbox_values"
            value="1"
            onClick={(e) => {
              size = "40 Short";
              selectShortlistedApplicant(e);
            }}
          />{" "}
        </p>
      ) : null}
      {trouser40R === "yes" ? (
        <p>
          {" "}
          Size 40R:{" "}
          <input
            type="checkbox"
            className="checkbox_values"
            value="1"
            onClick={(e) => {
              size = "40 Regular";
              selectShortlistedApplicant(e);
            }}
          />{" "}
        </p>
      ) : null}

      {trouser40L === "yes" ? (
        <p>
          {" "}
          Size 40L:{" "}
          <input
            type="checkbox"
            className="checkbox_values"
            value="1"
            onClick={(e) => {
              size = "40 Long";
              selectShortlistedApplicant(e);
            }}
          />{" "}
        </p>
      ) : null}
    </div>

    <div className="item_size">
      {trouser42S === "yes" ? (
        <p>
          {" "}
          Size 42S:{" "}
          <input
            type="checkbox"
            className="checkbox_values"
            value="1"
            onClick={(e) => {
              size = "42 Short";
              selectShortlistedApplicant(e);
            }}
          />{" "}
        </p>
      ) : null}
      {trouser42R === "yes" ? (
        <p>
          {" "}
          Size 42R:{" "}
          <input
            type="checkbox"
            className="checkbox_values"
            value="1"
            onClick={(e) => {
              size = "42 Regular";
              selectShortlistedApplicant(e);
            }}
          />{" "}
        </p>
      ) : null}

      {trouser42L === "yes" ? (
        <p>
          {" "}
          Size 42L:{" "}
          <input
            type="checkbox"
            className="checkbox_values"
            value="1"
            onClick={(e) => {
              size = "42 Long";
              selectShortlistedApplicant(e);
            }}
          />{" "}
        </p>
      ) : null}
      {trouser44S === "yes" ? (
        <p>
          {" "}
          Size 44S:{" "}
          <input
            type="checkbox"
            className="checkbox_values"
            value="1"
            onClick={(e) => {
              size = "44 Short";
              selectShortlistedApplicant(e);
            }}
          />{" "}
        </p>
      ) : null}

      {trouser44R === "yes" ? (
        <p>
          {" "}
          Size 44R:{" "}
          <input
            type="checkbox"
            className="checkbox_values"
            value="1"
            onClick={(e) => {
              size = "44 Regular";
              selectShortlistedApplicant(e);
            }}
          />{" "}
        </p>
      ) : null}

      {trouser44L === "yes" ? (
        <p>
          {" "}
          Size 44L:{" "}
          <input
            type="checkbox"
            className="checkbox_values"
            value="1"
            onClick={(e) => {
              size = "44 Long";
              selectShortlistedApplicant(e);
            }}
          />{" "}
        </p>
      ) : null}

      {sizexs === "yes" ? (
        <p>
          {" "}
          size XSm:{" "}
          <input
            type="checkbox"
            className="checkbox_values"
            value="1"
            onClick={(e) => {
              size = "Size XS";
              selectShortlistedApplicant(e);
            }}
          />{" "}
        </p>
      ) : null}
      {sizesm === "yes" ? (
        <p>
          {" "}
          size Sm:{" "}
          <input
            type="checkbox"
            className="checkbox_values"
            value="1"
            onClick={(e) => {
              size = "Size Small";
              selectShortlistedApplicant(e);
            }}
          />{" "}
        </p>
      ) : null}

      {sizemed === "yes" ? (
        <p>
          {" "}
          size Med:{" "}
          <input
            type="checkbox"
            className="checkbox_values"
            value="1"
            onClick={(e) => {
              size = "Size Medium";
              selectShortlistedApplicant(e);
            }}
          />{" "}
        </p>
      ) : null}
      {sizelarge === "yes" ? (
        <p>
          {" "}
          size Lrg:{" "}
          <input
            type="checkbox"
            className="checkbox_values"
            value="1"
            onClick={(e) => {
              size = "Size Large";
              selectShortlistedApplicant(e);
            }}
          />{" "}
        </p>
      ) : null}

      {sizexl === "yes" ? (
        <p>
          {" "}
          size XLg:{" "}
          <input
            type="checkbox"
            className="checkbox_values"
            value="1"
            onClick={(e) => {
              size = "Size XL";
              selectShortlistedApplicant(e);
            }}
          />{" "}
        </p>
      ) : null}

      {sizexxl === "yes" ? (
        <p>
          {" "}
          size 2XL:{" "}
          <input
            type="checkbox"
            className="checkbox_values"
            value="1"
            onClick={(e) => {
              size = "Size XXL";
              selectShortlistedApplicant(e);
            }}
           
          />{" "}
        </p>
      ) : null}

      {sizexxxl === "yes" ? (
        <p>
          {" "}
          size 3XL:{" "}
          <input
            type="checkbox"
            className="checkbox_values"
            value="1"
            onClick={(e) => {
              size = "Size XXXL";
              selectShortlistedApplicant(e);
            }}
          />{" "}
        </p>
      ) : null}
      {sizexxxxl === "yes" ? (
        <p>
          {" "}
          size 4XL{" "}
          <input
            type="checkbox"
            className="checkbox_values"
            value="1"
            onClick={(e) => {
              size = "Size XXXXL";
              selectShortlistedApplicant(e);
            }}
          />{" "}
        </p>
      ) : null}

      {shoe6 === "yes" ? (
        <p>
          {" "}
          size 6:{" "}
          <input
            type="checkbox"
            className="checkbox_values"
            value="1"
            onClick={(e) => {
              size = "shoe 6";
              selectShortlistedApplicant(e);
            }}
          />{" "}
        </p>
      ) : null}

      {shoe6half === "yes" ? (
        <p>
          {" "}
          size 6.5:{" "}
          <input
            type="checkbox"
            className="checkbox_values"
            value="1"
            onClick={(e) => {
              size = "shoe 6.5";
              selectShortlistedApplicant(e);
            }}
          />{" "}
        </p>
      ) : null}

      {shoe7 === "yes" ? (
        <p>
           
        size 7:{""}
          <input
            type="checkbox"
            className="checkbox_values"
            value="1"
            onClick={(e) => {
              size = "shoe 7";
              selectShortlistedApplicant(e);
            }}
          />{""}
        </p>
      ) : null}

      {shoe7half === "yes" ? (
        <p>
          
          size 7.5:{" "}
          <input
            type="checkbox"
            className="checkbox_values"
            value="1"
            onClick={(e) => {
              size = "shoe 7.5";
              selectShortlistedApplicant(e);
            }}
          />{" "}
        </p>
      ) : null}

      {shoe8 === "yes" ? (
        <p>
          {" "}
          size 8:{" "}
          <input
            type="checkbox"
            className="checkbox_values"
            value="1"
            onClick={(e) => {
              size = "shoe 8";
              selectShortlistedApplicant(e);
            }}
          />{" "}
        </p>
      ) : null}

      {shoe8half === "yes" ? (
        <p>
          {" "}
          size 8.5:{" "}
          <input
            type="checkbox"
            className="checkbox_values"
            value="1"
            onClick={(e) => {
              size = "shoe 8.5";
              selectShortlistedApplicant(e);
            }}
          />{" "}
        </p>
      ) : null}

      {shoe9 === "yes" ? (
        <p>
          {" "}
          size 9:{" "}
          <input
            type="checkbox"
            className="checkbox_values"
            value="1"
            onClick={(e) => {
              size = "shoe 9";
              selectShortlistedApplicant(e);
            }}
          />{" "}
        </p>
      ) : null}

      {shoe9half === "yes" ? (
        <p>
          {" "}
          size 9.5:{" "}
          <input
            type="checkbox"
            className="checkbox_values"
            value="1"
            onClick={(e) => {
              size = "shoe 9.5";
              selectShortlistedApplicant(e);
            }}
          />{" "}
        </p>
      ) : null}

      {shoe10 === "yes" ? (
        <p>
          {" "}
          size 10:{" "}
          <input
            type="checkbox"
            className="checkbox_values"
            value="1"
            onClick={(e) => {
              size = "shoe 10";
              selectShortlistedApplicant(e);
            }}
          />{" "}
        </p>
      ) : null}

      {shoe10half === "yes" ? (
        <p>
          {" "}
          size 10.5:{" "}
          <input
            type="checkbox"
            className="checkbox_values"
            value="1"
            onClick={(e) => {
              size = "shoe 10.5";
              selectShortlistedApplicant(e);
            }}
          />{" "}
        </p>
      ) : null}

      {shoe11 === "yes" ? (
        <p>
          {" "}
          size 11:{" "}
          <input
            type="checkbox"
            className="checkbox_values"
            value="1"
            onClick={(e) => {
              size = "shoe 11";
              selectShortlistedApplicant(e);
            }}
          />{" "}
        </p>
      ) : null}

      {shoe11half === "yes" ? (
        <p>
          {" "}
          size 11.5:{" "}
          <input
            type="checkbox"
            className="checkbox_values"
            value="1"
            onClick={(e) => {
              size = "shoe 11.5";
              selectShortlistedApplicant(e);
            }}
          />{" "}
        </p>
      ) : null}

      {shoe12 === "yes" ? (
        <p>
          {" "}
          size 12:{" "}
          <input
            type="checkbox"
            className="checkbox_values"
            value="1"
            onClick={(e) => {
              size = "shoe 12";
              selectShortlistedApplicant(e);
            }}
          />{" "}
        </p>
      ) : null}

      {shoe12half === "yes" ? (
        <p>
          {" "}
          size 12.5:{" "}
          <input
            type="checkbox"
            className="checkbox_values"
            value="1"
            onClick={(e) => {
              size = "shoe 12.5";
              selectShortlistedApplicant(e);
            }}
          />{" "}
        </p>
      ) : null}

      {shoe13 === "yes" ? (
        <p>
          {" "}
          size 13:{" "}
          <input
            type="checkbox"
            className="checkbox_values"
            value="1"
            onClick={(e) => {
              size = "shoe 13";
            
              selectShortlistedApplicant(e);
              
            }
            
            }
            
              
          />{" "}
        </p>
      ) : null}

      {shoe13half === "yes" ? (
        <p>
          {" "}
          size 13.5:{" "}
          <input
            type="checkbox"
            className="checkbox_values"
            value="1"
            onClick={(e) => {
              size = "shoe 13.5";
              selectShortlistedApplicant(e);
              
            }}
           // onClick={handleImageClick(id)}
          />{" "}
        </p>
      ) : null}




       
        <td>
          {" "}
          {Stddvd === "yes" ? (
            <p>
              {" "}
              <a2>Standard Dvd: </a2>
              <input
                type="checkbox"
                className="checkbox_values"
                value="1"
                onClick={(e) => {
                  size = "Standard Dvd";
                  selectShortlistedApplicant(e);
                }}
              />{" "}
              ← Press Here to add to basket{" "}
            </p>
          ) : null}
        </td>

        <td>
          {" "}
          {Bluedvd === "yes" ? (
            <p>
              {" "}
              Blu-Ray Dvd:{" "}
              <input
                type="checkbox"
                className="item"
                value="1"
                onClick={(e) => {
                  size = "Standard Dvd";
                  selectShortlistedApplicant(e);
                }}
              />{" "}
              ← Press Here to add to basket{" "}
            </p>
          ) : null}
        </td>

        <td>
          {" "}
          {MusicCd === "yes" ? (
            <p>
              {" "}
              Music Cd:{" "}
              <input
                type="checkbox"
                className="item"
                value="1"
                onClick={(e) => {
                  size = "Music Cd";
                  selectShortlistedApplicant(e);
                }}
              />{" "}
              ← Press Here to add to basket{" "}
            </p>
          ) : null}
        </td>

       


{Electricalitem === "yes" ? (
          <p>
            {" "}
            <strong> Check Box to add to basket: </strong>
            <input
              type="checkbox"
              className="checkbox_values1"
              value="1"
              onClick={(e) => {
                size = "electrical item";
                selectShortlistedApplicant(e);
              }}
            />{" "}
           
          </p>
        ) : null}





    </div>
     
      <button className= "btn-btn-danger"  onClick={closeModal}>Close</button>

   
      
      </div>
    </Modal>
  );
}

export default ImageModal;
// <img src={imageUrl} alt="Large Image" />
 //     <p>{description}</p>
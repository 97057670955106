
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

//import firebase from 'firebase/app';
//import 'firebase/auth';
//import 'firebase/firestore';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAiwqCh2KFonHUCbOgWB3DtTcZVEPJQPc8",
  authDomain: "shopping-bd03e.firebaseapp.com",
  projectId: "shopping-bd03e",
  storageBucket: "shopping-bd03e.appspot.com",
  messagingSenderId: "396724005345",
  appId: "1:396724005345:web:2b06f8b6a396987a96caa0",
  measurementId: "G-P1WNT612ZB",
};
// Initialize Firebase
const firebaseApp = firebase.initializeApp(firebaseConfig);


const db = firebaseApp.firestore();

const auth = firebase.auth();

export {db , auth};
export {firebase};

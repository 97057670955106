import React, { useEffect } from "react";
import "./App.css";
import Header from "./Header";
import FinanceHeader from "./FinanceHeader";
import AuthHeader from "./AuthHeader"
import PostalHeader from "./PostalHeader"
import Home from "./Home";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { auth } from "./firebase";
import { useStateValue } from "./StateProvider";
import Finance from "./Finance";
import ShoppingCart from "./ShoppingCart";
import StockControl from "./StockControl";
import Authorisation from "./Authorisation";
import Login from "./Login";
import LoginCreateAccount from "./LoginCreateAccount";
import LoginNewPassword from "./LoginNewPassword";
import AuthNewPassword from "./AuthNewPassword"
import FinanceNewPassword from "./FinanceNewPassword";
import PostalNewPassword from "./PostalNewPassword";
import Prods from "./Prods";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Orders from "./Orders";
import AllPaidItems from "./AllPaidItems";
import AllPaidNewItems from "./AllPaidNewItems";
import AllReturnedItems from "./AllReturnedItems";
import AllAcceptedItems from "./AllAcceptedItems";
import Validation from "./Validation";
import CurrysAll from "./CurrysAll";
import CurrysTv from "./CurrysTv";
import CurrysGames from "./CurrysGames";
import CurrysHealth from "./CurrysHealth";
import JDAccessories from "./JDSportsAccess";
import JDSportsAll from "./JDSportsAll";
import JDSportsJackets from "./JDSportsJackets";
import JDSportsShirts from "./JDSportsShirts";
import JDSportsShoes from "./JDSportsShoes";
import JDSportsTops from "./JDSportsTops";
import JDSportsTrousers from "./JDSportsTrousers";
import NikeAccess from "./NikeAccess";
import NikeAll from "./NikeAll";
import NikeJackets from "./NikeJackets";
import NikeShirts from "./NikeShirts";
import NikeShoes from "./NikeShoes";
import NikeTops from "./NikeTops";
import NikeTrousers from "./NikeTrousers";
import CottonAccess from "./CottonAccess";
import CottonAll from "./CottonAll";
import CottonJackets from "./CottonJackets";
import CottonShirts from "./CottonShirts";
import CottonShoes from "./CottonShoes";
import CottonTops from "./CottonTops";
import CottonTrousers from "./CottonTrousers";
import HSamuelAll from "./HSamuelAll";
import HSamuelJewels from "./HSamuelJewels";
import HSamuelWatches from "./HSamuelWatches";
import JoeBAccess from "./JoeBAccess";
import JoeBAll from "./JoeBAll";
import JoeBJackets from "./JoeBJackets";
import JoeBShirts from "./JoesBShirts";
import JoeBShoes from "./JoeBShoes";
import JoeBTops from "./JoeBTops";
import JoeBTrousers from "./JoeBTrousers";
import MandmAccess from "./MandmAccess";
import MandmAll from "./MandmAll";
import MandmJackets from "./MandmJackets";
import MandmShirts from "./MandmShirts";
import MandmShoes from "./MandmShoes";
import MandmTops from "./MandmTops";
import MandmTrousers from "./MandmTrousers";
import SalesAll from "./SaleAll";
import SalesClothing from "./SalesClothing";
import SalesDvd from "./SalesDvd";
import SalesElectrical from "./SalesElectrical";
import SalesMusic from "./SalesMusic";
import Validation2 from "./Validation2";
import Validation3 from "./Validation3";
import UserDetails from "./UserDetails";
import PostalAuth from "./PostalAuth";
import FinanceLogin from "./FinanceLogin"
import AuthLogin from "./AuthLogin"
import PostalLogin from "./PostalLogin"
import CurrysElectrical from "./CurrysElectrical"
import Searchstuff from "./Searchstuff"
import Finance2 from "./Finance2"
import OutOfStockOrder from "./OutOfStockOrder"
import OutOfStockOrders from "./OutOfStockOrders";
import DailyMedicationChart from "./DailyMedicationChart";
import MedicationChart from "./MedicationChart";
import Imagecodeofman from "./Imagecodeofman"
import ToolsLogin from "./ToolsLogin";
import ToolsNewPassword from "./ToolsNewPassword";
import ToolsHeader from "./ToolsHeader";
import PrintableComp from "./PrintableComp"
import Validation3_Watches from "./Validation3_Watches";
import Validation3_Sweatshirts from "./Validation3_Sweatshirts";
import Validation3_Jewellery from "./Validation3_Jewellery";
import Validation3_Knitwear from "./Validation3_Knitwear";
import Validation3_DVD from "./Validation3_DVD";
import Validation3_Elactrical from "./Validation3_Elactrical";
import Validation3_Health from "./Validation3_Health";
import Validation3_Trousers from "./Validation3_Trousers";
import Validation3_Gaming from "./Validation3_Gaming";
import Validation3_Shirts from "./Validation3_Shirts";
import Validation3_Shoes from "./Validation3_Shoes";
import Validation3_TV from "./Validation3_TV";
import Validation2_DVD from "./Validation2_DVD";
import Validation2_Electrical from "./Validation2_Electrical";
import Validation2_Gaming from "./Validation2_Gaming";
import Validation2_Health from "./Validation2_Health";
import Validation2_Jewellery from "./Validation2_Jewellery";
import Validation2_Knitwear from "./Validation2_Knitwear";
import Validation2_Shirts from "./Validation2_Shirts";
import Validation2_shoes from "./Validation2_shoes";
import Validation2_Sweatshirts from "./Validation2_Sweatshirts";
import Validation2_Trousers from "./Validation2_Trousers";
import Validation2_TVs from "./Validation2_TVs";
import Validation2_Watches from "./Validation2_Watches";
import OutOfStock from "./OutOfStock";
import Modal from "./Modal";
import NewAdditions from "./NewAdditions"
import medicationcalendar from "./MedicationCalendar"
import MedicationManager from './MedicationManager';


//stripe api keys
const promise = loadStripe(
  //This is the test key
   // "pk_test_51JptBTF3OrUXSAv9IkuTDY3X6NV4q6XIkuLgTynmy0BIJfpsRP2K10mIvuflHWuJsIMOrI6LRBk7D0Uuu0Vd9Cwg008zQJHgH6"
  //Live Key
   "pk_live_51JptBTF3OrUXSAv9dlwlGgLf7vyjOKznEn1ar7DkTqf9De3arKaBk0S8CZeRG4XJqFSNasawd7nT2rFpUURVmufz00VwiclRYm"
   );

   
  
function App() {
  const [, dispatch] = useStateValue();


  

  useEffect(() => {
    //will only run once when the app component loads
    auth.onAuthStateChanged((authUser) => {
      console.log("The user is >>>>", authUser);

      if (authUser) {
        //the auth user just logged in / user was already logged in
        dispatch({
          type: "SET_USER",
          user: authUser,
        });
      } else {
        //user is logged out
        dispatch({
          type: "SET_USER",
          user: null,
        });
      }
    });
  }, [dispatch]);

 

  return (
    <Router>
      <div className="app">
        <Switch>
          <Route path="/Orders">
            <Header />
            <Orders />
          </Route>
          <Route path="/OutOfStockOrders">
            <Header />
            <OutOfStockOrders />
          </Route>
          <Route path="/Prods">
            <ToolsHeader />
            <Prods />
          </Route>
          <Route path="/PostalAuth">
            <PostalHeader />
            <PostalAuth />
          </Route>
          <Route path="/PrintableComp">
            <PrintableComp />
          </Route>
          <Route path="/Searchstuff">
            <Header />
            <Searchstuff />
            <Modal/>
          </Route>
          <Route path="/NewAdditions">
            <Header />
            < NewAdditions />
            <Modal/>
          </Route>
          <Route path="/SalesAll">
            <Header />
            <SalesAll />
          </Route>
          <Route path="/SalesClothing">
            <Header />
            <SalesClothing />
          </Route>
          <Route path="/SalesDvd">
            <Header />
            <SalesDvd />
          </Route>
          <Route path="/SalesElectrical">
            <Header />
            <SalesElectrical />
          </Route>
          <Route path="/SalesMusic">
            <Header />
            <SalesMusic />
          </Route>
          <Route path="/MandmAccess">
            <Header />
            <MandmAccess />
          </Route>
          <Route path="/MandmAll">
            <Header />
            <MandmAll />
          </Route>
          <Route path="/MandmJackets">
            <Header />
            <MandmJackets />
          </Route>
          <Route path="/MandmShirts">
            <Header />
            <MandmShirts />
          </Route>
          <Route path="/MandmShoes">
            <Header />
            <MandmShoes />
          </Route>
          <Route path="/MandmTops">
            <Header />
            <MandmTops />
          </Route>
          <Route path="/MandmTrousers">
            <Header />
            <MandmTrousers />
          </Route>
          <Route path="/JoeBAccess">
            <Header />
            <JoeBAccess />
          </Route>
          <Route path="/JoeBAll">
            <Header />
            <JoeBAll />
          </Route>
          <Route path="/JoeBJackets">
            <Header />
            <JoeBJackets />
          </Route>
          <Route path="/JoeBShirts">
            <Header />
            <JoeBShirts />
          </Route>
          <Route path="/JoeBShoes">
            <Header />
            <JoeBShoes />
          </Route>
          <Route path="/JoeBTops">
            <Header />
            <JoeBTops />
          </Route>
          <Route path="/JoeBTrousers">
            <Header />
            <JoeBTrousers />
          </Route>
          <Route path="/HSamuelAll">
            <Header />
            <HSamuelAll />
          </Route>
          <Route path="/HSamuelJewels">
            <Header />
            <HSamuelJewels />
          </Route>
          <Route path="/HSamuelWatches">
            <Header />
            <HSamuelWatches />
          </Route>
          <Route path="/JDAccessories">
            <Header />
            <JDAccessories />
          </Route>
          <Route path="/CottonAccess">
            <Header />
            <CottonAccess />
          </Route>
          <Route path="/CottonAll">
            <Header />
            <CottonAll />
          </Route>
          <Route path="/CottonJackets">
            <Header />
            <CottonJackets />
          </Route>
          <Route path="/CottonShirts">
            <Header />
            <CottonShirts />
          </Route>
          <Route path="/CottonShoes">
            <Header />
            <CottonShoes />
          </Route>
          <Route path="/CottonTops">
            <Header />
            <CottonTops />
          </Route>
          <Route path="/CottonTrousers">
            <Header />
            <CottonTrousers />
          </Route>
         
          <Route path="/NikeAccess">
            <Header />
            <NikeAccess />
          </Route>
          <Route path="/NikeAll">
            <Header />
            <NikeAll />
          </Route>
          <Route path="/NikeJackets">
            <Header />
            <NikeJackets />
          </Route>
          <Route path="/NikeShirts">
            <Header />
            <NikeShirts />
          </Route>
          <Route path="/NikeShoes">
            <Header />
            <NikeShoes />
          </Route>
          <Route path="/NikeTops">
            <Header />
            <NikeTops />
          </Route>
          <Route path="/NikeTrousers">
            <Header />
            <NikeTrousers />
          </Route>
          <Route path="/JDSportsAll">
            <Header />
            <JDSportsAll />
          </Route>
          <Route path="/JDSportsJackets">
            <Header />
            <JDSportsJackets />
          </Route>
          <Route path="/JDSportsShirts">
            <Header />
            <JDSportsShirts />
          </Route>
          <Route path="/JDSportsShoes">
            <Header />
            <JDSportsShoes />
          </Route>
          <Route path="/JDSportsTops">
            <Header />
            <JDSportsTops />
          </Route>
          <Route path="/JDSportsTrousers">
            <Header />
            <JDSportsTrousers />
          </Route>
          <Route path="/CurrysAll">
            <Header />
            <CurrysAll />
          </Route>
          <Route path="/CurrysElectrical">
            <Header />
            <CurrysElectrical />
          </Route>
          <Route path="/CurrysHealth">
            <Header />
            <CurrysHealth />
          </Route>
          <Route path="/CurrysTv">
            <Header />
            <CurrysTv />
          </Route>
          <Route path="/CurrysGames">
            <Header />
            <CurrysGames />
          </Route>
          <Route path="/AllPaidItems">
            <FinanceHeader />
            <AllPaidItems />
          </Route>
          <Route path="/AllPaidNewItems">
            <FinanceHeader />
            <AllPaidNewItems />
          </Route>
          <Route path="/AllReturnedItems">
            <FinanceHeader />
            <AllReturnedItems />
          </Route>
          <Route path="/AllAcceptedItems">
            <FinanceHeader />
            <AllAcceptedItems />
          </Route>
          <Route path="/Validation">
            <ToolsHeader />
            <Validation />
          </Route>
          <Route path="/Validation2">
          <ToolsHeader />
            <Validation2 />
          </Route>
          <Route path="/imagecodeofman">
          <Header />
            <Imagecodeofman />
          </Route>
          <Route path="/Validation3">
          <ToolsHeader />
            <Validation3 />
          </Route>
          <Route path="/Validation3_Watches">
          <ToolsHeader />
            <Validation3_Watches />
          </Route>
          <Route path="/Validation3_Shirts">
          <ToolsHeader />
            <Validation3_Shirts />
          </Route>
          <Route path="/Validation3_Shoes">
          <ToolsHeader />
            <Validation3_Shoes />
          </Route>
          <Route path="/Validation3_Sweatshirts">
          <ToolsHeader />
            <Validation3_Sweatshirts />
          </Route>
          <Route path="/Validation3_Gaming">
          <ToolsHeader />
            <Validation3_Gaming />
          </Route>
          <Route path="/Validation3_DVD">
          <ToolsHeader />
            <Validation3_DVD />
          </Route>
          <Route path="/Validation3_Health">
          <ToolsHeader />
            <Validation3_Health/>
          </Route>
          <Route path="/Validation3_TV">
          <ToolsHeader />
            <Validation3_TV />
          </Route>
          <Route path="/Validation3_Electrical">
          <ToolsHeader />
            <Validation3_Elactrical />
          </Route>
          <Route path="/Validation3_Jewellery">
          <ToolsHeader />
            <Validation3_Jewellery />
          </Route>
          <Route path="/Validation3_Knitwear">
          <ToolsHeader />
            <Validation3_Knitwear />
          </Route>
          <Route path="/Validation3_Trousers">
          <ToolsHeader />
            <Validation3_Trousers />
          </Route>
          <Route path="/Validation2_Watches">
          <ToolsHeader />
            <Validation2_Watches />
          </Route>
          <Route path="/OutOfStock">
          <ToolsHeader />
            <OutOfStock/>
          </Route>
          
          <Route path="/Validation2_Shirts">
          <ToolsHeader />
            <Validation2_Shirts />
          </Route>
          <Route path="/Validation2_Shoes">
          <ToolsHeader />
            <Validation2_shoes />
          </Route>
          <Route path="/Validation2_Sweatshirts">
          <ToolsHeader />
            <Validation2_Sweatshirts />
          </Route>
          <Route path="/Validation2_Gaming">
          <ToolsHeader />
            <Validation2_Gaming />
          </Route>
          <Route path="/Validation2_DVD">
          <ToolsHeader />
            <Validation2_DVD />
          </Route>
          <Route path="/Validation2_Health">
          <ToolsHeader />
            <Validation2_Health/>
          </Route>
          <Route path="/Validation2_TVs">
          <ToolsHeader />
            <Validation2_TVs />
          </Route>
          <Route path="/Validation2_Electrical">
          <ToolsHeader />
            <Validation2_Electrical />
          </Route>
          <Route path="/Validation2_Jewellery">
          <ToolsHeader />
            <Validation2_Jewellery />
          </Route>
          <Route path="/Validation2_Knitwear">
          <ToolsHeader />
            <Validation2_Knitwear />
          </Route>
          <Route path="/Validation2_Trousers">
          <ToolsHeader />
            <Validation2_Trousers />
          </Route>
          <Route path="/UserDetails">
            <UserDetails/>
          </Route>
          <Route path="/MedicationChart">
            <MedicationChart/>
          </Route>
          <Route path="/DailyMedicationChart">
            <DailyMedicationChart/>
          </Route>
          <Route path="/StockControl">
            <StockControl />
          </Route>
          <Route path="/LoginCreateAccount">
            <Header />
            <LoginCreateAccount />
          </Route>
          <Route path="/login">
            <Header />
            <Login />
          </Route>
          <Route path="/FinanceLogin">
            <FinanceHeader />
            <FinanceLogin />
          </Route>
          <Route path="/ToolsLogin">
            <ToolsHeader />
            <ToolsLogin />
          </Route>

          <Route path="/AuthLogin">
            <AuthHeader />
            <AuthLogin />
          </Route>
          <Route path="/PostalLogin">
            <PostalHeader />
            <PostalLogin />
          </Route>
          <Route path="/LoginNewPassword">
            <Header />
            <LoginNewPassword />
          </Route>
          <Route path="/AuthNewPassword">
            <AuthHeader />
            <AuthNewPassword />
          </Route>
          <Route path="/PostalNewPassword">
            <PostalHeader />
            <PostalNewPassword />
          </Route>
          <Route path="/FinanceNewPassword">
            <FinanceHeader />
            <FinanceNewPassword />
          </Route>
          <Route path="/Authorisation">
            <AuthHeader />
            <Authorisation />
          </Route>
          <Route path="/ShoppingCart">
            <Header />
            <ShoppingCart />
          </Route>
          <Route path="/Finance2">
            <Elements stripe={promise}>
              <FinanceHeader />
              <Finance2 />
            </Elements>
          </Route>
          <Route path="/Finance">
            <Elements stripe={promise}>
              <FinanceHeader />
              <Finance />
            </Elements>
          </Route>
          <Route path="/">
            <Header />
            <Home />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;

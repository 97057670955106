import React, { useEffect, useState } from "react";
import "./AllPaidItems.css";
import { db } from "./firebase";
import { useStateValue } from "./StateProvider";
import AllPaidOrders from "./AllPaidOrders";
import { Helmet } from "react-helmet";

function AllPaidItems(){
  const [{ basket, user }, dispatch] = useStateValue();
  const [orders, setOrders] = useState([]);
  const [cart1, setCart1] = useState([]);
  

  function sayHello(i) {
    console.log(i);

    var variable1 = i;

    console.log(variable1);

    Fetchdata(variable1);
  }



  /*
 if (salaryRangeMin && salaryRangeMax) {
        query = query.where("salary", ">=", salaryRangeMin)
                     .where("salary", "<=", salaryRangeax);
      }
  */

  useEffect(() => {
    let collectionRef = db
      .collection("shopUsers");
      collectionRef = collectionRef.where("Designation", "in", ["Patient"])
      .where("MadePurchase", "==", "Yes");
     
     
    collectionRef.onSnapshot((querySnapshot1) => {
      var p = [];
      querySnapshot1.forEach((doc) => {
        p.push(doc.data());
      });

      setCart1(p);
      console.log(p);

      /* 
              let collectionRef = db
                    .collection("AllPaidIds")
                    .where("paiditemyn", "in", ["Yes"]);

                  collectionRef.onSnapshot((querySnapshot1) => {
                    var p = [];
                    querySnapshot1.forEach((doc) => {
                      p.push(doc.data());
                    });

                    setCart1(p);
                    console.log(p);
      */
    });
  }, []);

  const Fetchdata = (val) => {
    console.log(val);

    db.collection("AllpaidItems")
      .doc(val)
      .collection("orders")
      .orderBy("created", "desc")
      .onSnapshot((snapshot) =>
        setOrders(
         
          snapshot.docs.map((doc) => ({
            id: doc.id,
            data:  doc.data() 
           
          })
         
          )
        )
      );
  };



  return (
    <div className="orders">
      <h1>All paid Orders</h1>

      <div className="container_123">
        <table className="table  text-center">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col">User Name</th>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col">User Code</th>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col">Check</th>
            </tr>
          </thead>
          <tbody>
            {cart1.map((i, index) => (
              <tr key={i.authyn}>
                <th scope="row">{index + 1}</th>
                <th scope="row"></th>
                
                <td>{}</td>
                <td>{}</td>
                <td>{}</td>
                <td>{}</td>
                <td>{i.Email}</td>
                <td>{}</td>
                <td>{}</td>
                <td>{}</td>
                <td>{
                <small>{i.id}</small>}</td>
                <td>{}</td>
                <td>{}</td>
                <td>{}</td>
                <td>{}</td>
                <td>
                  <button
                    onClick={() => sayHello(i.id)}
                    className="btn-btn-danger"
                  >
                    View Order
                  </button>
                </td>
                <td>{}</td>
                <td>{}</td>
                
              </tr>
            ))}
          </tbody>
        </table>
      </div>


     

      <h1>All Paid Orders Awaiting Status</h1>

      {orders?.map((order) => (
        <AllPaidOrders order={order}
        
        />
       
      ))}
    </div>
    
  );
  
}

export default AllPaidItems;

/*
import React, { useEffect, useState } from "react";
import "./AllPaidItems.css";
import { db } from "./firebase";
import { useStateValue } from "./StateProvider";
import AllPaidOrders from "./AllPaidOrders";
import { Helmet } from "react-helmet";

function AllPaidItems() {
  const [{ basket, user }, dispatch] = useStateValue();
  const [orders, setOrders] = useState([]);
  const [cart1, setCart1] = useState([]);

  function sayHello(i) {
    console.log(i);

    var variable1 = i;

    console.log(variable1);

    Fetchdata(variable1);
  }

  useEffect(() => {
    let collectionRef = db
      .collection("AllPaidItemsId")
      .where("paiditemyn", "in", ["Yes"]);

    collectionRef.onSnapshot((querySnapshot1) => {
      var p = [];
      querySnapshot1.forEach((doc) => {
        p.push(doc.data());
      });

      setCart1(p);
      console.log(p);
    });
  }, []);

  const Fetchdata = (val) => {
    console.log(val);

    db.collection("AllpaidItems")
      .doc(val)
      .collection("orders")
      .orderBy("created", "desc")
      .onSnapshot((snapshot) =>
        setOrders(
          snapshot.docs.map((doc) => ({
            id: doc.id,
            data: doc.data(),
          }))
        )
      );
  };

  return (
    <div className="orders">
      <h1>Orders paid awaiting Delivery</h1>

      <div className="container_123">
        <table className="table  text-center">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Baskets</th>
              <th scope="col">User Code</th>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col">User Name</th>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col">Check</th>
            </tr>
          </thead>
          <tbody>
            {cart1.map((i, index) => (
              <tr key={i.authyn}>
                <th scope="row">{index + 1}</th>
                <th scope="row"></th>
                <td>{i.userid}</td>
                <td>{}</td>
                <td>{}</td>
                <td>{}</td>
                <td>{}</td>
                <td>{i.email}</td>
                <td>{}</td>
                <td>{}</td>
                <td>{}</td>
                <td>{}</td>
                <td>
                  <button
                    onClick={() => sayHello(i.userid)}
                    className="btn-btn-danger"
                  >
                    View Order
                  </button>
                </td>
                <td>{}</td>
                <td>{}</td>
                <td>
                  <small>{i.id}</small>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>


    

      <h1>All Paid Orders Awaiting Status</h1>

      {orders?.map((order) => (
        <AllPaidOrders order={order} />
      ))}
    </div>
  );
  
}

export default AllPaidItems;
*/

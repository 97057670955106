import { React, useId, useState } from "react";
import "./Validation2.css";
import { useStateValue } from "./StateProvider";
import { db, auth } from "./firebase";
import { useHistory } from "react-router-dom";
import { Helmet } from "helmet";

function Validation2_Trousers() {
    const [trouser32L, Settrouser32L] = useState("");
    const [trouser32R, Settrouser32R] = useState("");
    const [trouser32S, Settrouser32S] = useState("");
    const [trouser34L, Settrouser34L] = useState("");
    const [trouser34R, Settrouser34R] = useState("");
    const [trouser34S, Settrouser34S] = useState("");
    const [trouser36L, Settrouser36L] = useState("");
    const [trouser36R, Settrouser36R] = useState("");
    const [trouser36S, Settrouser36S] = useState("");
    const [trouser38L, Settrouser38L] = useState("");
    const [trouser38R, Settrouser38R] = useState("");
    const [trouser38S, Settrouser38S] = useState("");
    const [trouser40L, Settrouser40L] = useState("");
    const [trouser40R, Settrouser40R] = useState("");
    const [trouser40S, Settrouser40S] = useState("");
    const [trouser42L, Settrouser42L] = useState("");
    const [trouser42R, Settrouser42R] = useState("");
    const [trouser42S, Settrouser42S] = useState("");
    const [trouser44L, Settrouser44L] = useState("");
    const [trouser44R, Settrouser44R] = useState("");
    const [trouser44S, Settrouser44S] = useState("");
    const [addedby, Setaddedby] = useState("");
  
    const [sizexs, Setsizexs] = useState("");
    const [sizesm, Setsizesm] = useState("");
    const [sizemed, Setsizemed] = useState("");
    const [sizelarge, Setsizelarge] = useState("");
    const [sizexl, Setsizexl] = useState("");
    const [sizexxl, Setsizexxl] = useState("");
    const [sizexxxl, Setsizexxxl] = useState("");
    const [sizexxxxl, Setsizexxxxl] = useState("");
  
    const [shoe6, Setshoe6] = useState("");
    const [shoe7, Setshoe7] = useState("");
    const [shoe8, Setshoe8] = useState("");
    const [shoe9, Setshoe9] = useState("");
    const [shoe10, Setshoe10] = useState("");
    const [shoe11, Setshoe11] = useState("");
    const [shoe12, Setshoe12] = useState("");
    const [shoe13, Setshoe13] = useState("");
    const [shoe6half, Setshoe6half] = useState("");
    const [shoe7half, Setshoe7half] = useState("");
    const [shoe8half, Setshoe8half] = useState("");
    const [shoe9half, Setshoe9half] = useState("");
    const [shoe10half, Setshoe10half] = useState("");
    const [shoe11half, Setshoe11half] = useState("");
    const [shoe12half, Setshoe12half] = useState("");
    const [shoe13half, Setshoe13half] = useState("");
  
    const [onefitall, Setonefitall] = useState("");
  
    const [cart1, setCart1] = useState([]);
  
    const [info, setInfo] = useState([]);
    const [val, setVal] = useState();
  
    const [{ user }] = useStateValue();
  
    window.onload = function () {
      Fetchdata();
    };
  
    // Fetch the required data using the get() method
    const Fetchdata = () => {
      db.collection("Trousers")
        //.where("StoreName", "in", ["Cotton Traders"])
        .get()
        .then((querySnapshot) => {
          //db.collection("All").get().then((querySnapshot) => {
          // Loop through the data and store
          // it in array to display
  
          var p = [];
          querySnapshot.forEach((doc) => {
            p.push(doc.data());
          });
  
          setCart1(p);
        });
    };
  
    <div id="some-id"></div>;
  
    function user1(val) {
      console.log(val);
  
      db.collection("All")
        .where("Id", "in", [val])
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            doc.ref
              .update({
                Trouser32L: trouser32L,
                Trouser32R: trouser32R,
                Trouser32S: trouser32S,
                Trouser34L: trouser34L,
                Trouser34R: trouser34R,
                Trouser34S: trouser34S,
                Trouser36L: trouser36L,
                Trouser36R: trouser36R,
                Trouser36S: trouser36S,
                Trouser38L: trouser38L,
                Trouser38R: trouser38R,
                Trouser38S: trouser38S,
                Trouser40L: trouser40L,
                Trouser40R: trouser40R,
                Trouser40S: trouser40S,
                Trouser42L: trouser42L,
                Trouser42R: trouser42R,
                Trouser42S: trouser42S,
                Trouser44L: trouser44L,
                Trouser44R: trouser44R,
                Trouser44S: trouser44S,
                Sizexs: sizexs,
                Sizesm: sizesm,
                Sizemed: sizemed,
                Sizelarge: sizelarge,
                Sizexl: sizexl,
                Sizexxl: sizexxl,
                Sizexxxl: sizexxxl,
                Sizexxxxl: sizexxxxl,
                Shoe6: shoe6,
                Shoe7: shoe7,
                Shoe8: shoe8,
                Shoe9: shoe9,
                Shoe10: shoe10,
                Shoe11: shoe11,
                Shoe12: shoe12,
                Shoe13: shoe13,
                Shoe6half: shoe6half,
                Shoe7half: shoe7half,
                Shoe8half: shoe8half,
                Shoe9half: shoe9half,
                Shoe10half: shoe10half,
                Shoe11half: shoe11half,
                Shoe12half: shoe12half,
                Shoe13half: shoe13half,
                Onefitall: onefitall,
              })
              .then(() => {
                usersizeprodtype(val);
                //window.location.reload();
              })
  
              .catch(function (error) {
                console.error("Error updating document: ", error);
              });
          });
        });
    }
  
    function usersizeprodtype(val) {
      cart1.map((i) => {
        //console.log(i);
  
        var variable1id = val;
        var variable2prod = i.ProductType;
        console.log(variable1id);
        console.log(variable2prod);
        updatesizeinproducts(variable1id, variable2prod);
      });
    }
  
    function updatesizeinproducts(variable1id, variable2prod) {
      console.log(variable1id);
      console.log(variable2prod);
  
      db.collection(variable2prod)
        .where("Id", "in", [variable1id])
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            doc.ref
              .update({
                Trouser32L: trouser32L,
                Trouser32R: trouser32R,
                Trouser32S: trouser32S,
                Trouser34L: trouser34L,
                Trouser34R: trouser34R,
                Trouser34S: trouser34S,
                Trouser36L: trouser36L,
                Trouser36R: trouser36R,
                Trouser36S: trouser36S,
                Trouser38L: trouser38L,
                Trouser38R: trouser38R,
                Trouser38S: trouser38S,
                Trouser40L: trouser40L,
                Trouser40R: trouser40R,
                Trouser40S: trouser40S,
                Trouser42L: trouser42L,
                Trouser42R: trouser42R,
                Trouser42S: trouser42S,
                Trouser44L: trouser44L,
                Trouser44R: trouser44R,
                Trouser44S: trouser44S,
                Sizexs: sizexs,
                Sizesm: sizesm,
                Sizemed: sizemed,
                Sizelarge: sizelarge,
                Sizexl: sizexl,
                Sizexxl: sizexxl,
                Sizexxxl: sizexxxl,
                Sizexxxxl: sizexxxxl,
                Shoe6: shoe6,
                Shoe7: shoe7,
                Shoe8: shoe8,
                Shoe9: shoe9,
                Shoe10: shoe10,
                Shoe11: shoe11,
                Shoe12: shoe12,
                Shoe13: shoe13,
                Shoe6half: shoe6half,
                Shoe7half: shoe7half,
                Shoe8half: shoe8half,
                Shoe9half: shoe9half,
                Shoe10half: shoe10half,
                Shoe11half: shoe11half,
                Shoe12half: shoe12half,
                Shoe13half: shoe13half,
                Onefitall: onefitall,
              })
              .then(() => {
                window.location.reload();
              })
  
              .catch(function (error) {
                console.error("Error updating document: ", error);
              });
          });
        });
    }
  
    function user5(val) {
      cart1.map((i) => {
        console.log(i);
  
        var variable1 = val;
        console.log(variable1);
        updateauth5(variable1);
      });
      // Fetchdata();
    }
  
    function updateauth5(val) {
      console.log(val);
  
      db.collection("Validation2")
        .where("Id", "in", [val])
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            doc.ref
              .delete()
              .then(() => {
                window.location.reload();
              })
              .catch(function (error) {
                console.error("Error removing document: ", error);
              });
          });
        });
    }
  
    const sub = (e) => {
      e.preventDefault();
    };
  
    return (
      <div className="home__container2000">
        <div className="container_ee234">
          <div className="row_mt-3">
            <table className="table__text-center">
              <tbody>
                {cart1.map((i, index) => (
                  <tr key={i.id}>
                    <center>
                      <form
                        style={{ marginTop: "200px" }}
                        onSubmit={(event) => {
                          sub(event);
                        }}
                        id="addItems"
                      >
                        <div class="boxedallaround">
                          <div class="boxed223">
                            <h3>Store Name - {i.StoreName}</h3>
                            <h3>Item Name - {i.Title}</h3>
                            <h3>Product Id {i.Id}</h3>
                            <h3>Product Type -{i.ProductType}</h3>
                            <h3>Price - £{i.Price}</h3>
                            <br />
                            <br />
                            <img src={i.Image} style={{ width: "8rem" }} />
                            <br />
                            <br />
                            <h3>Sizes</h3>
                            <input
                              type="text"
                              placeholder="One size fits all"
                              value={i.Onefitall}
                            />
                            <input
                              type="checkbox"
                              id="Setstorename"
                              name="One Size Fits All"
                              value={"Yes"}
                              onChange={(e) => {
                                Setonefitall(e.target.value);
                              }}
                            />{" "}
                            One size fits all
                            <h3>Trousers/Jeans</h3>
                            <input
                              type="text"
                              size="5"
                              placeholder="32 L"
                              value={i.trouser32L}
                            />
                            <input
                              type="checkbox"
                              id="Settrouser32L"
                              name="32L"
                              value={"yes"}
                              onChange={(e) => {
                                Settrouser32L(e.target.value);
                              }}
                            />{" "}
                            32 L
                            <input
                              type="text"
                              size="5"
                              placeholder="32 R"
                              value={i.trouser32R}
                            />
                            <input
                              type="checkbox"
                              id="Settrouser32R"
                              name="32R"
                              value={"yes"}
                              onChange={(e) => {
                                Settrouser32R(e.target.value);
                              }}
                            />{" "}
                            32 R
                            <input
                              type="text"
                              size="5"
                              placeholder="32 S"
                              value={i.trouser32S}
                            />
                            <input
                              type="checkbox"
                              id="Settrouser32S"
                              name="32S"
                              value={"yes"}
                              onChange={(e) => {
                                Settrouser32S(e.target.value);
                              }}
                            />{" "}
                            32 S
                            <input
                              type="text"
                              size="5"
                              placeholder="34 L"
                              value={i.trouser34L}
                            />
                            <input
                              type="checkbox"
                              id="Settrouser34L"
                              name="34L"
                              value={"yes"}
                              onChange={(e) => {
                                Settrouser34L(e.target.value);
                              }}
                            />{" "}
                            34 L
                            <input
                              type="text"
                              size="5"
                              placeholder="34 R"
                              value={i.trouser34L}
                            />
                            <input
                              type="checkbox"
                              id="Settrouser34R"
                              name="34R"
                              value={"yes"}
                              onChange={(e) => {
                                Settrouser34R(e.target.value);
                              }}
                            />{" "}
                            34 R
                            <input
                              type="text"
                              size="5"
                              placeholder="34 S"
                              value={i.trouser34S}
                            />
                            <input
                              type="checkbox"
                              id="Settrouser34S"
                              name="34S"
                              value={"yes"}
                              onChange={(e) => {
                                Settrouser34S(e.target.value);
                              }}
                            />{" "}
                            34 S
                            <br />
                            <br />
                            <input
                              type="text"
                              size="5"
                              placeholder="36 L"
                              value={i.Trouser36L}
                            />
                            <input
                              type="checkbox"
                              id="Settrouser36L"
                              name="36L"
                              value={"yes"}
                              onChange={(e) => {
                                Settrouser36L(e.target.value);
                              }}
                            />{" "}
                            36 L
                            <input
                              type="text"
                              size="5"
                              placeholder="36 R"
                              value={i.Trouser36R}
                            />
                            <input
                              type="checkbox"
                              id="Settrouser36R"
                              name="36R"
                              value={"yes"}
                              onChange={(e) => {
                                Settrouser36R(e.target.value);
                              }}
                            />{" "}
                            36 R
                            <input
                              type="text"
                              size="5"
                              placeholder="36 S"
                              value={i.Trouser36S}
                            />
                            <input
                              type="checkbox"
                              id="Settrouser36S"
                              name="36S"
                              value={"yes"}
                              onChange={(e) => {
                                Settrouser36S(e.target.value);
                              }}
                            />{" "}
                            36 S
                            <input
                              type="text"
                              size="5"
                              placeholder="38 L"
                              value={i.Trouser38L}
                            />
                            <input
                              type="checkbox"
                              id="Settrouser38L"
                              name="38L"
                              value={"yes"}
                              onChange={(e) => {
                                Settrouser38L(e.target.value);
                              }}
                            />{" "}
                            38 L
                            <input
                              type="text"
                              size="5"
                              placeholder="38 R"
                              value={i.Trouser38R}
                            />
                            <input
                              type="checkbox"
                              id="Settrouser38R"
                              name="38R"
                              value={"yes"}
                              onChange={(e) => {
                                Settrouser38R(e.target.value);
                              }}
                            />{" "}
                            38 R
                            <input
                              type="text"
                              size="5"
                              placeholder="38 S"
                              value={i.Trouser38S}
                            />{" "}
                            <input
                              type="checkbox"
                              id="Settrouser38S"
                              name="38S"
                              value={"yes"}
                              onChange={(e) => {
                                Settrouser38S(e.target.value);
                              }}
                            />{" "}
                            38 S
                            <input
                              type="text"
                              size="5"
                              placeholder="40 L"
                              value={i.Trouser40L}
                            />{" "}
                            <input
                              type="checkbox"
                              id="Settrouser40L"
                              name="40L"
                              value={"yes"}
                              onChange={(e) => {
                                Settrouser40L(e.target.value);
                              }}
                            />{" "}
                            <br />
                            <br />
                            40 L
                            <input
                              type="text"
                              size="5"
                              placeholder="40 R"
                              value={i.Trouser40R}
                            />{" "}
                            <input
                              type="checkbox"
                              id="Settrouser40R"
                              name="40R"
                              value={"yes"}
                              onChange={(e) => {
                                Settrouser40R(e.target.value);
                              }}
                            />{" "}
                            40 R
                            <input
                              type="text"
                              size="5"
                              placeholder="40 S"
                              value={i.Trouser40S}
                            />{" "}
                            <input
                              type="checkbox"
                              id="Settrouser40S"
                              name="40S"
                              value={"yes"}
                              onChange={(e) => {
                                Settrouser40S(e.target.value);
                              }}
                            />{" "}
                            40 S
                            <input
                              type="text"
                              size="5"
                              placeholder="42 L"
                              value={i.Trouser42L}
                            />{" "}
                            <input
                              type="checkbox"
                              id="Settrouser42L"
                              name="42L"
                              value={"yes"}
                              onChange={(e) => {
                                Settrouser42L(e.target.value);
                              }}
                            />{" "}
                            42L
                            <input
                              type="text"
                              size="5"
                              placeholder="42 R"
                              value={i.Trouser42R}
                            />
                            <input
                              type="checkbox"
                              id="Settrouser42R"
                              name="42R"
                              value={"yes"}
                              onChange={(e) => {
                                Settrouser42R(e.target.value);
                              }}
                            />{" "}
                            42 R
                            <input
                              type="text"
                              size="5"
                              placeholder="42 S"
                              value={i.Trouser42S}
                            />
                            <input
                              type="checkbox"
                              id="Settrouser42S"
                              name="42S"
                              value={"yes"}
                              onChange={(e) => {
                                Settrouser42S(e.target.value);
                              }}
                            />{" "}
                            42 S
                            <input
                              type="text"
                              size="5"
                              placeholder="44 L"
                              value={i.Trouser44L}
                            />
                            <input
                              type="checkbox"
                              id="Settrouser44L"
                              name="44L"
                              value={"yes"}
                              onChange={(e) => {
                                Settrouser44L(e.target.value);
                              }}
                            />{" "}
                            44 L
                            <input
                              type="text"
                              size="5"
                              placeholder="44 R"
                              value={i.Trouser44R}
                            />
                            <input
                              type="checkbox"
                              id="Settrouser44R"
                              name="44R"
                              value={"yes"}
                              onChange={(e) => {
                                Settrouser44R(e.target.value);
                              }}
                            />{" "}
                            44 R
                            <input
                              type="text"
                              size="5"
                              placeholder="44 S"
                              value={i.Trouser44S}
                            />
                            <input
                              type="checkbox"
                              id="Settrouser44S"
                              name="44S"
                              value={"yes"}
                              onChange={(e) => {
                                Settrouser44S(e.target.value);
                              }}
                            />{" "}
                            44 S
                            <br />
                            <br />
                            <h3>
                              TShirts/T-Shirts/Sweatshirts/Tops/Knitwear/Jackets
                            </h3>
                            <input
                              type="text"
                              size="5"
                              placeholder="X Small"
                              value={i.Sizexs}
                            />
                            <input
                              type="checkbox"
                              id="Setsizexs"
                              name="X Small"
                              value={"yes"}
                              onChange={(e) => {
                                Setsizexs(e.target.value);
                              }}
                            />{" "}
                            X Small
                            <input
                              type="text"
                              size="5"
                              placeholder="Small"
                              value={i.Sizesm}
                            />
                            <input
                              type="checkbox"
                              id="Setsizesm"
                              name="Small"
                              value={"yes"}
                              onChange={(e) => {
                                Setsizesm(e.target.value);
                              }}
                            />{" "}
                            Small
                            <input
                              type="text"
                              size="5"
                              placeholder="Medium"
                              value={i.Sizemed}
                            />
                            <input
                              type="checkbox"
                              id="Setsizemed"
                              name="Medium"
                              value={"yes"}
                              onChange={(e) => {
                                Setsizemed(e.target.value);
                              }}
                            />{" "}
                            Medium
                            <input
                              type="text"
                              size="5"
                              placeholder="Large"
                              value={i.Sizelarge}
                            />
                            <input
                              type="checkbox"
                              id="Setsizelarge"
                              name="Large"
                              value={"yes"}
                              onChange={(e) => {
                                Setsizelarge(e.target.value);
                              }}
                            />{" "}
                            Large
                            <input
                              type="text"
                              size="5"
                              placeholder="XL"
                              value={i.Sizexl}
                            />
                            <input
                              type="checkbox"
                              id="Setsizexl"
                              name="XL"
                              value={"yes"}
                              onChange={(e) => {
                                Setsizexl(e.target.value);
                              }}
                            />{" "}
                            XL
                            <input
                              type="text"
                              size="5"
                              placeholder="XXL"
                              value={i.Sizexxl}
                            />
                            <input
                              type="checkbox"
                              id="Setsizexxl"
                              name="XXL"
                              value={"yes"}
                              onChange={(e) => {
                                Setsizexxl(e.target.value);
                              }}
                            />{" "}
                            XXL
                            <input
                              type="text"
                              size="5"
                              placeholder="3XL"
                              value={i.Sizexxxl}
                            />
                            <input
                              type="checkbox"
                              id="Setsizexxxl"
                              name="XXXL"
                              value={"yes"}
                              onChange={(e) => {
                                Setsizexxxl(e.target.value);
                              }}
                            />{" "}
                            XXXL
                            <input
                              type="text"
                              size="5"
                              placeholder="4XL"
                              value={i.Sizexxxxl}
                            />
                            <input
                              type="checkbox"
                              id="Setsizexxxxl"
                              name="XXXXL"
                              value={"yes"}
                              onChange={(e) => {
                                Setsizexxxxl(e.target.value);
                              }}
                            />{" "}
                            XXXXL
                            <h3>Shoes/Boots</h3>
                            <input
                              type="text"
                              size="4"
                              placeholder="6"
                              value={i.Shoe6}
                            />
                            <input
                              type="checkbox"
                              id="Setshoe6"
                              name="Size 6"
                              value={"yes"}
                              onChange={(e) => {
                                Setshoe6(e.target.value);
                              }}
                            />{" "}
                            6
                            <input
                              type="text"
                              size="5"
                              placeholder="7"
                              value={i.Shoe7}
                            />
                            <input
                              type="checkbox"
                              id="Setshoe7"
                              name="Size 7"
                              value={"yes"}
                              onChange={(e) => {
                                Setshoe7(e.target.value);
                              }}
                            />{" "}
                            7
                            <input
                              type="text"
                              size="5"
                              placeholder="8"
                              value={i.Shoe8}
                            />
                            <input
                              type="checkbox"
                              id="Setshoe8"
                              name="Size 8"
                              value={"yes"}
                              onChange={(e) => {
                                Setshoe8(e.target.value);
                              }}
                            />{" "}
                            8
                            <input
                              type="text"
                              size="5"
                              placeholder="9"
                              value={i.Shoe9}
                            />
                            <input
                              type="checkbox"
                              id="Setshoe9"
                              name="Size 9"
                              value={"yes"}
                              onChange={(e) => {
                                Setshoe9(e.target.value);
                              }}
                            />{" "}
                            9
                            <input
                              type="text"
                              size="5"
                              placeholder="10"
                              value={i.Shoe10}
                            />
                            <input
                              type="checkbox"
                              id="Setshoe10"
                              name="Size 10"
                              value={"yes"}
                              onChange={(e) => {
                                Setshoe10(e.target.value);
                              }}
                            />{" "}
                            10
                            <input
                              type="text"
                              size="5"
                              placeholder="11"
                              value={i.Shoe11}
                            />
                            <input
                              type="checkbox"
                              id="Setshoe11"
                              name="Size 11"
                              value={"yes"}
                              onChange={(e) => {
                                Setshoe11(e.target.value);
                              }}
                            />{" "}
                            11
                            <input
                              type="text"
                              size="5"
                              placeholder="12"
                              value={i.Shoe12}
                            />
                            <input
                              type="checkbox"
                              id="Setshoe12"
                              name="Size 12"
                              value={"yes"}
                              onChange={(e) => {
                                Setshoe12(e.target.value);
                              }}
                            />{" "}
                            12
                            <input
                              type="text"
                              size="5"
                              placeholder="13"
                              value={i.Shoe13}
                            />
                            <input
                              type="checkbox"
                              id="Setshoe13"
                              name="Size 13"
                              value={"yes"}
                              onChange={(e) => {
                                Setshoe13(e.target.value);
                              }}
                            />{" "}
                            13
                            <br />
                            <br />
                            <input
                              type="text"
                              size="5"
                              placeholder="6.5"
                              value={i.Shoe6half}
                            />
                            <input
                              type="checkbox"
                              id="Setshoe6half"
                              name="Size 6.5"
                              value={"yes"}
                              onChange={(e) => {
                                Setshoe6half(e.target.value);
                              }}
                            />{" "}
                            6.5
                            <input
                              type="text"
                              size="5"
                              placeholder="7.5"
                              value={i.Shoe7half}
                            />
                            <input
                              type="checkbox"
                              id="Setshoe7half"
                              name="Size 7.5"
                              value={"yes"}
                              onChange={(e) => {
                                Setshoe7half(e.target.value);
                              }}
                            />{" "}
                            7.5
                            <input
                              type="text"
                              size="5"
                              placeholder="8"
                              value={i.Shoe8half}
                            />
                            <input
                              type="checkbox"
                              id="Setshoe8half"
                              name="Size 8.5"
                              value={"yes"}
                              onChange={(e) => {
                                Setshoe8half(e.target.value);
                              }}
                            />{" "}
                            8.5
                            <input
                              type="text"
                              size="5"
                              placeholder="9.5"
                              value={i.Shoe9half}
                            />
                            <input
                              type="checkbox"
                              id="Setshoe9half"
                              name="Size 9.5"
                              value={"yes"}
                              onChange={(e) => {
                                Setshoe9half(e.target.value);
                              }}
                            />{" "}
                            9.5
                            <input
                              type="text"
                              size="5"
                              placeholder="10"
                              value={i.Shoe10half}
                            />
                            <input
                              type="checkbox"
                              id="Setshoe10half"
                              name="Size 10.5"
                              value={"yes"}
                              onChange={(e) => {
                                Setshoe10half(e.target.value);
                              }}
                            />{" "}
                            10.5
                            <input
                              type="text"
                              size="5"
                              placeholder="11.5"
                              value={i.Shoe11half}
                            />
                            <input
                              type="checkbox"
                              id="Setshoe11half"
                              name="Size 11.5"
                              value={"yes"}
                              onChange={(e) => {
                                Setshoe11half(e.target.value);
                              }}
                            />{" "}
                            11.5
                            <input
                              type="text"
                              size="5"
                              placeholder="12.5"
                              value={i.Shoe12half}
                            />
                            <input
                              type="checkbox"
                              id="Setshoe12half"
                              name="Size 12.5"
                              value={"yes"}
                              onChange={(e) => {
                                Setshoe12half(e.target.value);
                              }}
                            />{" "}
                            12.5
                            <input
                              type="text"
                              size="5"
                              placeholder="13.5"
                              value={i.Shoe13half}
                            />
                            <input
                              type="checkbox"
                              id="Setshoe13half"
                              name="Size 13.5"
                              value={"yes"}
                              onChange={(e) => {
                                Setshoe13half(e.target.value);
                              }}
                            />{" "}
                            13.5
                          </div>
  
                          <div class="boxed223">
                            <h3>Added By</h3>
                            <input
                              type="checkbox"
                              id="Setaddedby"
                              placeholder="addedby"
                              name="addedby"
                              value={"Nicola"}
                              onChange={(e) => {
                                Setaddedby(e.target.value);
                              }}
                            />{" "}
                            Nicola
                            <input
                              type="checkbox"
                              id="Setaddedby"
                              placeholder="addedby"
                              name="addedby"
                              value={"Graham"}
                              onChange={(e) => {
                                Setaddedby(e.target.value);
                              }}
                            />{" "}
                            Graham
                           
                          </div>
                          <button
                            onClick={() => user1(i.Id)}
                            className="allPaidProducts__updateproducts"
                          >
                            Submit Sizes
                          </button>
  
                          <button
                            onClick={() => user5(i.Id)}
                            className="allPaidProducts__backinfo1"
                          >
                            Delete Item
                          </button>
                        </div>
                      </form>
  
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                    </center>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }

export default Validation2_Trousers
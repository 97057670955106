import React, { useState } from "react";
import "./Login.css";
import { Link, useHistory } from "react-router-dom";
import { auth, db } from "./firebase";
import { useStateValue } from "./StateProvider";
import securelogo from "./images/Securelogo.jpg";
import { now } from "moment";
import {Helmet} from "react-helmet";

function AuthNewPassword() {
  //this is where you can change email to patient number
  const history = useHistory();
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [newPassword, setnewPassword] = useState("");
  const [userid, SET_USER] = useState("");
  const [id, Setid] = useState("");
  const [producttype, Setproducttype] = useState("");
  const [{ user }] = useStateValue();

  const updateuser = (e) => {
    if (user) {
      //  user = auth().currentUser;

      user
        .updatePassword(newPassword)
        .then(() => {
          db.collection("users")
            .where("Email", "in", [email])
            .get()
            .then((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                doc.ref.update({
                  Email: email,
                  oldpassword: password,
                  id: user.uid,
                  Password: newPassword,
                  timevalue: Date().toLocaleString(),
                });
              });
            });

            

            alert("Password Updated Successfully");
            sendnewdetails()
                          
         
        })
        .catch((error) => {
          // An error ocurred
          // ...
        });
    }
  };

function sendnewdetails(){


  db.collection("shopUsers")
            .where("Email", "in", [email])
            .get()
            .then((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                doc.ref.update({
              Email: email,
              Password: newPassword,
              id: user.uid,
              timevalue: Date().toLocaleString(),

            })
            history.push("/Authorisation");
            // Update successful.
          });
        });

}


  return (
    <div className="login">
      <Helmet>
        <title>Secure Shopping| Log In</title>
      </Helmet>

      <Link to="/Authorisation">
        <img className="login__logo" src={securelogo} alt="securelogo" />
      </Link>
      <div className="login__container">
        <h1>Change your Password</h1>
        <h6>User Id-{user?.uid}</h6>

        <form name="MyForm">
          <h5>Enter your User Name</h5>
          <input
            type="text"
            value={email}
            onChange={(e) => setemail(e.target.value)}
          />

          <h5>Enter Your Old Password</h5>
          <input
            type="password"
            value={password}
            onChange={(e) => setpassword(e.target.value)}
          />

          <h5>Enter New Password (needs to be 6 characters or more)</h5>
          <input
            type="password"
            value={newPassword}
            onChange={(e) => setnewPassword(e.target.value)}
          />
        </form>
        <button onClick={updateuser} className="login__registerButton">
          {" "}
          Change Password
        </button>

        <p>
          By Changing your password you agree to our terms and conditions of Use
          & Sale. You also state that you are the person signing in is the
          actual user and not someone else.
        </p>
      </div>
    </div>
  );
}
export default AuthNewPassword
import { React, useState } from "react";
import "./Validation.css";
import { useStateValue } from "./StateProvider";
import { db } from "./firebase";
import { useHistory } from "react-router-dom";
import { auth } from "./firebase";
import { Helmet } from "helmet";

function Validation() {
  const [info, setInfo] = useState([]);
  const [storename, Setstorename] = useState("");
  const history = useHistory();

  window.onload = function () {
    Fetchdata();
  };

  // Fetch the required data using the get() method
  const Fetchdata = () => {
    db.collection("Validation")
      .get()
      .then((querySnapshot) => {
        //db.collection("All").get().then((querySnapshot) => {
        // Loop through the data and store
        // it in array to display
        querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        querySnapshot.forEach((element) => {
          var data = element.data();
          setInfo((arr) => [...arr, data]);
        });
        unsub();
      });
  };
  const unsub = db.collection("Validation").onSnapshot(() => {});

  <div id="some-id"></div>;

  // Display the result on the page
  return (
    <div>
      {info.map((data) => (
        <Frame
          storename={data.StoreName}
          id={data.Id}
          image={data.Image}
          price={data.Price}
          title={data.Title}
          prodinfo={data.Prodinfo}
          rating={data.Rating}
          colour={data.Colour}
          bluedvd={data.Bluedvd}
          sizelarge={data.Sizelarge}
          producttype={data.ProductType}
          trouser32L={data.Trouser32L}
          trouser32R={data.Trouser32R}
          trouser32S={data.Trouser32S}
          trouser34L={data.Trouser34L}
          trouser34R={data.Trouser34R}
          trouser34S={data.Trouser34S}
          trouser36L={data.Trouser36L}
          trouser36R={data.Trouser36L}
          trouser36S={data.Trouser36S}
          trouser38L={data.Trouser38L}
          trouser38R={data.Trouser38R}
          trouser38S={data.Trouser38S}
          trouser40L={data.Trouser40L}
          trouser40R={data.Trouser40R}
          trouser40S={data.Trouser40S}
          trouser42L={data.Trouser42L}
          trouser42R={data.Trouser42R}
          trouser42S={data.Trouser42S}
          trouser44L={data.Trouser44L}
          trouser44R={data.Trouser44R}
          trouser44S={data.Trouser44S}
          sizexs={data.Sizexs}
          sizesm={data.Sizesm}
          sizemed={data.Sizemed}
          sizelarge={data.Sizelarge}
          sizexl={data.Sizexl}
          sizexxl={data.Sizexxl}
          sizexxxl={data.Sizexxxl}
          sizexxxxl={data.Sizexxxxl}
          shoe6={data.Shoe6}
          shoe7={data.Shoe7}
          shoe8={data.Shoe8}
          shoe9={data.Shoe9}
          shoe10={data.Shoe10}
          shoe11={data.Shoe11}
          shoe12={data.Shoe12}
          shoe13={data.Shoe13}
          shoe6half={data.Shoe6half}
            shoe7half={data.Shoe7half}
            shoe8half={data.Shoe8half}
            shoe9half={data.Shoe9half}
            shoe10half={data.Shoe10half}
            shoe11half={data.Shoe11half}
            shoe12half={data.Shoe12half}
            shoe13half={data.Shoe13half}
          onefitall={data.Onefitall}
          stddvd={data.Stddvd}
          bluedvd={data.Bluedvd}
          musicCd={data.MusicCd}
          electricalitem={data.Electricalitem}
          Addedby={data.Addedby}
        />
      ))}
    </div>
  );
}

// Define how each display entry will be structured
      const Frame = ({
        id,
        image,
        price,
        rating,
        title,
        prodinfo,
        colour,
        size,
        storename,
        producttype,
        trouser32L,
        trouser32R,
        trouser32S,
        trouser34L,
        trouser34R,
        trouser34S,
        trouser36L,
        trouser36R,
        trouser36S,
        trouser38L,
        trouser38R,
        trouser38S,
        trouser40L,
        trouser40R,
        trouser40S,
        trouser42L,
        trouser42R,
        trouser42S,
        trouser44L,
        trouser44R,
        trouser44S,
        sizexs,
        sizesm,
        sizexl,
        sizexxl,
        sizexxxl,
        sizexxxxl,
        sizemed,
        sizelarge,
        shoe6,
        shoe7,
        shoe8,
        shoe9,
        shoe10,
        shoe11,
        shoe12,
        shoe13,
        shoe10half,
        shoe11half,
        shoe12half,
        shoe13half,
        shoe6half,
        shoe7half,
        shoe8half,
        shoe9half,
        onefitall,
        stddvd,
        bluedvd,
        musicCd,
        electricalitem,
        Addedby,
      }) => {
        // const [{basket,user}, dispatch] = useStateValue();
        const [{ user }] = useStateValue();
        const selectShortlistedApplicant = (e) => {
          const checked = e.target.checked;

          if (checked) {
            //checked

            addtocart();

            //alert("Selected");
          } else {
            //unchecked  setDisable(e.target.value);
            alert("Select a size first!");
            // alert(" Successfully unticked");
          }
        };
        function approveitems(val) {
          console.log(val);

         
          var myFloat2 = parseFloat(rating);

          db.collection("All").add({
            StoreName: storename,
            Image: image,
            Price: price,
            Rating: myFloat2,
            Title: title,
            ProductType: producttype,
            Id: id,
            Prodinfo: prodinfo,
            Colour: colour,
            Trouser32L: trouser32L,
            Trouser32R: trouser32R,
            Trouser32S: trouser32S,
            Trouser34L: trouser34L,
            Trouser34R: trouser34R,
            Trouser34S: trouser34S,
            Trouser36L: trouser36L,
            Trouser36R: trouser36R,
            Trouser36S: trouser36S,
            Trouser38L: trouser38L,
            Trouser38R: trouser38R,
            Trouser38S: trouser38S,
            Trouser40L: trouser40L,
            Trouser40R: trouser40R,
            Trouser40S: trouser40S,
            Trouser42L: trouser42L,
            Trouser42R: trouser42R,
            Trouser42S: trouser42S,
            Trouser44L: trouser44L,
            Trouser44R: trouser44R,
            Trouser44S: trouser44S,
            Sizexs: sizexs,
            Sizesm: sizesm,
            Sizemed: sizemed,
            Sizelarge: sizelarge,
            Sizexl: sizexl,
            Sizexxl: sizexxl,
            Sizexxxl: sizexxxl,
            Sizexxxxl: sizexxxxl,
            Shoe6: shoe6,
            Shoe7: shoe7,
            Shoe8: shoe8,
            Shoe9: shoe9,
            Shoe10: shoe10,
            Shoe11: shoe11,
            Shoe12: shoe12,
            Shoe13: shoe13,


            Shoe6half:shoe6half,
            Shoe7half:shoe7half,
            Shoe8half:shoe8half,
            Shoe9half:shoe9half,
            Shoe10half:shoe10half,
            Shoe11half:shoe11half,
            Shoe12half:shoe12half,
            Shoe13half:shoe13half,
            Onefitall: onefitall,
            Stddvd: stddvd,
            Bluedvd: bluedvd,
            MusicCd: musicCd,
            Electricalitem: electricalitem,
           
          });

    db.collection(producttype).add({
      StoreName: storename,
      Image: image,
      Price: price,
      Rating: myFloat2,
      Title: title,
      ProductType: producttype,
      Id: id,
      Prodinfo: prodinfo,
      Colour: colour,
      Trouser32L: trouser32L,
      Trouser32R: trouser32R,
      Trouser32S: trouser32S,
      Trouser34L: trouser34L,
      Trouser34R: trouser34R,
      Trouser34S: trouser34S,
      Trouser36L: trouser36L,
      Trouser36R: trouser36R,
      Trouser36S: trouser36S,
      Trouser38L: trouser38L,
      Trouser38R: trouser38R,
      Trouser38S: trouser38S,
      Trouser40L: trouser40L,
      Trouser40R: trouser40R,
      Trouser40S: trouser40S,
      Trouser42L: trouser42L,
      Trouser42R: trouser42R,
      Trouser42S: trouser42S,
      Trouser44L: trouser44L,
      Trouser44R: trouser44R,
      Trouser44S: trouser44S,
      Sizexs: sizexs,
      Sizesm: sizesm,
      Sizemed: sizemed,
      Sizelarge: sizelarge,
      Sizexl: sizexl,
      Sizexxl: sizexxl,
      Sizexxxl: sizexxxl,
      Sizexxxxl: sizexxxxl,
      Shoe6: shoe6,
      Shoe7: shoe7,
      Shoe8: shoe8,
      Shoe9: shoe9,
      Shoe10: shoe10,
      Shoe11: shoe11,
      Shoe12: shoe12,
      Shoe13: shoe13,
      Shoe6half:shoe6half,
            Shoe7half:shoe7half,
            Shoe8half:shoe8half,
            Shoe9half:shoe9half,
            Shoe10half:shoe10half,
            Shoe11half:shoe11half,
            Shoe12half:shoe12half,
            Shoe13half:shoe13half,
      
      Onefitall: onefitall,
      Stddvd: stddvd,
      Bluedvd: bluedvd,
      MusicCd: musicCd,
      Electricalitem: electricalitem,
     

    });

    db.collection("NewItems").add({
      StoreName: storename,
      Image: image,
      Price: price,
      Rating: myFloat2,
      Title: title,
      ProductType: producttype,
      Id: id,
      Prodinfo: prodinfo,
      Colour: colour,
      Trouser32L: trouser32L,
      Trouser32R: trouser32R,
      Trouser32S: trouser32S,
      Trouser34L: trouser34L,
      Trouser34R: trouser34R,
      Trouser34S: trouser34S,
      Trouser36L: trouser36L,
      Trouser36R: trouser36R,
      Trouser36S: trouser36S,
      Trouser38L: trouser38L,
      Trouser38R: trouser38R,
      Trouser38S: trouser38S,
      Trouser40L: trouser40L,
      Trouser40R: trouser40R,
      Trouser40S: trouser40S,
      Trouser42L: trouser42L,
      Trouser42R: trouser42R,
      Trouser42S: trouser42S,
      Trouser44L: trouser44L,
      Trouser44R: trouser44R,
      Trouser44S: trouser44S,
      Sizexs: sizexs,
      Sizesm: sizesm,
      Sizemed: sizemed,
      Sizelarge: sizelarge,
      Sizexl: sizexl,
      Sizexxl: sizexxl,
      Sizexxxl: sizexxxl,
      Sizexxxxl: sizexxxxl,
      Shoe6: shoe6,
      Shoe7: shoe7,
      Shoe8: shoe8,
      Shoe9: shoe9,
      Shoe10: shoe10,
      Shoe11: shoe11,
      Shoe12: shoe12,
      Shoe13: shoe13,


      Shoe6half:shoe6half,
      Shoe7half:shoe7half,
      Shoe8half:shoe8half,
      Shoe9half:shoe9half,
      Shoe10half:shoe10half,
      Shoe11half:shoe11half,
      Shoe12half:shoe12half,
      Shoe13half:shoe13half,
      Onefitall: onefitall,
      Stddvd: stddvd,
      Bluedvd: bluedvd,
      MusicCd: musicCd,
      Electricalitem: electricalitem,
     
    });


    alert("Item Approved");

    db.collection("Validation")
      .where("Id", "in", [val])
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          doc.ref
            .delete()
            .then(() => {
              console.log("Validation successfully deleted!");
              window.location.reload();
            })
            .catch(function (error) {
              console.error("Error removing document: ", error);
            });
        });
      });

    //  let collectionRef =   db.collection("All").doc(val).delete()
  }

  function deleteitems(val) {
    console.log(val);

    db.collection("Validation")
      .where("Id", "in", [val])
      .get()
      .then((onSnapshot) => {
        onSnapshot.forEach((doc) => {
          doc.ref
            .delete()
            .then(() => {
              alert("Item Removed");

              console.log("Validation successfully deleted!");

              window.location.reload();
            })
            .catch(function (error) {
              console.error("Error removing document: ", error);
            });
        });
      });
  }

  function addtocart(item) {
    if (user) {
      //  this creates a random id for the purposes of cart deletion items
      let mynumber = Math.floor(100000 + Math.random() * 900000);

      db.collection("cart")
        .doc(user?.uid)
        .collection("orders")
        .add({
          idAndCode: id && mynumber,
          id: id,
          ProductType: producttype,
          storename: storename,
          title: title,
          image: image,
          price: price,
          rating: rating,
          prodinfo: prodinfo,
          colour: colour,
          size: size,
          quantity: Number(1),
          user: user.uid,
        })

        .then((docRef) => {
          alert("Item Added to Basket");
        })
        .catch((error) => {
          console.error("Error adding document: ", error);
        });
    } else {
      <script>
        function myFunction(){" "}
        {alert(
          "Please Sign in before trying to add items to the Basket! Thank you"
        )}
      </script>;
    }
  }

  return (
    <div className="mainly1">
      <p>{storename}</p>

      <p>{title}</p>

      <p>{id}</p>

      <div>
        <p>{producttype}</p>
      </div>
      <div>
        <p>{price}</p>
      </div>

      <div>
        <p>{colour} </p>
      </div>
      <div>
        <p> {rating} </p>
      </div>

      <div>
        <p>Description : {prodinfo} </p>
      </div>

      <div>
        <p>Added By : {Addedby} </p>
      </div>

      <img className="profile-image1" src={image} alt="" />

      <div>
        <button
          className="allPaidProducts__backinfo1"
          onClick={() => deleteitems(id)}
        >
          Remove Item{" "}
        </button>
      </div>

      <div>
        <button
          className="allPaidProducts__info1"
          onClick={() => approveitems(id)}
        >
          Approve Item
        </button>
      </div>
    </div>
  );
};

export default Validation;

/*

<div className="item_prices1">
        <small>£</small>
        <strong>{(price, Number(price).toFixed(2))}</strong>
      </div>

*/

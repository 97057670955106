

import React , {useRef} from 'react'
import{ useReactToPrint } from "react-to-print";

const PrintableComp = ()=> {

    const componentRef = useRef();
    const handlePrint = useReactToPrint ({
                      
        content: ()=> componentRef.current,
        documentTitle: 'emp-data',
        onafterprint: ()=> alert('Print Success')
      });
return(

    <>
    <div ref={componentRef} style={{width: '100%',height: window.innerHeight}} >
    <h1 className="text-centre my-3 border py-2">Employee data</h1> 
    </div>

    <button onClick={handlePrint}>Print</button>
    </> 
   
);
};


export default PrintableComp;

/*
    const componentRef = useRef();
    const handlePrint = useReactToPrint ({
                      
    content: ()=> componentRef.current,
    documentTitle: 'emp-data',
    onafterprint: ()=> alert('Print Success')
  });






    <>
         <div ref={componentRef} style={{width: '100%',height: window.innerHeight}} >
             <h1 className="text-centre my-3 border py-2">Employee data</h1> 
         
         
         </div>
        <button 
                 
                 onClick={handlePrint}
                 className="btn-btn-danger"
               >
                Print This order
               </button>
      </>

*/


import { React, useState, useEffect } from "react";
import "./Finance.css";
import { useStateValue } from "./StateProvider";
import { Link, useHistory } from "react-router-dom";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import CurrencyFormat from "react-currency-format";
import axios from "./axios";
import { db } from "./firebase";
import cardslogo from "./images/bankcards.png";
import { Helmet } from "react-helmet";

function Finance2() {
  const [{ basket, user }, dispatch] = useStateValue();
  const history = useHistory();
  const stripe = useStripe();
  const elements = useElements();

  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState("");
  const [succeeded, setSucceeded] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [clientSecret, setClientSecret] = useState(true);

  const [cart, setCart] = useState([]);
  const [products, setProducts] = useState([]);
  const [cart1, setCart1] = useState([]);

  //const createCheckoutSession = async () => {
  // const stripe = await stripePromise;
  //call the backend to create the session

  //}

  useEffect(() => {
    console.log(total());
    //genate the special client stripe secret which allows us to charge a  customer
    const getClientSecret = async () => {
      const response = await axios({
        method: "post",

        url: `/payments/create?total=${
          (total().toFixed(2) * 100) + (1.50 * 100)
        }`,
      });
      setClientSecret(response.data.clientSecret);
    };
    getClientSecret();
  }, [cart]);

  //console.log("The secret is >>>>", clientSecret);
 // console.log(
//    "The cart is >>>>",
 //   total().toFixed(2) * 100 + 1.50 * 100
 // );

  function sayHello(i) {
    console.log(i);

    var variable1 = i;

    Fetchdata(variable1);
  }

  useEffect(() => {
    if (user) {
      let collectionRef = db
        .collection("Authorisation")

        .where("paiditemyn", "==", "No")
        .where("authyn", "==", "Yes")
        .where("postalauth", "==", "Yes");

      collectionRef.onSnapshot((querySnapshot1) => {
        var p = [];
        querySnapshot1.forEach((doc) => {
          p.push(doc.data());
        });

        setCart1(p);
      });
    }
  }, [user]);

  const Fetchdata = (val) => {
    let collectionRef2 = db.collection("cart").doc(val).collection("orders");

    collectionRef2.onSnapshot((querySnapshot) => {
      var p = [];
      querySnapshot.forEach((doc) => {
        p.push(doc.data());
      });
      total();
     // shippingtotal();
      setCart(p);
    });
  };

 /* function updateauth(val) {
    console.log(val);

    db.collection("Authorisation")
      .doc(val)

      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          doc.ref
            .update({
              authyn: "Yes",
            })
            .catch(function (error) {
              console.error("Error updating document: ", error);
            });
        });
      });
  }
*/
  function user11() {
    cart.map((i) => {
      console.log(i.user);

      var variable1 = i.user;

      user111(variable1);
    });
  }

  function user111(variable1) {
    cart1.map((i) => {
      console.log(variable1);
      console.log(i.email);

      var variable2 = i.email;

      updateauth2({ variable1, variable2 });
    });
  }

  function total1() {
    let x1 = 0;
    cart.map((i) => {
      x1 += i.quantity;
    });
    return x1;
  }

  function total() {
    let x = 0;
    cart.map((i) => {
      x += i.price;
    });

    return x;
  }

  function subtotal() {
    let x = 0;
    cart.map((i) => {
      
      x += i.price;
    });

    return x;
  }

/*  function shippingtotal() {
    // let x = 0
    let filly = 0;
    let shipping = 0;
    let shippingjoe = 0;
    let shippingargos = 0;
    let shippingmandm = 0;
    let bigsale = 0;
    cart.map((i) => {
      //   console.log( "values of whats in the cart ....", (i.storename) )

      /// joe browns shipping
      var dataset = [i.storename];
      var search = "Joe Browns";

      var counter = dataset.reduce(function (n, val) {
        return n + (val === search);
      }, 0);

      if (counter >= 1) {
        shippingjoe = 4.0;
      }

      // console.log(counter);
      //  console.log(shippingjoe);
      ///Argos Shipping
      var dataset2 = [i.storename];
      var search2 = "Curries Pc World";

      var counter2 = dataset2.reduce(function (n2, val2) {
        return n2 + (val2 === search2);
      }, 0);

      if (counter2 >= 1) {
        shippingargos = 3.95;
      }

      // console.log(counter2);
      //  console.log(shippingargos);

      ///Sports Direct Shipping

      var dataset3 = [i.storename];
      var search3 = "JD Sports";

      var counter3 = dataset3.reduce(function (n3, val3) {
        return n3 + (val3 === search3);
      }, 0);

      if (counter3 >= 1) {
        shipping = 5.0;
      }

      // console.log(counter3);
      // console.log(shipping);

      /// m& ms shipping
      var dataset4 = [i.storename];
      var search4 = "M&MDirect";

      var counter4 = dataset4.reduce(function (n4, val4) {
        return n4 + (val4 === search4);
      }, 0);

      if (counter4 >= 1) {
        shippingmandm = 5.0;
      }

      //  console.log(counter4);
      //  console.log(shippingmandm);

      /// Bigsale shipping
      var dataset5 = [i.storename];
      var search5 = "Big Sale";

      var counter5 = dataset5.reduce(function (n5, val5) {
        return n5 + (val5 === search5);
      }, 0);

      if (counter5 >= 1) {
        bigsale = 4.9;
      }

      //    console.log(counter5);
      //    console.log(bigsale);

      filly =
        shipping + shippingargos + shippingjoe + shippingmandm + bigsale + 1.5;
    });

    return filly;
  }
  */

  const handleSubmit = async (event) => {
    event.preventDefault();
    setProcessing(true);

    const payload = await stripe
      .confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
        },
      })
      .then(({ paymentIntent }) => {
        // payment intent = payment confirmation
        //console.log(cart.user);
        //const [option] = user?.uid;
      /*  cart1.map((item) =>
        
       
          db
            .collection("paidItems")
            .doc(cart.user)
            .collection("orders")
            .doc(paymentIntent.id)
            .set({
              basket: cart,
              amount: paymentIntent.amount,
              created: paymentIntent.created,
              process: "All Items Paid awaiting order update",
            })
            );
            */
        cart.map((item) =>
          db
            .collection("AllpaidItems")

            .doc(item.user)
            .collection("orders")
            .doc(paymentIntent.id)
            .set({
              basket: cart,
              paymentintentvalue: paymentIntent.id,
              amount: paymentIntent.amount,
              created: paymentIntent.created,
              email: item.email,
              process: "All Items Paid awaiting order update",
              patientId: item.user,
            
            })
        );

        setSucceeded(true);
        setError(null);
        setProcessing(false);
        elements.getElement(CardElement).clear();
        user11();

        //updateauth(user?.uid)
        ////////////////////////////////////////////////
        // history.replace("/finance");
      });
  };
  function updateauth2({ variable1, variable2 }) {
    console.log(variable1);
    console.log(variable2);



    

    db.collection("Authorisation")
      .where("userid", "in", [variable1])
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          doc.ref
            .delete()
            .then(() => {
              deletecartitems(variable1);
              console.log("Authorisation successfully deleted!");
             
              updateauth3({variable1 });
              /////////////////////////////////////////////////
              /// history.replace("/finance");
            })
            .catch(function (error) {
              console.error("Error removing document: ", error);
            });
        });
      });
  }

  function updateauth3({  variable1 }) {
     //console.log(variable1);
    console.log(variable1);

    //  cart1.map((item)  => (
      db.collection("shopUsers")
      .where("id", "in", [variable1])
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          doc.ref.update({
         MadePurchase:"Yes",
        timevalue: Date().toLocaleString(),

      })
    });
  });

    // ))
  }

  function deletecartitems(variable1) {
    console.log(variable1);
    console.log("cart successfully deleted!");
    let collectionRef = db
      .collection("cart")
      .doc(variable1)
      .collection("orders");

    collectionRef.get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        doc.ref
          .delete()
          .then(() => {
            console.log("Document successfully deleted!");

            window.location.reload();
            //history.replace("/finance");
          })
          .catch(function (error) {
            console.error("Error removing document: ", error);
          });
      });
    });
  }



  function removetocart(item) {
    console.log(item)
    console.log(item.user);
    console.log(item.idAndCode);
    let spanishflu = item.user;
        let collectionRef = db
          .collection("cart")
          .doc(item.user)
          .collection("orders");
          console.log(item.user);
          console.log(item.idAndCode);
    
        collectionRef
          .where("idAndCode", "==", item.idAndCode)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              doc.ref
                .delete()
                .then(() => {
                  console.log("Document successfully deleted!");
                  var spinme = total1();
                  console.log(spinme);
                  console.log(spanishflu);
                  if ( spinme <= 1 ){
                    updateauthspanishflu(spanishflu)
                  }
                })
                .catch(function (error) {
                  console.error("Error removing document: ", error);
                });
            });
          });
      }

      function updateauthspanishflu(variable1) {
        console.log(variable1);
       
        db.collection("Authorisation")
          .where("userid", "in", [variable1])
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              doc.ref
                .delete()
                .then(() => {
                
                  console.log("Authorisation successfully deleted!");
                  console.log(variable1);
                  
                  /////////////////////////////////////////////////
                  /// history.replace("/finance");
                })
                .catch(function (error) {
                  console.error("Error removing document: ", error);
                });
            });
          });
      }
    





    
  const handleChange = (event) => {
    //listens for card changes and any errors a customer types in
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };

  return (
    <div className="container_12">
      <Helmet>
        <title>Secure Shopping| Finance</title>
      </Helmet>

      <div className="container_12">
        <h1>Basket Orders Needing Payment </h1>
      </div>
      <div className="container_134">
        <table className="table  text-center">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Baskets</th>
              <th scope="col">User Code</th>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col">User Name</th>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col">To Pay</th>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col">Authorised By</th>
            </tr>
          </thead>
          <tbody>
            {cart1.map((i, index) => (
              <tr key={i.authyn}>
                <th scope="row">{index + 1}</th>
                <th scope="row"></th>
                <td>{i.userid}</td>
                <td>{}</td>
                <td>{}</td>
                <td>{}</td>
                <td>{}</td>
                <td>{i.email}</td>
                <td>{}</td>
                <td>{}</td>
                <td>{}</td>
                <td>{}</td>
                <td>
                  <button
                      onClick={() => sayHello(i.userid,i.email)}
                    className="btn-btn-danger"
                  >
                    Select To Pay
                  </button>
                </td>
                <td>{}</td>
                <td>{}</td>
                <td>
                  <small>{i.id}</small>
                </td>
                <tr>
                {" "}
                  <td>{}</td>
                  <td>{}</td>
                  <td>{}</td>
                  <td>{}</td>
                  <td>{i.managername} "Manager"</td>
                </tr>
               
                <tr>
                  {" "}
                  <td>{}</td>
                  <td>{}</td>
                  <td>{}</td>
                  <td>{}</td>
                  <td>{i.postalName} "Postal"</td>
                </tr>
              </tr>

            ))}
          </tbody>
        </table>
      </div>

      <div className="payment">
        <div className="payment__container">
          <h1>Checkout ({total1()} items )</h1>

          {/* Payment section delivery address */}

          <div className="payment__section">
            <div className="payment__title">
              <h3>Delivery Address</h3>
            </div>
            <div className="payment__address">
              <p>Broadmoor Hospital</p>
              <p>Postal Monitors Office, R and D centre</p>
              <p>Crowthorne, </p>
              <p>RG45 7EG</p>
            </div>
          </div>

          {/* Payment section review items */}

          <div className="payment__section2">
            <div className="container_mt-2">
              {products.map((item) => (
                <div className="col-3" key={item.id}>
                  <img src={item.url} className="card-img-top" />
                  <div className="card-body">
                    <h6 className="card-title">
                      {item.name} - $ {item.price}
                    </h6>
                  </div>
                </div>
              ))}

              <div className="row_mt-3">
                <table className="table__text-center">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Product</th>
                      <th scope="col">Product Name</th>
                      <th scope="col"></th>
                      <th scope="col"></th>
                      <th scope="col">Price</th>
                      <th scope="col"></th>
                      <th scope="col"></th>
                      <th scope="col">Size</th>
                      <th scope="col"></th>
                      <th scope="col"></th>
                      <th scope="col"></th>
                      <th scope="col">Pat. num</th>
                      <th scope="col"></th>
                      <th scope="col"></th>
                      <th scope="col"></th>
                      <th scope="col">Store Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cart.map((i, index) => (
                      <tr key={i.id}>
                        <th scope="row">{index + 1}</th>
                        <th scope="row">
                          <img src={i.image} style={{ width: "4rem" }} />
                        </th>
                        <td>{i.title}</td>
                        <td>{}</td>
                        <td>{}</td>
                        <td>{(i.price, Number(i.price).toFixed(2))}</td>
                        <td>{}</td>
                        <td>{}</td>

                        <td>{i.size}</td>
                        <td>{}</td>
                        <td>{}</td>
                        <td>{}</td>
               

                        <td>{i.email.split("@")[0] }</td>
                        <td>{}</td>
                        <td>{}</td>
                        <td>{}</td>

                        <td>
                          <small>
                            {i.storename} {"-"} {i.id}
                          </small>
                        </td>
                        <td>
                    <button
                      onClick={() => removetocart(i)}
                      className="btn-btn-danger"
                    >
                      Remove
                    </button>
                  </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="row">
                <div className="col text-center">
                  <h4>Number of Items in Basket: {total1()}</h4>
                </div>
                <div className="col text-center">
                  <h5>Sub Total: £ {subtotal().toFixed(2)}</h5>
                </div>
                <div className="col text-center">
                  <h5>
                  Service Total: £ {1.50.toFixed(2)}
                  </h5>
                </div>

                <div className="col text-center">
                  <h4>TOTAL: £ {(total() + 1.50).toFixed(2)}</h4>
                </div>
                <div className="col text-center">
                  <h6>
                    Total includes £1.50 service charge{" "}
                  </h6>
                </div>
              </div>
            </div>
          </div>

          {/* Payment section peyment method */}

          <div className="payment__section">
            <div className="payment__title">
              <h3>Payment Method</h3>
              <img className="header13__logo" src={cardslogo} alt="cardslogo" />
            </div>

            <div className="payment__details">
              {/* Strip magic goes here */}
              <form onSubmit={handleSubmit}>
                <CardElement onChange={handleChange} />

                <div className="payment__priceContainer">
                  <CurrencyFormat
                    renderText={(value) => (
                      <>
                        <h3>
                          Order Total: £
                          {Number(total() + 1.50).toFixed(2)}
                        </h3>
                      </>
                    )}
                    decimalScale={2}
                    value={(total() + 1.50).toFixed(2)}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"£"}
                  />

                  <button
                    role="link"
                    disabled={processing || disabled || succeeded}
                  >
                    <span>{processing ? <p>Processing</p> : "Pay Now"}</span>
                  </button>
                </div>

                {error && <div>{error}</div>}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Finance2;

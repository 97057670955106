import { React, useLayoutEffect, useEffect, useState } from "react";
import "./Header.css";
import { db } from "./firebase";
import { useStateValue } from "./StateProvider";
import { auth } from "./firebase";
import { Link, useHistory } from "react-router-dom";
import bigsale from "./images/sale100.png";
import mamdirectlogo from "./images/mandm100.png";
import jblogo from "./images/joebrowns100.png";
import curriessquare from "./images/curryssquare100.png";
import nikelogo100 from "./images/nikelogo100.png";
import jdsports100 from "./images/jdsports100.png";
import cottontraders100 from "./images/cottontraders100.png";
import hsamuals100 from "./images/hsamuals100.png";
import Shopping from "./images/Shopping.png";
import homeicon from "./images/screen/homeicon.png"

function Header() {
  //this code is used to pull all the info through
  const [{ user }] = useStateValue();
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [cart, setCart] = useState([]);

  // const toggling = () => setIsOpen(!isOpen);
  const button = document.querySelector("#button");
  const tooltip = document.querySelector("#tooltip");

  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
  }

  useEffect(() => {
    if (user) {
      db.collection("cart")
        .doc(user?.uid)
        .collection("orders")

        .onSnapshot((snapshot) =>
          setCart(
            snapshot.docs.map((doc) => ({
              data: doc.data(),
            }))
          )
        );
    } else {
    }
  }, [user]);

  function total1() {
    let x1 = 0;
    cart.map((i) => {
      x1 += i.data.quantity;
    });
    return x1;
  }

  const handleAuthentication = () => {
    if (user) {
      auth.signOut();
      history.replace("/");
    }
  };

  return (
    <div>
      <ul
        className="navinator"
        style={{ zIndex: "1000", position: "fixed", display: "flex" }}
      >
        <li class="drop-item" style={{ float: "below" }}>
          <div id="profile">
            <div style={{ float: "below", paddingRight: "4px" }}>
              <img
                className="header__logo"
                src={curriessquare}
                alt="curriessquare"
              />
            </div>
            <div style={{ float: "left", paddingRight: "4px" }}>
              Currys PC World
            </div>
            <div style={{ float: "left" }} className="arrow-down"></div>
          </div>
          <div style={{ float: "right" }} className="dropdown">
            <li>
              <a className="anchor" style={{ textAlign: "right" }} href="/home">
                Main Page
              </a>
            </li>
            <li>
              <a
                className="anchor"
                style={{ textAlign: "right" }}
                href="/CurrysAll"
              >
                All Items
              </a>
            </li>
            <li>
              <a
                className="anchor"
                style={{ textAlign: "right" }}
                //onClick={gotthisok("CAurrys")}
                href="/CurrysTv"
              >
                TV & Audio
              </a>
            </li>

            <li>
              <a
                className="anchor"
                style={{ textAlign: "right" }}
                href="/CurrysGames"
              >
                Gaming & Games
              </a>
            </li>
            <li>
              <a
                className="anchor"
                style={{ textAlign: "right" }}
                href="/CurrysHealth"
              >
                Health & Beauty
              </a>
            </li>
            <li>
              <a
                className="anchor"
                style={{ textAlign: "right" }}
                href="/CurrysElectrical"
              >
                Electrical
              </a>
            </li>
          </div>
        </li>

        <li class="drop-item" style={{ float: "below" }}>
          <div id="profile">
            <div style={{ float: "below", paddingRight: "4px" }}>
              <img
                className="header__logo"
                src={jdsports100}
                alt="jdsports100"
              />
            </div>
            <div style={{ float: "left", paddingRight: "4px" }}>JD Sports</div>
            <div style={{ float: "left" }} className="arrow-down"></div>
          </div>
          <div style={{ float: "right" }} className="dropdown">
            <li>
              <a className="anchor" style={{ textAlign: "right" }} href="/home">
                Main Page
              </a>
            </li>
            <li>
              <a
                className="anchor"
                style={{ textAlign: "right" }}
                href="/JDSportsAll"
              >
                All Items
              </a>
            </li>
            <li>
              <a
                className="anchor"
                style={{ textAlign: "right" }}
                href="/JDSportsTrousers"
              >
                Trousers/Jeans
              </a>
            </li>
            <li>
              <a
                className="anchor"
                style={{ textAlign: "right" }}
                href="/JDSportsShirts"
              >
                T-Shirts/Shirts
              </a>
            </li>
            <li>
              <a
                className="anchor"
                style={{ textAlign: "right" }}
                href="/JDSportsJackets"
              >
                Knitwear/Jackets
              </a>
            </li>
            <li>
              <a
                className="anchor"
                style={{ textAlign: "right" }}
                href="/JDSportsTops"
              >
                Fleece/Tracksuits
              </a>
            </li>
            <li>
              <a
                className="anchor"
                style={{ textAlign: "right" }}
                href="/JDSportsShoes"
              >
                Trainers/Shoes
              </a>
            </li>
            <li>
              <a
                className="anchor"
                style={{ textAlign: "right" }}
                href="/JDAccessories"
              >
                Accessories
              </a>
            </li>
          </div>
        </li>

        <li class="drop-item" style={{ float: "below" }}>
          <div id="profile">
            <div style={{ float: "below", paddingRight: "4px" }}>
              <img
                className="header__logo"
                src={nikelogo100}
                alt="nikelogo100"
              />
            </div>
            <div style={{ float: "left", paddingRight: "4px" }}>Nike Store</div>
            <div style={{ float: "left" }} className="arrow-down"></div>
          </div>
          <div style={{ float: "right" }} className="dropdown">
            <li>
              <a className="anchor" style={{ textAlign: "right" }} href="/home">
                Main Page
              </a>
            </li>
            <li>
              <a
                className="anchor"
                style={{ textAlign: "right" }}
                href="/NikeAll"
              >
                All Items
              </a>
            </li>
            <li>
              <a
                className="anchor"
                style={{ textAlign: "right" }}
                href="/NikeTrousers"
              >
                Trousers/Jeans
              </a>
            </li>
            <li>
              <a
                className="anchor"
                style={{ textAlign: "right" }}
                href="/NikeShirts"
              >
                T-Shirts/Shirts
              </a>
            </li>
            <li>
              <a
                className="anchor"
                style={{ textAlign: "right" }}
                href="/NikeJackets"
              >
                Tops/Jackets
              </a>
            </li>
            <li>
              <a
                className="anchor"
                style={{ textAlign: "right" }}
                href="/NikeTops"
              >
                Fleece/Tracksuits
              </a>
            </li>
            <li>
              <a
                className="anchor"
                style={{ textAlign: "right" }}
                href="/NikeShoes"
              >
                Trainers/Shoes
              </a>
            </li>
            <li>
              <a
                className="anchor"
                style={{ textAlign: "right" }}
                href="/NikeAccess"
              >
                Accessories
              </a>
            </li>
          </div>
        </li>

        <li class="drop-item" style={{ float: "below" }}>
          <div id="profile">
            <div style={{ float: "below", paddingRight: "4px" }}>
              <img
                className="header__logo"
                src={cottontraders100}
                alt="cottontraders100"
              />
            </div>
            <div style={{ float: "left", paddingRight: "4px" }}>
              Cotton Traders
            </div>
            <div style={{ float: "left" }} className="arrow-down"></div>
          </div>
          <div style={{ float: "right" }} className="dropdown">
            <li>
              <a className="anchor" style={{ textAlign: "right" }} href="/Home">
                Main Page
              </a>
            </li>
            <li>
              <a
                className="anchor"
                style={{ textAlign: "right" }}
                href="/CottonAll"
              >
                All Items
              </a>
            </li>
            <li>
              <a
                className="anchor"
                style={{ textAlign: "right" }}
                href="/CottonTrousers"
              >
                Trousers/Shorts
              </a>
            </li>
            <li>
              <a
                className="anchor"
                style={{ textAlign: "right" }}
                href="/CottonShirts"
              >
                T-Shirts/Shirts
              </a>
            </li>
            <li>
              <a
                className="anchor"
                style={{ textAlign: "right" }}
                href="/CottonJackets"
              >
                Knitwear/Jackets
              </a>
            </li>
            <li>
              <a
                className="anchor"
                style={{ textAlign: "right" }}
                href="/CottonTops"
              >
                Fleece/Tops
              </a>
            </li>
            <li>
              <a
                className="anchor"
                style={{ textAlign: "right" }}
                href="/CottonShoes"
              >
                Trainers/Shoes
              </a>
            </li>
            <li>
              <a
                className="anchor"
                style={{ textAlign: "right" }}
                href="/CottonAccess"
              >
                Accessories
              </a>
            </li>
          </div>
        </li>

        <li class="drop-item" style={{ float: "below" }}>
          <div id="profile">
            <div style={{ float: "below", paddingRight: "4px" }}>
              <img
                className="header__logo"
                src={hsamuals100}
                alt="hsamuals100"
              />
            </div>
            <div style={{ float: "left", paddingRight: "4px" }}>H Samuel</div>
            <div style={{ float: "left" }} className="arrow-down"></div>
          </div>
          <div style={{ float: "left" }} className="dropdown">
            <li>
              <a className="anchor" style={{ textAlign: "right" }} href="/Home">
                Main Page
              </a>
            </li>
            <li>
              <a
                className="anchor"
                style={{ textAlign: "right" }}
                href="/HSamuelAll"
              >
                All Items
              </a>
            </li>
            <li>
              <a
                className="anchor"
                style={{ textAlign: "right" }}
                href="/HSamuelJewels"
              >
                Jewellery
              </a>
            </li>
            <li>
              <a
                className="anchor"
                style={{ textAlign: "right" }}
                href="/HSamuelWatches"
              >
                Watches
              </a>
            </li>
          </div>
        </li>

        <li class="drop-item" style={{ float: "below" }}>
          <div id="profile">
            <div>
              <img className="header__logo" src={jblogo} alt="jblogo" />
            </div>
            <div>Joe Brown's</div>
            <div style={{ float: "left" }} className="arrow-down"></div>
          </div>
          <div style={{ float: "left" }} className="dropdown">
            <li>
              <a className="anchor" style={{ textAlign: "right" }} href="/Home">
                Main Page
              </a>
            </li>
            <li>
              <a
                className="anchor"
                style={{ textAlign: "right" }}
                href="/JoeBAll"
              >
                All Items
              </a>
            </li>
            <li>
              <a
                className="anchor"
                style={{ textAlign: "right" }}
                href="/JoeBTrousers"
              >
                Trousers/Jeans
              </a>
            </li>
            <li>
              <a
                className="anchor"
                style={{ textAlign: "right" }}
                href="/JoeBShirts"
              >
                T-Shirts/Shirts
              </a>
            </li>
            <li>
              <a
                className="anchor"
                style={{ textAlign: "right" }}
                href="/JoeBJackets"
              >
                Knitwear/Jackets
              </a>
            </li>
            <li>
              <a
                className="anchor"
                style={{ textAlign: "right" }}
                href="/JoeBTops"
              >
                Fleece/Tops
              </a>
            </li>
            <li>
              <a
                className="anchor"
                style={{ textAlign: "right" }}
                href="/JoeBShoes"
              >
                Shoes/Boots
              </a>
            </li>
            <li>
              <a
                className="anchor"
                style={{ textAlign: "right" }}
                href="/JoeBAccess"
              >
                Accessories
              </a>
            </li>
          </div>
        </li>

        <li class="drop-item" style={{ float: "left" }}>
          <div id="profile">
            <div style={{ float: "below", paddingRight: "4px" }}>
              <img
                className="header__logo"
                src={mamdirectlogo}
                alt="mamdirectlogo"
              />
            </div>
            <div style={{ float: "left", paddingRight: "4px" }}>M&M Direct</div>
            <div style={{ float: "left" }} className="arrow-down"></div>
          </div>
          <div style={{ float: "right" }} className="dropdown">
            <li>
              <a className="anchor" style={{ textAlign: "right" }} href="/Home">
                Main Page
              </a>
            </li>
            <li>
              <a
                className="anchor"
                style={{ textAlign: "right" }}
                href="/MandmAll"
              >
                All Items
              </a>
            </li>
            <li>
              <a
                className="anchor"
                style={{ textAlign: "right" }}
                href="/MandmTrousers"
              >
                Trousers/Jeans
              </a>
            </li>
            <li>
              <a
                className="anchor"
                style={{ textAlign: "right" }}
                href="/MandmShirts"
              >
                T-Shirts/Shirts
              </a>
            </li>
            <li>
              <a
                className="anchor"
                style={{ textAlign: "right" }}
                href="/MandmJackets"
              >
                Knitwear/Jackets
              </a>
            </li>
            <li>
              <a
                className="anchor"
                style={{ textAlign: "right" }}
                href="/MandmTops"
              >
                Fleece/Tops
              </a>
            </li>
            <li>
              <a
                className="anchor"
                style={{ textAlign: "right" }}
                href="/MandmShoes"
              >
                Shoes/Boots
              </a>
            </li>
            <li>
              <a
                className="anchor"
                style={{ textAlign: "right" }}
                href="/MandmAccess"
              >
                Accessories
              </a>
            </li>
          </div>
        </li>

        <li class="drop-item" style={{ float: "left" }}>
          <div id="profile">
            <div style={{ float: "below", paddingRight: "4px" }}>
              <img className="header__logo" src={homeicon} alt="homeicon" />
            </div>
            <div style={{ float: "left", paddingRight: "4px" }}>Other Things</div>
            <div style={{ float: "left" }} className="arrow-down"></div>
          </div>
          <div style={{ float: "right" }} className="dropdown">
            <li>
              <a
                className="anchor"
                style={{ textAlign: "right" }}
                href="/NewAdditions"
              >
                Newest Additions
              </a>
            </li>
            <li>
              <a
                className="anchor"
                style={{ textAlign: "right" }}
                href="/SalesClothing"
              >
                Clothing
              </a>
            </li>
            <li>
              <a
                className="anchor"
                style={{ textAlign: "right" }}
                href="/SalesDVD"
              >
                DVD's
              </a>
            </li>
            <li>
              <a
                className="anchor"
                style={{ textAlign: "right" }}
                href="/SalesMusic"
              >
                Music
              </a>
            </li>
            <li>
              <a
                className="anchor"
                style={{ textAlign: "right" }}
                href="/SalesElectrical"
              >
                Electrical
              </a>
            </li>
          </div>
        </li>

        <li class="drop-item" style={{ float: "center" }}>
          <div id="profile">
            <div className="header__optionBasketfont">
              <Link to="/ShoppingCart">
                <div className="badge badge-warning" id="lblCartCount">
                  {" "}
                  {total1()}
                </div>
                <img
                  className="header__optionBasket"
                  src={Shopping}
                  alt="Shopping"
                />
              </Link>

              <div className="col text-center">
                <h4>Basket Items</h4>
              </div>
            </div>
          </div>
        </li>

        <li class="drop-item34" style={{ float: "right" }}>
          <div id="profile">
            <div
              style={{
                float: "right",
                paddingBottom: "5%",
                paddingRight: "5px",
                paddingLeft: "5%",
              }}
            >
              <div onClick={handleAuthentication} className="header__option">
                <span className="header__optionLineone">Hello,</span>
                <span className="header__optionLineone">
                  {user ? user?.email.split("@")[0] : "Guest"}{" "}
                </span>

                <span className="header__optionLinetwo">
                  {user ? "Sign Out" : "Sign In"}
                </span>
              </div>
            </div>

            <div style={{ float: "left" }} className="arrow-down"></div>
          </div>
          <div style={{ float: "left" }} className="dropdown34">
            <li>
              <a
                className="anchor"
                style={{ textAlign: "center" }}
                href="Login"
              >
                <div onClick={handleAuthentication} className="header__option">
                  <span className="header__optionLineone">
                    {user ? user?.email.split("@")[0] : "Guest"}{" "}
                  </span>

                  <span className="header__optionLinetwo">
                    {user ? "Sign Out ?" : "Sign In"}
                  </span>
                </div>
              </a>
            </li>

            <li>
              <a
                className="anchor"
                style={{ textAlign: "left" }}
                href="LoginNewPassword"
              >
                <span className="header__optionLinetwo">
                  {user ? "Change Password" : ""}
                </span>
              </a>
            </li>
          </div>
        </li>

        <li class="drop-item" style={{ float: "left" }}>
          <div id="profile">
            <div
              style={{
                float: "center",
                paddingBottom: "25%",
                paddingRight: "45px",
                paddingLeft: "5%",
              }}
            >
              <Link to="/Orders">
                <div className="header__option">
                  <span className="header__optionLineOne">My Paid Orders</span>

                
                </div>
              </Link>

              <Link to="/OutOfStockOrders">
                <div className="header__option">
                  <span className="header__optionLineOne">My Out of Stock Orders </span>

                
                </div>
              </Link>

            </div>
          </div>
        </li>
      </ul>
    </div>
  );
}

export default Header;

import { React, useState } from "react";
import "./FinanceHeader.css";
import { useStateValue } from "./StateProvider";
import { db, auth } from "./firebase";
import { Link, useHistory } from "react-router-dom";
import Accepted from "./images/AcceptedItems1.png";
import returnicon from "./images/returnitems.png";
import placeorder from "./images/placeorder1.png";
import allpaid from "./images/allpaid1.png";



function ToolsHeader() {
    const [{ basket }, dispatch] = useStateValue();

    //this code is used to pull all the info through
    const [{ user }] = useStateValue();
    const history = useHistory();
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [cart, setCart] = useState([]);
  
    // const toggling = () => setIsOpen(!isOpen);
    const button = document.querySelector("#button");
    const tooltip = document.querySelector("#tooltip");
  
    const onOptionClicked = (value) => () => {
      setSelectedOption(value);
      <Link to={"/Prods"}> </Link>;
      setIsOpen(false);
      console.log(selectedOption);
    };
  
    const handleAuthentication1 = () => {
      if (user) {
        auth.signOut();
        history.replace("/Prods");
      }
    };


   
    
  
    function total1() {
      let x1 = 0;
      cart.map((i) => {
        x1 += i.quantity;
      });
      return x1;
    }
  
    return (
      <div>
        <ul
          className="navinatorfine"
         
        >
         
       


          <li class="drop-item" style={{ float: "below" }}>
          <div id="profile">
            <div style={{ float: "below", paddingRight: "24px" }}>
              <img
                className="header__logo"
                src={allpaid}
                alt="/Validation3"
              />
            </div>
            <div style={{ float: "left", paddingRight: "24px" }}>Update Sizes</div>
            <div style={{ float: "left" }} className="arrow-down"></div>
          </div>
          <div style={{ float: "right" }} className="dropdown">
           
            <li>
              <a
                className="anchor"
                style={{ textAlign: "right" }}
                href="/Validation2"
              >
                All Items
              </a>
            </li>
            <li>
              <a
                className="anchor"
                style={{ textAlign: "right" }}
                href="/Validation2_Trousers"
               
              >
                Trousers/Jeans
              </a>
             
            </li>
            <li>
              <a
                className="anchor"
                style={{ textAlign: "right" }}
                href="/Validation2_Shirts"
              >
                T-Shirts/Shirts
              </a>
            </li>
            <li>
              <a
                className="anchor"
                style={{ textAlign: "right" }}
                href="/Validation2_Knitwear"
              >
                Knitwear/Jackets
              </a>
            </li>
            <li>
              <a
                className="anchor"
                style={{ textAlign: "right" }}
                href="/Validation2_Sweatshirts"
              >
                Fleece/Tracksuits
              </a>
            </li>
            <li>
              <a
                className="anchor"
                style={{ textAlign: "right" }}
                href="/Validation2_shoes"
              >
                Trainers/Shoes
              </a>
            </li>
            <li>
              <a
                className="anchor"
                style={{ textAlign: "right" }}
                href="/Validation2_Watches"
              >
                Watches
              </a>
            </li>
            <li>
              <a
                className="anchor"
                style={{ textAlign: "right" }}
                href="/Validation2_TVs"
              >
                TV's and Audio
              </a>
            </li>
            <li>
              <a
                className="anchor"
                style={{ textAlign: "right" }}
                href="/Validation2_Health"
              >
               Health
              </a>
            </li>
            <li>
              <a
                className="anchor"
                style={{ textAlign: "right" }}
                href="/Validation2_Gaming"
              >
                Gaming
              </a>
            </li>
            <li>
              <a
                className="anchor"
                style={{ textAlign: "right" }}
                href="/Validation2_Jewellery"
              >
               Jewellery
              </a>
            </li>
            <li>
              <a
                className="anchor"
                style={{ textAlign: "right" }}
                href="/Validation2_DVD"

                
              >
              DVDs

            
              </a>
             
            </li>
          </div>
        </li>





          


          <li class="drop-item" style={{ float: "below" }}>
          <div id="profile">
            <div style={{ float: "below", paddingRight: "14px" }}>
              <img
                className="header__logo"
                src={allpaid}
                alt="/Validation3"
              />
            </div>
            <div style={{ float: "left", paddingRight: "4px" }}>Update Prices</div>
            <div style={{ float: "left" }} className="arrow-down"></div>
          </div>
          <div style={{ float: "right" }} className="dropdown">
           
            <li>
              <a
                className="anchor"
                style={{ textAlign: "right" }}
                href="/Validation3"
              >
                All Items
              </a>
            </li>
            <li>
              <a
                className="anchor"
                style={{ textAlign: "right" }}
                href="/Validation3_Trousers"
               
              >
                Trousers/Jeans
              </a>
             
            </li>
            <li>
              <a
                className="anchor"
                style={{ textAlign: "right" }}
                href="/Validation3_Shirts"
              >
                T-Shirts/Shirts
              </a>
            </li>
            <li>
              <a
                className="anchor"
                style={{ textAlign: "right" }}
                href="/Validation3_Knitwear"
              >
                Knitwear/Jackets
              </a>
            </li>
            <li>
              <a
                className="anchor"
                style={{ textAlign: "right" }}
                href="/Validation3_Sweatshirts"
              >
                Fleece/Tracksuits
              </a>
            </li>
            <li>
              <a
                className="anchor"
                style={{ textAlign: "right" }}
                href="/Validation3_Shoes"
              >
                Trainers/Shoes
              </a>
            </li>
            <li>
              <a
                className="anchor"
                style={{ textAlign: "right" }}
                href="/Validation3_Watches"
              >
                Watches
              </a>
            </li>
            <li>
              <a
                className="anchor"
                style={{ textAlign: "right" }}
                href="/Validation3_TV"
              >
                TV's and Audio
              </a>
            </li>
            <li>
              <a
                className="anchor"
                style={{ textAlign: "right" }}
                href="/Validation3_Health"
              >
               Health
              </a>
            </li>
            <li>
              <a
                className="anchor"
                style={{ textAlign: "right" }}
                href="/Validation3_Gaming"
              >
                Gaming
              </a>
            </li>
            <li>
              <a
                className="anchor"
                style={{ textAlign: "right" }}
                href="/Validation3_Jewellery"
              >
               Jewellery
              </a>
            </li>
            <li>
              <a
                className="anchor"
                style={{ textAlign: "right" }}
                href="/Validation3_DVD"

                
              >
              DVDs

            
              </a>
             
            </li>
          </div>
        </li>










          <li class="drop-itemfine" >
            <div id="profile11">
              <div className="header__optionfine">
                <Link to="/Prods">
                  <img
                    className="header__optionBasketfine"
                    src={placeorder}
                    alt="Prods"
                  />
                </Link>
  
                <Link to="/Prods" className="header__optionfine">
                  <span className="header__optionLineonefine">Add New Products </span>
                </Link>
               
              </div>
            </div>
          </li>
  
          <li class="drop-itemfine" >
            <div id="profile11">
              <div
                style={{
                  float: "left",
                  paddingBottom: "5px",
                  paddingRight: "5px",
                }}
              >
                <div
                  onClick={handleAuthentication1}
                  className="header__optionfine"
                >
                  <span className="header__optionLineonefine">
                    Hello, {user ? user?.email.split("@")[0] : "Guest"}{" "}
                  </span>
  
                  <span className="header__optionLinetwofine">
                    {user ? "Sign Out" : "Sign In"}
                  </span>
                </div>
              </div>
              <div style={{ float: "left" }} className="arrow-down"></div>
            </div>
            <div
              style={{ paddingTop: "125px" }}
              className="dropdown"
            >
              <li>
                <a className="anchor" href="ToolsLogin">
                  <div
                    onClick={handleAuthentication1}
                    className="header__optionfine"
                  >
                    <span className="header__optionLineonefine">
                      Hello, {user ? user?.email.split("@")[0] : "Guest"}{" "}
                    </span>
  
                    <span className="header__optionLinetwofine">
                      {user ? "Sign Out ?" : "Sign In"}
                    </span>
                  </div>
                </a>
              </li>












  
              <li>
                <a
                  className="anchor"
                  href="ToolsNewPassword"
                >
                  <span className="header__optionLinetwofine">
                    {user ? "Change Password" : ""}
                  </span>
                </a>
              </li>
            </div>
          </li>
  
          <li class="drop-itemfine" >
            <div id="profile11">
              <div className="header__optionfine">
                <Link to="/Validation">
                  <img
                    className="header__optionBasketfine"
                    src={Accepted}
                    alt="Validation"
                  />
                </Link>
                <div style={{ paddingRight: "0px" }}>Validate Items</div>
              </div>
            </div>
          </li>


          <li class="drop-itemfine" >
            <div id="profile11">
              <div className="header__optionfine">
                <Link to="/OutOfStock">
                  <img
                    className="header__optionBasketfine"
                    src={Accepted}
                    alt="OutOfStock"
                  />
                </Link>
                <div style={{ paddingRight: "0px" }}>Out of stock Items</div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    );
  }

export default ToolsHeader
import React from "react";
import "./AllPaidOrders.css";
import moment from "moment";
import AllPaidProducts from "./AllPaidProducts";
import CurrencyFormat from "react-currency-format";
import { Helmet } from "helmet";
import { db } from "./firebase";

function AllPaidOrders({ order})

 {


    var formatter = new Intl.NumberFormat('gbp', {
        style: 'currency',
        currency: 'GBP',
      
        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });



//<p>{(order.data.colour)}</p>
//<p>{(order.data.storename)}</p>
      



  return (
    <div className="order">



      
      <h2>Order</h2>
      <p>{moment.unix(order.data.created).format("Do MMMM YYYY, h:mma")}</p>
     
      <p className="order__id">
        <small>Order id - {order.id}</small>
      </p>
      <p className="order__id2">
        <strong>User Name - {order.data.email}</strong>
      </p>
      <p className="order__id2">
        <strong>Order Status - {order.data.process}</strong>
      </p>
       

      
     
      
      

      {order.data.basket?.map((item) => (
        <AllPaidProducts
          id={item.id}
          storename={item.storename}
          title={item.title}
          size={item.size}
          image={item.image}
          price={item.price}
          rating={item.rating}
          colour={item.colour}
          prodinfo={item.user}
          datepaid = {order.data.created}
          grandtotal = {order.data.amount}
          idAndCode = {item.idAndCode}
          process = {order.data.process}
          email = {order.data.email}
          status = {item.status}
          paymentintentvalue = {order.data.paymentintentvalue}
          hideButtons
         
   
        
        />
      ))}

      <CurrencyFormat
        renderText=  {(value) => (
          <h3 className="order__total">Basket Grand Total: { value}</h3>
          
        )}
        decimalScale={2}
        value={ formatter.format (order.data.amount / 100)}
        displayType={"text"}
        thousandSeparator={true}
        prefix={"£"}
      />


    </div>
  );
}

export default AllPaidOrders
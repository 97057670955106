import React from "react";
import "./AllReturnedOrders.css";
import moment from "moment";
import AllReturnedProducts from "./AllReturnedProducts";
import CurrencyFormat from "react-currency-format";
import { Helmet } from "helmet";
import { db } from "./firebase";


function AllReturnedOrders({ order }) {


    var formatter = new Intl.NumberFormat('gbp', {
        style: 'currency',
        currency: 'GBP',
      
        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });

var prodid = order.id;
var idandcode = order.data.idAndCode;
var paymentintentvalue = order.data.paymentintentvalue;
var title = order.data.title;

//<p>{(order.data.colour)}</p>
//<p>{(order.data.storename)}</p>








      
function updateReturedDetais(num){

             console.log(num);
             console.log(prodid)
             console.log(idandcode)
              console.log(paymentintentvalue)
              console.log(title)
  
          db.collection("OrdersReturned").doc(num).collection('OrdersSentBack')
       
        
         
          .where('idAndCode', 'in' , [idandcode])
           
            .get()
            .then((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                doc.ref.update({
                  process: "Refund Recieved", 


                


                  
                 
        
              }).catch(function(error) {
                  console.error("Error updating document: ", error);
                });
              });
            
            alert("Refund Recieved");
            gotthisok(num)

            /*

             db.collection('AllPaidItemsId')
    .where('userid', 'in' , [val])
    .get()
    .then(querySnapshot => {
      querySnapshot.forEach((doc) => {
        doc.ref.update({
          paiditemyn: "Yes", 
         

      }).catch(function(error) {
          console.error("Error updating document: ", error);
        });
       
      })
      
    });
            */
       
  })      
}

function gotthisok(num) {
  const reference = db.collection("AllpaidItems")
                  .doc(num)
                  .collection("orders").doc(paymentintentvalue)
                 // reference.where(val, "==", idAndCode)
                 reference.update({
            
                  process:  " " + title + " UPDATE-- Refund Recieved" + '\n' + " , ",
                  
                 
                 })
                  console.log("nothing happened did it?")
               
                
               
}






  return (
    <div class="boxedNew">
    <div className="order">
     
      <h2>Order</h2>
      <p>{moment.unix(order.data.datepaid).format("Do MMMM YYYY, h:mma")}</p>
      <p><strong>Order Status - {(order.data.process)}</strong></p>
      <p className="order__id">
        <small>Order Returned id - {order.id}</small>
      </p>
    
     
      <img className="allPaidProducts2__image2" src={(order.data.image)} />
      <p>Item Name - {(order.data.title)}</p>
      <p>Store Name - {(order.data.storename)}</p>
      <p>Colour - {(order.data.colour)}</p>
      <p>Size - {(order.data.size)}</p>
      <p>User Id - {(order.data.prodinfo)}</p>
      
      <CurrencyFormat
        renderText=  {(value) => (
          <h3 >Item Total: { value}</h3>
        )}
        decimalScale={2}
        value={ formatter.format (order.data.price)}
        displayType={"text"}
        thousandSeparator={true}
        prefix={"£"}
      />
    
     
      <p><small>Item id - {(order.data.id)}</small></p>
 
    
      <p className="order__id">
        <small>{order.storename}</small>
      </p>
      <button
                    onClick={() => updateReturedDetais(order.data.prodinfo)}
                    className="btn-btn-danger"
                  >
                    Refund Recieved
                  </button>
     
      </div>
      
      {order.data.basket?.map((item) => (
        <AllReturnedProducts
          id={item.id}
          storename={item.storename}
          title={item.title}
          size={item.size}
          image={item.image}
          price={item.price}
          rating={item.rating}
          colour={item.colour}
          prodinfo={item.user}
          grandtotal={item.grandtotal}
          process = {order.data.process}
          hideButtons
         
        
        />
      ))}
     

      <CurrencyFormat
        renderText=  {(value) => (
          <h3 className="order__total">Order Total: { value}</h3>
        )}
        decimalScale={2}
        value={ formatter.format (order.data.grandtotal  / 100)}
        displayType={"text"}
        thousandSeparator={true}
        prefix={"£"}
      />
       <p className="order__total"><small>This total includes all items in basket as well as delivery cost and service charge of £1.50</small></p>

    </div>
  );
}


export default AllReturnedOrders

import  React, {useRef ,useState , useEffect} from "react";

import "./Finance.css";
import { useStateValue } from "./StateProvider";
import { Link, useHistory } from "react-router-dom";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import CurrencyFormat from "react-currency-format";
import axios from "./axios";
import { db } from "./firebase";
import cardslogo from "./images/bankcards.png";
import { Helmet } from "react-helmet";
import { now } from "moment";
import useReactToPrint from "react-to-print";



function Finance() {
    const [{ basket, user }, dispatch] = useStateValue();
    const history = useHistory();
    const stripe = useStripe();
    const elements = useElements();
    const [price, Setprice] = useState("");
    const [val, setVal] = useState();
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState("");
    const [succeeded, setSucceeded] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [clientSecret, setClientSecret] = useState(true);
  
    const [cart, setCart] = useState([]);
    const [products, setProducts] = useState([]);
    const [cart1, setCart1] = useState([]);
    const componentRef = useRef();
  
   
   
  
    useEffect(() => {
      console.log(total());
      //genate the special client stripe secret which allows us to charge a  customer
      const getClientSecret = async () => {
        const response = await axios({
          method: "post",
  
          url: `/payments/create?total=${
            (total().toFixed(2) * 100) + (1.50 * 100)
          }`,
        });
        setClientSecret(response.data.clientSecret);
      };
      getClientSecret();
    }, [cart]);
  
    //console.log("The secret is >>>>", clientSecret);
   // console.log(
  //    "The cart is >>>>",
   //   total().toFixed(2) * 100 + 1.50 * 100
   // );
  
    function sayHello(i) {
      console.log(i);
  
      var variable1 = i;
  
      Fetchdata(variable1);
    }
  
    useEffect(() => {
      if (user) {
        let collectionRef = db
          .collection("Authorisation")
  
          .where("paiditemyn", "==", "No")
          .where("authyn", "==", "Yes")
          .where("postalauth", "==", "Yes");
  
        collectionRef.onSnapshot((querySnapshot1) => {
          var p = [];
          querySnapshot1.forEach((doc) => {
            p.push(doc.data());
            console.log(p)
          });
  
          setCart1(p);
        });
      }
    }, [user]);
  
    const Fetchdata = (val) => {
      let collectionRef2 = db.collection("cart").doc(val).collection("orders");
  
      collectionRef2.onSnapshot((querySnapshot) => {
        var p = [];
        querySnapshot.forEach((doc) => {
          p.push(doc.data());
        });
        total();
       // shippingtotal();
        setCart(p);
      });
    };
  


    
   /* function updateauth(val) {
      console.log(val);
  
      db.collection("Authorisation")
        .doc(val)
  
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            doc.ref
              .update({
                authyn: "Yes",
              })
              .catch(function (error) {
                console.error("Error updating document: ", error);
              });
          });
        });
    }
  */
    function user11() {
      cart.map((i) => {
        console.log(i.user);
  
        var variable1 = i.user;
  
        user111(variable1);
      });
    }
  
    function user111(variable1) {
      cart1.map((i) => {
        console.log(variable1);
        console.log(i.email);
  
        var variable2 = i.email;


       
  
      //  updateauth2({ variable1, variable2 });
      });
    }
  
    function total1() {
      let x1 = 0;
      cart.map((i) => {
        x1 += i.quantity;
      });
      return x1;
    }

    function useridnumber() {
      let xpie = "";
    cart.map((i) => {
     
        xpie = i.user ;
       
    //  console.log(xpie)
      console.log(xpie)
      });
     // return xpie;
      return xpie;
    }
  
    function total() {
      let x = 0;
      cart.map((i) => {
        x += i.price;
      });
  
      return x;
    }
  
    function subtotal() {
      let x = 0;
      cart.map((i) => {
        
        x += i.price;
      });
  
      return x;
    }
  
  
    
   
    //const handleSubmit = async (event) => {

    /*  function handleSubmit(valmoo) {
     /// event.preventDefault();
     console.log("The secret is >>>>", valmoo);
     

     cart.map((item) =>
          db
            .collection("AllStockItemsPaid")

            .doc(item.user)
            .collection("orders")
            .doc(item.id)
            .set({
              basket: cart,
              email: item.email,
              process: "All Items Paid awaiting order update",
              patientId: item.user,
            
            })
        );  
             
    }; */


    function updateauth2({ variable1}) {
      console.log(variable1);
      let mooppoo = useridnumber() 
                  console.log(mooppoo)
  
      db.collection("Authorisation")
        .where("userid", "in", [variable1])
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            doc.ref
              .delete()
              .then(() => {
                deletecartitems(mooppoo);
                console.log("Authorisation successfully deleted!");
               
                updateauth3({mooppoo});
                /////////////////////////////////////////////////
                /// history.replace("/finance");
              })
              .catch(function (error) {
                console.error("Error removing document: ", error);
              });
          });
        });
    }
  
    function updateauth3(noonoo) {
       //console.log(variable1);
      console.log(noonoo);
  
      //  cart1.map((item)  => (
        db.collection("shopUsers")
        .where("id", "in", [noonoo])
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            doc.ref.update({
           MadePurchase:"YesNew",
          timevalue: Date().toLocaleString(),
  
        })
      });
    });
  
      // ))
    }
  
    function deletecartitems(variable1) {
      console.log(variable1);
     
      let collectionRef = db
        .collection("cart")
        .doc(variable1)
        .collection("orders");
  
      collectionRef.get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          doc.ref
            .delete()
            .then(() => {
              
  
              window.location.reload();
              console.log("cart successfully deleted!");
          
            })
            .catch(function (error) {
              console.error("Error removing document: ", error);
            });
        });
      });
    }
  
  
  
    function removetocart(item) {
      console.log(item)
      console.log(item.user);
      console.log(item.idAndCode);
      let spanishflu = item.user;
          let collectionRef = db
            .collection("cart")
            .doc(item.user)
            .collection("orders");
            console.log(item.user);
            console.log(item.idAndCode);
      
          collectionRef
            .where("idAndCode", "==", item.idAndCode)
            .get()
            .then((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                doc.ref
                  .delete()
                  .then(() => {
                    console.log("Document successfully deleted!");
                    var spinme = total1();
                    console.log(spinme);
                    console.log(spanishflu);
                    if ( spinme <= 1 ){
                      updateauthspanishflu(spanishflu)
                    }
                  })
                  .catch(function (error) {
                    console.error("Error removing document: ", error);
                  });
              });
            });
        }
  
        function updateauthspanishflu(variable1) {
          console.log(variable1);
         
          db.collection("Authorisation")
            .where("userid", "in", [variable1])
            .get()
            .then((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                doc.ref
                  .delete()
                  .then(() => {
                    
                    console.log("Authorisation successfully deleted!");
                    console.log(variable1);
                    
                    /////////////////////////////////////////////////
                    /// history.replace("/finance");
                  })
                  .catch(function (error) {
                    console.error("Error removing document: ", error);
                  });
              });
            });
        }
      
        function itemOrdered(item) {
          console.log(item)
          console.log(item.user);
          console.log(item.idAndCode);
          let spanishflu = item.user;
              let collectionRef = db
                .collection("cart")
                .doc(item.user)
                .collection("orders");
                console.log(item.user);
                console.log(item.idAndCode);
          
              collectionRef
                .where("idAndCode", "==", item.idAndCode)
                .get()
                .then((querySnapshot) => {
                  querySnapshot.forEach((doc) => {
                  
                    
                        doc.ref
                          .update({
                            status: "Item has been ordered",
                            authorised:"Item has been ordered",
                          })
              
                      .then(() => {
                        console.log("Item has been ordered");
                        updateauth3(item.user)
                       
                      })
                      .catch(function (error) {
                        console.error("Error updating document: ", error);
                      });
                  });
                });
            }


          
                
                    function changePrice(item){
                      
                      console.log(price)
                      var myFloat = parseFloat(price); //10.256
                      console.log(price)
                      console.log(item)
                       console.log(item.user);
                             console.log(item.idAndCode);
                         
                  let collectionRef = db
                    .collection("cart")
                    .doc(item.user)
                    .collection("orders");
                    console.log(item.user);
                    console.log(item.idAndCode);
              
                  collectionRef
                    .where("idAndCode", "==", item.idAndCode)
                    .get()
                    .then((querySnapshot) => {
                      querySnapshot.forEach((doc) => {
                      
                        
                            doc.ref
                            .update({
                              //just update the price of item
                              price: Number(price),
                            })
                  
                          .then(() => {
                            console.log("Price Changed");
                           
                          })
                          .catch(function (error) {
                            console.error("Error updating document: ", error);
                          });
                      });
                    });
                    }

  
            function itemOutOfStock(item) {
              console.log(item)
              console.log(item.user);
              console.log(item.idAndCode);
              let spanishflu = item.user;
                  let collectionRef = db
                    .collection("cart")
                    .doc(item.user)
                    .collection("orders");
                    console.log(item.user);
                    console.log(item.idAndCode);
              
                  collectionRef
                    .where("idAndCode", "==", item.idAndCode)
                    .get()
                    .then((querySnapshot) => {
                      querySnapshot.forEach((doc) => {
                      
                        
                            doc.ref
                              .update({
                                status: "Item Is Out Of Stock",
                                authorised:"Item Is Out Of Stock",
                              })
                  
                          .then(() => {
                            console.log("IItem Is Out Of Stock");
                            itemOutOfStockcont(item)
                          })
                          .catch(function (error) {
                            console.error("Error updating document: ", error);
                          });
                      });
                    });
                }

                function itemOutOfStockcont(item) {
                  console.log(item)
                  console.log(item.user);
                  console.log(item.idAndCode);
                  let spanishflu = item.user;
    
                  db.collection("ItemsOutOfStock")
        
                    .doc(item.user)
                    .collection("orders")
                    .doc(item.title)
                    .set({
                      
                      email: item.email,
                      process: "Item is out of stock!",
                      patientId: item.user,
                      image:item.image,
                      price: item.price,
                      size: item.size,
                      storeName: item.storename,
                    
                      id: item.id,
                     // ProductType: item.producttype,
                    
                      title:item.title,
                      colour: item.colour,
                      rating: item.rating,
                      prodinfo: item.prodinfo,
                      dateview: Date().toLocaleString(),
                     
                     
                    
                    })
        
    
                              .then(() => {
                                console.log("Item is out of stock!");
                                removetocart(item);
                              })
                              .catch(function (error) {
                                console.error("Error updating document: ", error);
                              });
                            }
                        
                    
                           
                            function deletefartitems(valloo) {

                              let mooppoo = useridnumber() 
                              console.log(mooppoo)
                             
                             // console.log(colour)
                             
                              
                                  cart.map((item) =>
                                  db
                                    .collection("AllpaidNewItems")

                                    .doc(item.user)
                                    .collection("orders")
                                    .doc(Date().toLocaleString())
                                    .set({
                                      basket: cart,
                                      amount: (total() + 1.50).toFixed(2),
                                      email: item.email,
                                      process: "All Items Paid awaiting order update",
                                      patientId: item.user,
                                      dateview: Date().toLocaleString(),
                 
                                    })
                                    .then(() => {

                                      let collectionRef = db
                                      .collection("cart")
                                      .doc(item.user)
                                      .collection("orders");
                                
                                    collectionRef.get().then((querySnapshot) => {
                                      querySnapshot.forEach((doc) => {
                                        doc.ref
                                          .delete()
                                      console.log("Document successfully deleted!");
                                      updateauthspanishflu(item.user)
                                    })})}
                                    
                                    
                                    )
                                    .catch(function (error) {
                                      console.error("Error removing document: ", error);
                                  
                            })) }
                               
                            


                       
                
                /*

                function deletecartitems(valloo) {

                  let mooppoo = useridnumber() 
                  console.log(mooppoo)
                 
                 // console.log(colour)
                  console.log("cart successfully deleted!");
                  
                    
                  let collectionRef = db
                    .collection("cart")
                    .doc(mooppoo)
                    .collection("orders");
              
                  collectionRef.get().then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                      doc.ref
                        .delete()
                        .then(() => {
                          console.log("Document successfully deleted!");
                          updateauthspanishflu(mooppoo)
                       //   window.location.reload();
                          //history.replace("/finance");
                        })
                        .catch(function (error) {
                          console.error("Error removing document: ", error);
                        });
                    });
                  });
                  
                }
                        */
  
                const sub = (e) => {
                  e.preventDefault();
              
                  var myFloat = parseFloat(price); //10.256
                 
                };
               
                
    return (

    

      <div className="container_12">
        <Helmet>
          <title>Secure Shopping| Finance</title>
        </Helmet>
  
        <div className="container_12">
          <h1>Basket Orders Needing Payment </h1>
        </div>
        <div className="container_134">
          <table className="table  text-center">
            <thead>
              <tr>
              <th scope="col"></th>
                <th scope="col">#</th>
                <th scope="col">Baskets</th>
                <th scope="col"></th>
                <th scope="col"></th>
                <th scope="col">User Name</th>
                <th scope="col"></th>
                <th scope="col"></th>
                <th scope="col"></th>
                <th scope="col"></th>
                <th scope="col">To Pay</th>
                <th scope="col"></th>
                <th scope="col"></th>
                <th scope="col"></th>
                <th scope="col">Authorised By</th>
              </tr>
            </thead>
            <tbody>
              {cart1.map((i, index) => (
                <tr key={i.authyn}>
                  <th scope="col"></th>
                  <th scope="col"></th>
                  <th scope="row">{index + 1}</th>
                  <th scope="row"></th>
                 
                 
                  <td>{}</td>
                  <td>{i.email}</td>
                  <td>{}</td>
                  <td>{}</td>
                  <td>{}</td>
                  <td>{}</td>
                  <td>
                    <button
                        onClick={() => sayHello(i.userid,i.email)}
                      className="btn-btn-danger212"
                    >
                      Select To Pay
                    </button>
                  </td>
                  <td>{}</td>
                  <td>{}</td>
                  <td>
                    <small>{i.id}</small>
                  </td>
                  <tr>
                  {" "}
                    <td>{}</td>
                    <td>{}</td>
                    <td>{}</td>
                    <td>{}</td>
                    <td>{i.managername} "Manager"</td>
                  </tr>
                 
                  <tr>
                    {" "}
                    <td>{}</td>
                    <td>{}</td>
                    <td>{}</td>
                    <td>{}</td>
                    <td>{i.postalName} "Postal"</td>
                  </tr>
                </tr>
  
              ))}
            </tbody>
          </table>
        </div>
  
        <div className="payment">
          <div className="payment__container">
            <h1>Checkout ({total1()} items )</h1>
  
            {/* Payment section delivery address */}
  
            <div className="payment__section">
            
            </div>
  
            {/* Payment section review items */}
  
            <div className="payment__section2">
              <div className="container_mt-2">
                {products.map((item) => (
                  <div className="col-3" key={item.id}>
                    <img src={item.url} className="card-img-top" />
                    <div className="card-body">
                      <h6 className="card-title">
                        {item.name} - $ {item.price}
                      </h6>
                    </div>
                  </div>
                ))}
  
                <div className="row_mt-3">
                  <table className="table__text-center">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                        <th scope="col">Product Name</th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                        <th scope="col">Price</th>
                        <th scope="col"></th>
                        <th scope="col">UpdatePrice</th>
                        <th scope="col"></th>
                        <th scope="col">Size</th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                        <th scope="col">Pat. num</th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                        <th scope="col">Store Name</th>
                      </tr>
                    </thead>
                    <tbody>
                      {cart.map((i, index) => (
                        <tr key={i.id}>
                       
                       <div className="noticeBoard">
                           {i.status} 
                  
                        
                            </div>

                          
                             
                          <th scope="row">{index + 1}</th>
                          <th scope="row">
                            <img src={i.image} style={{ width: "4rem" }} />
                          </th>
                          <td>{i.title}</td>
                          <td>{}</td>
                          <td>{}</td>

                          <td>{(i.price, Number(i.price).toFixed(2))}</td>
                          <td>{}</td>

                         
                      
                          
                         
                          <td>{ <input
                            type="text"
                            size="17"
                            placeholder="Update Price Paid"
                            value={val}
                            onChange={(e) => {
                              Setprice(
                                Number(e.target.value).toFixed(2)
                              );
                            }}
                          />}</td>
                          <td>{}</td>
                          <td>{i.size}</td>
                          <td>{}</td>
                          <td>{}</td>
                          <td>{}</td>
                 
  
                          <td>{i.email.split("@")[0] }</td>
                          <td>{}</td>
                          <td>{}</td>
                          <td>{}</td>
  
                          <td>
                            <small>
                              {i.storename} {"-"} {i.id}
                            </small>
                          </td>
                          <td>
                      <button
                        onClick={() => changePrice(i)}
                        className="allPriceProducts__updateproducts"
                      >
                        Update Price
                      </button>
                    </td>
                    <td>
                      <button
                        onClick={() => removetocart(i)}
                        className="btn-btn-danger"
                      >
                        Remove
                      </button>
                    </td>
                    <td>
                      <button
                        onClick={() => itemOrdered(i)}
                        className="allPaidProducts__updateproducts"
                      >
                        Item Ordered
                      </button>
                    </td>
                    <td>
                      <button
                        onClick={() => itemOutOfStock(i)}
                        className="allPaidProducts__SoldOut"
                      >
                        Item Out Of Stock
                      </button>
                    </td>

                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="row">
                  <div className="col text-center">
                    <h4>Number of Items in Basket: {total1()}</h4>
                  </div>
                  <div className="row">
                <div className="col text-center">
                  <h5>Sub Total: £ {subtotal().toFixed(2)}</h5>
                </div>
                

                <div className="col text-center">
                  <h4>TOTAL: £ {(total()).toFixed(2)}</h4>
                </div>
                <div className="col text-center">
                 
                </div>
              </div>

      
     

                       <div>
                          <h4>User Number {(useridnumber() )}</h4>
                  <button 
                 
                 onClick={() => deletefartitems()}
                        className="btn-btn-danger"
                      >
                        Order Completed
                      </button>
                      </div>
                  
      
                </div>
              </div>
            </div>
  
           
          </div>
        </div>
      </div>
    );



  }

 
  
export default Finance









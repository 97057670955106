import { React, useState } from "react";
import "./Validation.css";
import { useStateValue } from "./StateProvider";
import { db } from "./firebase";
import { useHistory } from "react-router-dom";


function Validation3_Health() {
    const [info, setInfo] = useState([]);
    const [storename, Setstorename] = useState("");
    const history = useHistory();
    const [val, setVal] = useState();
    const [price, Setprice] = useState("");
  
   
   window.onload = function () {
     Fetchdata();
   };
  
    // Fetch the required data using the get() method
    const Fetchdata = () => {
      db.collection("Health & Beauty")  // .where('Id', 'in' , ["TR650"]) //X5651-113
        .get()
        .then((querySnapshot) => {
          //db.collection("All").get().then((querySnapshot) => {
          // Loop through the data and store
          // it in array to display
          querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
          querySnapshot.forEach((element) => {
            var data = element.data();
            setInfo((arr) => [...arr, data]);
          });
          unsub();
        });
    };


    const unsub = db.collection("All").onSnapshot(() => {});
  
    <div id="some-id"></div>;
  
    // Display the result on the page
    return (
      
      <div>
         <div className="mainly1">
       
       
        </div>
        {info.map((data) => (
          <Frame
            storename={data.StoreName}
            id={data.Id}
            image={data.Image}
            price={data.Price}
            title={data.Title}
            prodinfo={data.Prodinfo}
            rating={data.Rating}
            colour={data.Colour}
            bluedvd={data.Bluedvd}
            sizelarge={data.Sizelarge}
            producttype={data.ProductType}
            trouser32L={data.Trouser32L}
            trouser32R={data.Trouser32R}
            trouser32S={data.Trouser32S}
            trouser34L={data.Trouser34L}
            trouser34R={data.Trouser34R}
            trouser34S={data.Trouser34S}
            trouser36L={data.Trouser36L}
            trouser36R={data.Trouser36L}
            trouser36S={data.Trouser36S}
            trouser38L={data.Trouser38L}
            trouser38R={data.Trouser38R}
            trouser38S={data.Trouser38S}
            trouser40L={data.Trouser40L}
            trouser40R={data.Trouser40R}
            trouser40S={data.Trouser40S}
            trouser42L={data.Trouser42L}
            trouser42R={data.Trouser42R}
            trouser42S={data.Trouser42S}
            trouser44L={data.Trouser44L}
            trouser44R={data.Trouser44R}
            trouser44S={data.Trouser44S}
            sizexs={data.Sizexs}
            sizesm={data.Sizesm}
            sizemed={data.Sizemed}
            sizelarge={data.Sizelarge}
            sizexl={data.Sizexl}
            sizexxl={data.Sizexxl}
            sizexxxl={data.Sizexxxl}
            sizexxxxl={data.Sizexxxxl}
            shoe6={data.Shoe6}
            shoe7={data.Shoe7}
            shoe8={data.Shoe8}
            shoe9={data.Shoe9}
            shoe10={data.Shoe10}
            shoe11={data.Shoe11}
            shoe12={data.Shoe12}
            shoe13={data.Shoe13}
            onefitall={data.Onefitall}
            stddvd={data.Stddvd}
            bluedvd={data.Bluedvd}
            musicCd={data.MusicCd}
            electricalitem={data.Electricalitem}
            addedby={data.Addedby}
          />
        ))}
      </div>
    );
  }
  
  // Define how each display entry will be structured
        const Frame = ({
          id,
          image,
          price,
          rating,
          title,
          prodinfo,
          colour,
          size,
          storename,
          producttype,
          trouser32L,
          trouser32R,
          trouser32S,
          trouser34L,
          trouser34R,
          trouser34S,
          trouser36L,
          trouser36R,
          trouser36S,
          trouser38L,
          trouser38R,
          trouser38S,
          trouser40L,
          trouser40R,
          trouser40S,
          trouser42L,
          trouser42R,
          trouser42S,
          trouser44L,
          trouser44R,
          trouser44S,
          sizexs,
          sizesm,
          sizexl,
          sizexxl,
          sizexxxl,
          sizexxxxl,
          sizemed,
          sizelarge,
          shoe6,
          shoe7,
          shoe8,
          shoe9,
          shoe10,
          shoe11,
          shoe12,
          shoe13,
          onefitall,
          stddvd,
          bluedvd,
          musicCd,
          electricalitem,
          addedby,
          
        }) => {
          // const [{basket,user}, dispatch] = useStateValue();
          const [{ user }] = useStateValue();
         
  
  
  
    function deleteitems(val,producttype) {
      console.log(val);
      console.log(producttype);
  
      db.collection(producttype)
        .where("Id", "in", [val])
        .get()
        .then((onSnapshot) => {
          onSnapshot.forEach((doc) => {
            doc.ref
              .delete()
              .then(() => {
               
  
                console.log("Validation successfully deleted!");
  
      
              })
              .catch(function (error) {
                console.error("Error removing document: ", error);
              });
          });
        });
        deleteitems2(val) 
    }  
    
    function deleteitems2(val) {
        console.log(val);
    
        db.collection("All")
          .where("Id", "in", [val])
          .get()
          .then((onSnapshot) => {
            onSnapshot.forEach((doc) => {
              doc.ref
                .delete()
                .then(() => {
                  alert("Item Removed");
    
                  console.log("Validation successfully deleted!");
                  whathaveIdone("Deleted an item")
    
                 window.location.reload();
                })
                .catch(function (error) {
                  console.error("Error removing document: ", error);
                });
            });
          });
  
      }
      const [val, setVal] = useState();
      const [newprice,Setnewprice] = useState("");
    
      function changePrice(val,producttype){
                        
        console.log(newprice)
        var myFloat = parseFloat(newprice); //10.256
        console.log(price)
        console.log(val)
        console.log(producttype)
        
           
        db.collection(producttype)
        .where("Id", "in", [val])
        .get()
        .then((onSnapshot) => {
          onSnapshot.forEach((doc) => {
            doc.ref
              .update({
                //just update the price of item
                Price: Number(newprice),
              })
    
            .then(() => {
              console.log("Price Changed");
              changePriceAll(val)
             
            })
            .catch(function (error) {
              console.error("Error updating document: ", error);
            });
        });
      });
      }
  
      function changePriceAll(val){
                        
        console.log(newprice)
        var myFloat = parseFloat(newprice); //10.256
        console.log(price)
        console.log(val)
        
           
        db.collection("All")
        .where("Id", "in", [val])
        .get()
        .then((onSnapshot) => {
          onSnapshot.forEach((doc) => {
            doc.ref
              .update({
                //just update the price of item
                Price: Number(newprice),
              })
    
            .then(() => {
              console.log("Price Changed");
              whathaveIdone("Changed Price");
             
            })
            .catch(function (error) {
              console.error("Error updating document: ", error);
            });
        });
      });
      }
  
     
      function whathaveIdone(Val){
              console.log (Val);         
         
          db.collection("tabs") .doc(user?.uid)
          .collection(producttype)
          .add({
            NewPrice: Number(newprice),
            whatitwas: producttype,
            whathappened: Val,
            title: title,
            timevalue: Date().toLocaleString(),
          })
  
          .then((docRef) => {
              console.log (Val && "Spitnshine");     
            alert("Data Successfully Submitted");
          })
          .catch((error) => {
            console.error("Error adding document: ", error);
          });
  
  
      }
  


    return (
      <div className="home__container2000">
      <div className="mainly1">
       
        <p>{storename}</p>
  
        <p>{title}</p>
  
        <p>{id}</p>
  
        <div>
          <p>{producttype}</p>
        </div>

        <div>
          <p>{colour}</p>
        </div>
        <div>
          <p>{rating}</p>
        </div>

        <div>
          <p> {price} </p>
        </div>
        <div>
          <p> {addedby} </p>
        </div>

        <div>
        <td>{ <input
                            type="text"
                            size="17"
                            placeholder="Update Price Paid"
                            value={val}
                            onChange={(e) => {
                              Setnewprice(
                                Number(e.target.value).toFixed(2)
                              );
                            }}
                          />}</td>
        
        </div>
  
        <img className="profile-image1" src={image} alt="" />
  
        <div>

        <td>
                      <button
                        onClick={() => changePrice(id,producttype)}
                        className="allPriceProducts__updateproducts"
                      >
                        Update Price
                      </button>
                    </td>
          <button
            className="allPaidProducts__backinfo1"
            onClick={() => deleteitems(id,producttype)}
          >
            Remove Item{" "}
          </button>
        </div>
  
       
      </div>
      </div>
    );
  };
export default Validation3_Health
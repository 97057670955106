import React from "react";
import "./Order.css";
import moment from "moment";
import CheckoutProduct from "./CheckoutProduct";
import CurrencyFormat from "react-currency-format";
import { Helmet } from "helmet";

function OutOfStockOrder({ order }) {
  var formatter = new Intl.NumberFormat("gbp", {
    style: "currency",
    currency: "GBP",

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  return (
    <div className="order">
      <h2>Order you have placed but is out of stock</h2>
      <p>{order.data.dateview}</p>

      <img className="img-wrap" src={order.data.image} />
      <p className="order__id">
        <small>Item id - {order.id}</small>
      </p>
      <p>
        <h3>{order.data.storeName}</h3>
      </p>
      <p>
        <small>{order.data.prodinfo}</small>
      </p>

      <CurrencyFormat
        renderText={(value) => (
          <h3 className="order__total">Item Price: {value}</h3>
        )}
        decimalScale={2}
        value={formatter.format(order.data.price)}
        displayType={"text"}
        thousandSeparator={true}
        prefix={"£"}
      />
      <h5 className="order__total">
        Check Stock in a few days to see if product may become available again.
      </h5>
    </div>
  );
}

export default OutOfStockOrder;

import React, { useState } from "react";
import "./Login.css";
import { Link, useHistory } from "react-router-dom";
import { auth, db } from "./firebase";
import { useStateValue } from "./StateProvider";
import securelogo from "./images/Securelogo.jpg";
import { Helmet } from "react-helmet";

function LoginCreateAccount() {
  //this is where you can change email to patient number
  const history = useHistory();
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [newPassword, setnewPassword] = useState("");
  const [address, setaddress] = useState("");
  const [designation, setdesignation] = useState("");
  const [postcode, setpostcode] = useState("");
  const [userid, SET_USER] = useState("");
  const [id, Setid] = useState("");
  const [producttype, Setproducttype] = useState("");

  const [{ user }] = useStateValue();

  const register = (e) => {
    e.preventDefault();

    // firbase register stuff here
    auth
      .createUserWithEmailAndPassword(email, password)
      .then((auth) => {
        //it was successfully created
        console.log(auth);

        if (auth) {
          db.collection("users").add({
            Email: email,
            Password: password,

            Designation: designation,
            oldpassword: "",
            id: "",
            Password: password,
            timevalue: Date().toLocaleString(),
          });
          db.collection("shopUsers")
            .add({
              Email: email,
              Password: password,
              Designation: designation,
              id: "",
              MadePurchase: "No",
              Password: password,
              timevalue: Date().toLocaleString(),
            })

            .then((docRef) => {
              alert("Data Successfully Submitted");
            })
            .catch((error) => {
              console.error("Error adding document: ", error);
            });

          history.push("/Home");
        }
      })
      .catch((error) => alert(error.message));
    setemail("");
    setpassword("");
    setdesignation("");
  };

  return (
    <div className="login">
      <Helmet>
        <title>Secure Shopping| Create Account</title>
      </Helmet>

      <Link to="/Home">
        <img className="login__logo" src={securelogo} alt="securelogo" />
      </Link>
      <div className="login__container">
        <h1>Create an Account</h1>
        <h6>User Id-{user?.uid}</h6>

        <form name="MyForm">
          <h5>User Name Add @broadmoor.nhs</h5>
          <input
            type="text"
            value={email}
            onChange={(e) => setemail(e.target.value)}
          />

          <h5>Password</h5>
          <input
            type="password"
            value={password}
            onChange={(e) => setpassword(e.target.value)}
          />

          <h5>Designation Staff / Patient</h5>
          <input
            type="text"
            textTransform
            capitalize
            value={designation}
            onChange={(e) => setdesignation(e.target.value)}
          />

          <button
            disabled={!user?.uid}
            onClick={register}
            className="login__registerButton"
          >
            {" "}
            Create an account
          </button>
        </form>

        <p>
          By Creating an account you agree to our terms and conditions of Use &
          Sale. You also state that you have the authority to create this
          account.
        </p>
      </div>
    </div>
  );
}

export default LoginCreateAccount;

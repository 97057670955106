import { React, useState, useEffect } from "react";
import "./ShoppingCart.css";
import { useStateValue } from "./StateProvider";
import { db, auth } from "./firebase";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

function ShoppingCart() {
  const [{ user }] = useStateValue();
  const [cart, setCart] = useState([]);
  const [products, setProducts] = useState([]);

  const [userid, Setuserid] = "";
  const [email, Setemail] = "";
  const [authyn, Setauthyn] = "";
  const [paiditemyn, Setpaiditemyn] = "";
  const [cart1, setCart1] = useState([]);

  useEffect(() => {
    if (user) {
      db.collection("cart")
        .doc(user?.uid)
        .collection("orders")

        .onSnapshot((snapshot) =>
          setCart(
            snapshot.docs.map((doc) => ({
              data: doc.data(),
            }))
          )
        );
        getwardname()
    } else {
    }
  }, [user]);





  function getwardname(val) {
    let collectionRef = db
    .collection("users")

    .where("id", "==", user?.uid)
   

  collectionRef.onSnapshot((querySnapshot1) => {
    var p = [];
    querySnapshot1.forEach((doc) => {
      p.push(doc.data());
    });

    setCart1(p);
          });
       }
  
 

  function SendforAuth() {
    let collectionRef = db
      .collection("cart")
      .doc(user?.uid)
      .collection("orders");

    collectionRef.get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        doc.ref
          .update({
            authorised: "Sent to Manager",
            email: user.email,

            
          })
          .catch(function (error) {
            console.error("Error removing document: ", error);
          });
      });
    });
  }


  


  function removetocart(item) {
    let collectionRef = db
      .collection("cart")
      .doc(user?.uid)
      .collection("orders");

    collectionRef
      .where("idAndCode", "==", item.data.idAndCode)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          doc.ref
            .delete()
            .then(() => {
              console.log("Document successfully deleted!");
            })
            .catch(function (error) {
              console.error("Error removing document: ", error);
            });
        });
      });
  }

  function total1() {
    let x1 = 0;
    cart.map((i) => {
      x1 += i.data.quantity;
    });
    return x1;
  }

  function total() {
    let x = 0;
    cart.map((i) => {
      x += i.data.price;
    });
    return x;
  }

  function addtothecart(item) {
    let xtc1 = "";
    cart1.map((i) => {
      xtc1 = i.WardName;
    });
    console.log(xtc1)
    

  
    
    if (user) {
      const docRef = db.collection("Authorisation").doc(user.uid);
      docRef
        .set({
          userid: user.uid,
          email: user.email,
          authyn: "No",
          paiditemyn: "No",
          postalauth: "No",
          managername: "",
          postalName: "",
          authBy: user.email,
          WardName: xtc1,
          // docRef.id is the id you want.
        })
        .then((r) => {
          SendforAuth();
        });
    }
    }

  /*
                                    db.collection("Authorisation")
                             
                                   
                                   
                                    .add({
        
                                    
                                    userid: user.uid,
                                    email: user.email,
                                    authyn: "No", 
                                    paiditemyn: "No"                         
        
                                  })


                                          SendforAuth()
                                  */

  return (
    <div className="container_333">
      <Helmet>
        <title>Secure Shopping-Basket</title>
      </Helmet>

      <div className="container_3334">
        <h1>Shopping Basket </h1>

        <div className="row_mt-3">
          <table className="table  text-center">
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col"></th>
                <th scope="col">Product Name</th>
                <th scope="col"></th>
                <th scope="col"></th>
                <th scope="col">Price</th>
                <th scope="col"></th>
                <th scope="col"></th>
                <th scope="col">Size</th>
                <th scope="col"></th>
                <th scope="col"></th>
                <th scope="col">Remove</th>
                <th scope="col"></th>
                <th scope="col"></th>
                <th scope="col">Shop</th>
                <th scope="col"></th>
                <th scope="col"></th>
                <th scope="col">Sent for Authorisation</th>
                <th scope="col"></th>
                <th scope="col"></th>
                <th scope="col"></th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {cart.map((i, index) => (
                <tr key={i.data.id}>
                  <th scope="row">{index + 1}</th>
                  <th scope="row">
                    <img src={i.data.image} style={{ width: "4rem" }} />
                  </th>
                  <td>
                    <strong>{i.data.title}</strong>
                  </td>
                  <td>{}</td>
                  <td>{}</td>
                  <td>{(i.data.price, Number(i.data.price).toFixed(2))}</td>
                  <td>{}</td>

                  <td>{}</td>
                  <td>{i.data.size}</td>
                  <td>{}</td>
                  <td>{}</td>
                  <td>
                    <button
                      onClick={() => removetocart(i)}
                      className="btn-btn-danger"
                    >
                      Remove
                    </button>
                  </td>
                  <td>{}</td>
                  <td>{}</td>
                  <td>
                    <strong>{i.data.storename}</strong>
                  </td>
                  <td>{}</td>
                  <td>{}</td>

                  <td>
                    <strong className="btn-btn-danger2">
                      {" "}
                      {i.data.authorised}
                    </strong>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <h5>
          Please Note if you purchase from differnt stores you will be charged
          delivery charges for each store.{" "}
        </h5>


        <tbody>
            {cart1.map((i, index) => (
              <tr key={i.id}>
                <td>{}</td>
                <td>{}</td>
                <td>{}</td>
                <td>{}</td>
                <td>{i.WardName} Ward </td>
                
              </tr>
              
              
            ))}
          </tbody>

        <div className="row">
          <div className="col text-center">
            <h4>Basket Items Total: {total1()}</h4>
          </div>
          <div className="col text-center">
            <h4>TOTAL: £ {total().toFixed(2)}</h4>
          </div>
        </div>
      

        <div className="header__option2">
          <Link to="/Product">
            <button onClick={addtothecart} className="btn-btn-danger">
              Send for Authorisation
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default ShoppingCart;

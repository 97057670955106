import { React, useState, useEffect } from "react";
import "./AllPaidProducts.css";
import { db } from "./firebase";
import { firebase,firestore } from "./firebase";
import { useStateValue } from "./StateProvider";


function AllPaidNewProducts({
  id,
  storename,
  image,
  title,
  price,
  rating,
  colour,
  email,
  prodinfo,
  size,
  datepaid,
  grandtotal,
  idAndCode,
  process,
  paymentintentvalue,
  status,
  dateview,
}) {
  const [{ basket }, dispatch] = useStateValue();
  const [cart, setCart] = useState([]);

  const project = () => {
    switch (process) {
      case "Paid - Awaiting Order Confirmation":
        return <button onClick={itemsAccepted}> Goods Accepted</button>;
      case "Item Ordered Awaiting Delivery":
        return <button onClick={itemsSentBack}> to supplier</button>;
      case "Item Arrived":
        return <button onClick={itemsSentBack}> Goodsto supplier</button>;
      case "Returned To Supplier Waiting Refund":
        return <button onClick={itemsSentBack}> Back to supplier</button>;
      case "Goods Refunded":
        return <button onClick={itemsSentBack}> Back to supplier</button>;

      case "Goods Accepted":
        return <button onClick={itemsSentBack}> Back to supplier</button>;

      default:
        return <h1></h1>;
    }
  };

  const project2 = () => {
    switch (process) {
      case "Paid - Awaiting Order Confirmation":
        return (
          <button onClick={itemsSentBack}> Goods Sent Back to supplier</button>
        );
      case "Paid":
        return (
          <button color={"blue"} onClick={itemsSentBack}>
            {" "}
            to supplier
          </button>
        );
      case "three":
        return <button onClick={itemsSentBack}> Goodsto supplier</button>;
      case "four":
        return <button onClick={itemsSentBack}> Back to supplier</button>;

      default:
        return <h1></h1>;
    }
  };

  var num = prodinfo;
  var idandcode = idAndCode;
  var paymentcode = paymentintentvalue;
    var nowpaidid = {process}


  function itemsupdateBack (val) {
  
   
    console.log(val)
    
    const reference = db.collection("AllpaidNewItems")
      .doc(prodinfo)
      .collection("orders").doc(paymentcode)
     // reference.where(val, "==", idAndCode)
     .update({

      process: process + " - " + title + " Sent to goods to be sent back" + '\n' + " , ",
      
     
     })

     itemsSentBack(val)
      console.log("nothing happened did it?")
   
    };


    function itemsupdateKept (val) {
     
     
      console.log(val)
      
      const reference = db.collection("AllpaidNewItems")
        .doc(prodinfo)
        .collection("orders").doc(paymentcode)
       // reference.where(val, "==", idAndCode)
       .update({
  
        process: process + " - " + title + " Sent to goods accepted" + '\n' + " , ",
        
       
       })
       itemsAccepted(val)
        console.log("nothing happened did it?")
     
      };
   
   


  const itemsSentBack = (e) => {
    alert("Saved as an Item Sent Back.");
    console.log(id, title, price, colour);


    e.preventDefault();
    db.collection("OrdersReturned")
      .doc(prodinfo)
      .collection("OrdersSentBack")
      .add({
        id: id,
        storename: storename,
        title: title,
        image: image,
        price: price,
        rating: rating,
        prodinfo: prodinfo,
        colour: colour,
        size: size,
        datepaid: datepaid,
        grandtotal: grandtotal,
        idAndCode: idAndCode,
        email: email,
        status:  "Item Returned - Awaiting Refund",
        process: "Item Returned - Awaiting Refund",
        paymentintentvalue: paymentintentvalue,

      }) 
      ;
  };

  const itemsAccepted = (e) => {
    console.log(id, title, price, colour);

    alert("Saved as Goods Accepted.");

    e.preventDefault();
    db.collection("OrdersSettled")
      .doc(prodinfo)
      .collection("OrdersRecieved")
      .add({
        id: id,
        storename: storename,
        title: title,
        image: image,
        price: price,
        rating: rating,
        prodinfo: prodinfo,
        colour: colour,
        size: size,
        datepaid: datepaid,
        grandtotal: grandtotal,
        idAndCode: idAndCode,
        process: process,
        email: email,
        status: status,
        paymentintentvalue: paymentintentvalue,
        dateview: dateview,
      });
  };

  return (
    <div className="allPaidProducts">
      <div class="boxedNew">
        <p>
          <strong>Status - {status}</strong>
        </p>
        <img className="allPaidProducts__image" src={image} />
        <div className="allPaidProducts__info">
          <p className="allPaidProducts__title">{title} </p>
          <p className="allPaidProducts__colour">Item Colour - {colour} </p>
          <p className="allPaidProducts__prodinfo">Users Id - {prodinfo} </p>
          

          <p className="allwPaidProducts2__prodinfo2">
            Item Sort Id - {idAndCode}{" "}
          </p>
         
          
        
          <p>
            <strong>Store Name - {storename}</strong>
          </p>

          <p>
            <strong>Size/Number - {size}</strong>
          </p>
         
          
          <p className="allPaidProducts__price">
            <strong>£{(price, Number(price).toFixed(2))}</strong>
          </p>

          <div className="allPaidProducts__rating">
            {Array(rating)
              .fill()
              //map or render through
              .map((i) => (
                <p>🌟</p>
              ))}
          </div>

          <div className="allPaidProducts__info">
            {" "}
            <button className="allPaidProducts__info" onClick={itemsupdateKept}>
              {" "}
              Goods Accepted
            </button>
          </div>

         

          <div className="allPaidProducts__backinfo">
            {" "}
            <button
              className="allPaidProducts__backinfo"
              onClick={itemsupdateBack}
            >
              {" "}
              Goods Sent Back to supplier
            </button>
          </div>
        </div>
      </div>
      
    </div>
  );
}

export default AllPaidNewProducts;

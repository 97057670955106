import {React,useState} from 'react';
import "./StockControl.css";
import { useStateValue } from "./StateProvider";
import {  db } from './firebase'
import {auth} from "./firebase";
import {Helmet} from 'helmet'


function StockControl() {
    const [info , setInfo] = useState([]);
    const [storename , Setstorename] = useState("");          
                    
                    
    window.onload = function() {

        Fetchdata();
    
    
};


    // Fetch the required data using the get() method
    const Fetchdata = ()=>{
        db.collection("All").get().then((querySnapshot) => {
        //db.collection("All").get().then((querySnapshot) => {
            // Loop through the data and store
            // it in array to display
            querySnapshot.docs.map((doc) => ({id: doc.id, ...doc.data()}))
            querySnapshot.forEach(element => {
                var data = element.data();
                setInfo(arr => [...arr , data]);
                
            });
            unsub();
        })
    }
    const unsub = db.collection('All').onSnapshot(() => {
    });

    
    <div id='some-id'>
    </div>

   


    
// Display the result on the page
return (
  
   
    <div> 

    
    
    
    {
        info.map((data) => (
        <Frame storename={data.StoreName}
                id={data.Id}
            image={data.Image}
            price={data.Price}
            title={data.Title}
            prodinfo={data.Prodinfo}
            rating={data.Rating}
            colour={data.Colour}
           
            ProductType={data.ProductType}

            


            
            />
        ))
    }




    </div>

    );

    }



    // Define how each display entry will be structured
    const Frame = ({id, idAndCode,image , price, rating , title 
        ,prodinfo,colour,size,storename,ProductType,
    
    
    }) => {

    // const [{basket,user}, dispatch] = useStateValue();
    const [{ user} ] = useStateValue();
    const selectShortlistedApplicant = (e) => {
        const checked = e.target.checked;
      

        if (checked) {
        //checked

       
     
        addtocart();

        //alert("Selected");

     
        } else {
        //unchecked  setDisable(e.target.value);
        alert("Select a size first!");
        // alert(" Successfully unticked");
        }



    };

    
    function deleteitems(val) {

        console.log(val);

        db.collection("All").where("Id", "==", val).get()
        .then(querySnapshot => {
            querySnapshot.docs[0].ref.delete(); 

        });

        console.log(ProductType);
        db.collection(ProductType).where("Id", "==", val).get()
        .then(querySnapshot => {
            querySnapshot.docs[0].ref.delete(); 

        });





    //  let collectionRef =   db.collection("All").doc(val).delete()
  
       }
  
  

    function addtocart(item) {
        if (user) {

        
          //  this creates a random id for the purposes of cart deletion items 
            let mynumber = Math.floor(100000 + Math.random() * 900000);

        db.collection('cart')
        .doc(user?.uid)
        .collection('orders').add({

                            idAndCode:id && mynumber,
                            id: id ,
                            ProductType:ProductType,
                            storename:storename,
                            title: title,
                            image: image,
                            price: price,
                            rating: rating,
                            prodinfo: prodinfo,
                            colour: colour,
                            size: size,  
                            quantity: Number(1), 
                            user: user.uid,  

    
    
            })

            .then((docRef) => {
                alert("Item Added to Basket");
    
            
            
    
            })
            .catch((error) => {
                console.error("Error adding document: ", error);
            });
    
            
    }else {
    
        <script>
        function myFunction() {
            alert("Please Sign in before trying to add items to the Basket! Thank you")
        }
        </script>
    }

    }



    
 
   
  
    return (
    
   
    
        <div className="mainly">

           
           
        
            <p>{storename}</p>
         
            
            <p>{title}</p>
         
            <p>{id}</p>
          

            <div >
            <p>{ProductType}</p>
            </div>

            
            
            <div className= "item_prices"> 
            <small>£</small>
            <strong>{price ,  Number(price).toFixed(2)  }</strong>
            </div>
            
            <div >
            <p>Colour : {colour} </p>
            </div>
           
          
                    <img className= "profile-image1 "
                    src= {image}
                    alt=""
                    />


       
         <div >
               <button className = "addtobasketbuttonShow"  onClick={() => deleteitems(id)}>Remove Item </button>
          </div>
                
         

   </div>

  


)


    
}

export default StockControl


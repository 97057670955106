import React ,{useRef}from "react";
import "./AllPaidOrders.css";
import moment from "moment";
import AllPaidNewProducts from "./AllPaidNewProducts";
import CurrencyFormat from "react-currency-format";
import { Helmet } from "helmet";
import { db } from "./firebase";
import{ useReactToPrint } from "react-to-print";


function AllPaidNewOrders({ order})

 {
  const componentRef = useRef();
  const handlePrint = useReactToPrint ({
                    
      content: ()=> componentRef.current,
      documentTitle: 'emp-data',
      onafterprint: ()=> alert('Print Success')
    });
    

    var formatter = new Intl.NumberFormat('gbp', {
        style: 'currency',
        currency: 'GBP',
      
        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });



//<p>{(order.data.colour)}</p>
//<p>{(order.data.storename)}</p>
      



  return (
    <div className="order">



      
      <h1>Order Details</h1>

    

      <p className="order__id2">

      
        <strong>Date Item Ordered - {order.data.dateview}</strong>
      </p>
     
      <p className="order__id">
        <small>Order id - {order.id}</small>
      </p>
    
      <p className="order__id2">
        <strong>Order Status - {order.data.process}</strong>
      </p>
       
    
      <>
      
    <div ref={componentRef}>

    <h2   className="col text-center"  >Order</h2> 
    <p className="order__id2">
        <strong>User Name - {order.data.email}</strong>
      </p>

    {order.data.basket?.map((item) => (

<AllPaidNewProducts
  id={item.id}
  storename={item.storename}
  title={item.title}
  size={item.size}
  image={item.image}
  price={item.price}
  rating={item.rating}
  colour={item.colour}
  prodinfo={item.user}
  dateview = {item.dateview}
  grandtotal = {order.data.amount}
  idAndCode = {item.idAndCode}
  process = {order.data.process}
  email = {order.data.email}
  status = {item.status}
  paymentintentvalue = {order.data.paymentintentvalue}
  hideButtons
 


/>

))  }

    </div>

    <button onClick={handlePrint}>Print</button>
    </> 
       
      <CurrencyFormat
        renderText=  {(value) => (
          <h3 className="order__total">Total Basket Price: { value}</h3>
          
          
        )}
        decimalScale={2}
        value={ formatter.format (order.data.amount )}
        displayType={"text"}
        thousandSeparator={true}
        prefix={"£"}
      />


     
      


    </div>
  );
}

export default AllPaidNewOrders
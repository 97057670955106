import axios from "axios"

const instance = axios.create({
baseURL: 
    'https://us-central1-shopping-bd03e.cloudfunctions.net/api'
});



//'http://localhost:5001/shopping-bd03e/us-central1/api'

//'https://us-central1-shopping-bd03e.cloudfunctions.net/api

export default instance;
import { db } from "./firebase";
import { useState } from "react";
import { Link } from "react-router-dom";
import "./Prods.css";

const Prods = () => {
  const [storename, Setstorename] = useState("");
  const [id, Setid] = useState("");
  const [producttype, Setproducttype] = useState("");
  const [title, Settitle] = useState("");
  const [prodinfo, Setprodinfo] = useState(""); //(1)
  const [price, Setprice] = useState("");
  const [image, Setimage] = useState("");
  const [colour, Setcolour] = useState(""); //(2)
  const [delcharge, Setdelcharge] = useState(""); //(3)

  const [trouser32L, Settrouser32L] = useState("");
  const [trouser32R, Settrouser32R] = useState("");
  const [trouser32S, Settrouser32S] = useState("");
  const [trouser34L, Settrouser34L] = useState("");
  const [trouser34R, Settrouser34R] = useState("");
  const [trouser34S, Settrouser34S] = useState("");
  const [trouser36L, Settrouser36L] = useState("");
  const [trouser36R, Settrouser36R] = useState("");
  const [trouser36S, Settrouser36S] = useState("");
  const [trouser38L, Settrouser38L] = useState("");
  const [trouser38R, Settrouser38R] = useState("");
  const [trouser38S, Settrouser38S] = useState("");
  const [trouser40L, Settrouser40L] = useState("");
  const [trouser40R, Settrouser40R] = useState("");
  const [trouser40S, Settrouser40S] = useState("");
  const [trouser42L, Settrouser42L] = useState("");
  const [trouser42R, Settrouser42R] = useState("");
  const [trouser42S, Settrouser42S] = useState("");
  const [trouser44L, Settrouser44L] = useState("");
  const [trouser44R, Settrouser44R] = useState("");
  const [trouser44S, Settrouser44S] = useState("");
  const [addedby, Setaddedby] = useState("");

  const [sizexs, Setsizexs] = useState("");
  const [sizesm, Setsizesm] = useState("");
  const [sizemed, Setsizemed] = useState("");
  const [sizelarge, Setsizelarge] = useState("");
  const [sizexl, Setsizexl] = useState("");
  const [sizexxl, Setsizexxl] = useState("");
  const [sizexxxl, Setsizexxxl] = useState("");
  const [sizexxxxl, Setsizexxxxl] = useState("");

  const [shoe6, Setshoe6] = useState("");
  const [shoe7, Setshoe7] = useState("");
  const [shoe8, Setshoe8] = useState("");
  const [shoe9, Setshoe9] = useState("");
  const [shoe10, Setshoe10] = useState("");
  const [shoe11, Setshoe11] = useState("");
  const [shoe12, Setshoe12] = useState("");
  const [shoe13, Setshoe13] = useState("");

  const [shoe6half, Setshoe6half] = useState("");
  const [shoe7half, Setshoe7half] = useState("");
  const [shoe8half, Setshoe8half] = useState("");
  const [shoe9half, Setshoe9half] = useState("");
  const [shoe10half, Setshoe10half] = useState("");
  const [shoe11half, Setshoe11half] = useState("");
  const [shoe12half, Setshoe12half] = useState("");
  const [shoe13half, Setshoe13half] = useState("");
  const [stddvd, Setstddvd] = useState("");
  const [bluedvd, Setbluedvd] = useState("");
  const [musicCd, setMusicCd] = useState("");
  const [electricalitem, setelectricalitem] = useState("");

  const [onefitall, Setonefitall] = useState("");

  const [rating, Setrating] = useState("");

  const [val, setVal] = useState();

  const sub = (e) => {
    e.preventDefault();

    var myFloat = parseFloat(price); //10.256
    var myFloat2 = parseFloat(rating);

    // Add data to the store

    //	db.collection("products").add({

    db.collection("Validation")
      .add({
        StoreName: storename,
        Image: image,
        Price: myFloat,
        Rating: myFloat2,
        Title: title,
        ProductType: producttype,
        Id: id,
        Prodinfo: prodinfo,
        Colour: colour,
        Delcharge: delcharge,
        Trouser32L: trouser32L,
        Trouser32R: trouser32R,
        Trouser32S: trouser32S,
        Trouser34L: trouser34L,
        Trouser34R: trouser34R,
        Trouser34S: trouser34S,
        Trouser36L: trouser36L,
        Trouser36R: trouser36R,
        Trouser36S: trouser36S,
        Trouser38L: trouser38L,
        Trouser38R: trouser38R,
        Trouser38S: trouser38S,
        Trouser40L: trouser40L,
        Trouser40R: trouser40R,
        Trouser40S: trouser40S,
        Trouser42L: trouser42L,
        Trouser42R: trouser42R,
        Trouser42S: trouser42S,
        Trouser44L: trouser44L,
        Trouser44R: trouser44R,
        Trouser44S: trouser44S,
        Sizexs: sizexs,
        Sizesm: sizesm,
        Sizemed: sizemed,
        Sizelarge: sizelarge,
        Sizexl: sizexl,
        Sizexxl: sizexxl,
        Sizexxxl: sizexxxl,
        Sizexxxxl: sizexxxxl,
        Shoe6: shoe6,
        Shoe7: shoe7,
        Shoe8: shoe8,
        Shoe9: shoe9,
        Shoe10: shoe10,
        Shoe11: shoe11,
        Shoe12: shoe12,
        Shoe13: shoe13,
        Shoe6half: shoe6half,
        Shoe7half: shoe7half,
        Shoe8half: shoe8half,
        Shoe9half: shoe9half,
        Shoe10half: shoe10half,
        Shoe11half: shoe11half,
        Shoe12half: shoe12half,
        Shoe13half: shoe13half,
        Onefitall: onefitall,
        Stddvd: stddvd,
        Bluedvd: bluedvd,
        MusicCd: musicCd,
        Electricalitem: electricalitem,
        Addedby: addedby,
      })
      .then((docRef) => {
        alert("Data Successfully Submitted");
        sendtovalidation2(e);
        window.location.reload();
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });
    
  };


  function sendtovalidation2(e) {
    e.preventDefault();
    var myFloat = parseFloat(price); //10.256
    var myFloat2 = parseFloat(rating);

    // Add data to the store

    //	db.collection("products").add({

    db.collection("Validation2")
      .add({
        StoreName: storename,
        Image: image,
        Price: myFloat,
        Rating: myFloat2,
        Title: title,
        ProductType: producttype,
        Id: id,
        Prodinfo: prodinfo,
        Colour: colour,
        Delcharge: delcharge,
        Trouser32L: trouser32L,
        Trouser32R: trouser32R,
        Trouser32S: trouser32S,
        Trouser34L: trouser34L,
        Trouser34R: trouser34R,
        Trouser34S: trouser34S,
        Trouser36L: trouser36L,
        Trouser36R: trouser36R,
        Trouser36S: trouser36S,
        Trouser38L: trouser38L,
        Trouser38R: trouser38R,
        Trouser38S: trouser38S,
        Trouser40L: trouser40L,
        Trouser40R: trouser40R,
        Trouser40S: trouser40S,
        Trouser42L: trouser42L,
        Trouser42R: trouser42R,
        Trouser42S: trouser42S,
        Trouser44L: trouser44L,
        Trouser44R: trouser44R,
        Trouser44S: trouser44S,
        Sizexs: sizexs,
        Sizesm: sizesm,
        Sizemed: sizemed,
        Sizelarge: sizelarge,
        Sizexl: sizexl,
        Sizexxl: sizexxl,
        Sizexxxl: sizexxxl,
        Sizexxxxl: sizexxxxl,
        Shoe6: shoe6,
        Shoe7: shoe7,
        Shoe8: shoe8,
        Shoe9: shoe9,
        Shoe10: shoe10,
        Shoe11: shoe11,
        Shoe12: shoe12,
        Shoe13: shoe13,
        Shoe6half: shoe6half,
        Shoe7half: shoe7half,
        Shoe8half: shoe8half,
        Shoe9half: shoe9half,
        Shoe10half: shoe10half,
        Shoe11half: shoe11half,
        Shoe12half: shoe12half,
        Shoe13half: shoe13half,
        Onefitall: onefitall,
        Stddvd: stddvd,
        Bluedvd: bluedvd,
        MusicCd: musicCd,
        Electricalitem: electricalitem,
        Addedby: addedby,
      })
      
      .catch((error) => {
        console.error("Error adding document: ", error);
      });
    
  };

  

  return (
    <div>
      <center>
        <form
          style={{ marginTop: "200px" }}
          onSubmit={(event) => {
            sub(event);
          }}
          id="addItems"
        >
          <div class="boxed111">
            <h3>Sale Items  </h3>
            <p>For Dvd's tick - On Sale , Then Dvd's and then what type of DVD it is and then product Id and the product details</p>
            <input
              type="checkbox"
              useRef="minus"
              id="Setstorename"
              name="Store Name"
              value={"Saleitems"}
              onChange={(e) => {
                Setstorename(e.target.value);
              }}
            />{" "}
            On Sale
            <p>Sale Product Type Dont get confused with stuff below </p>
            <input
              type="checkbox"
              id="Setstorename"
              name="product type"
              value={"DVDs"}
              onChange={(e) => {
                Setproducttype(e.target.value);
              }}
            />{" "}
            Dvd's
            <input
              type="checkbox"
              id="Setstorename"
              name="product type"
              value={"Music"}
              onChange={(e) => {
                Setproducttype(e.target.value);
              }}
            />{" "}
            Music
            <input
              type="checkbox"
              id="Setstorename"
              name="product type"
              value={"Electrical"}
              onChange={(e) => {
                Setproducttype(e.target.value);
              }}
            />{" "}
            Electrical
            <input
              type="checkbox"
              id="Setstorename"
              name="product type"
              value={"Clothing"}
              onChange={(e) => {
                Setproducttype(e.target.value);
              }}
            />{" "}
            Big Sale Clothing Clothing (Dont forget sizes below)


            <br />
            <br />
            <br />
            <br />




           
            <h3>DVD's / BlueRay</h3>
            <input
              type="checkbox"
              id="Setstddvd"
              name="stddvd"
              value={"yes"}
              onChange={(e) => {
                Setstddvd(e.target.value);
              }}
            />{" "}
            Standard DVD
            <input
              type="checkbox"
              id="Setbluedvd"
              name="bluedvd"
              value={"yes"}
              onChange={(e) => {
                Setbluedvd(e.target.value);
              }}
            />{" "}
            Blu Ray DVD
            <br />
            <br />
            <input
              type="checkbox"
              id="SetmusicCd"
              name="musicCd"
              value={"yes"}
              onChange={(e) => {
                setMusicCd(e.target.value);
              }}
            />{" "}
            Music Cd's
        
            <br />
            <br />
          </div>
          <br />
          <br />
          <br />
          <br />
          <h3>All Items need an Id. </h3>
          <input
            type="text"
            placeholder="id"
            value={val}
            onChange={(e) => {
              Setid(e.target.value);
            }}
          />
          <br />
          <br />
          <h3>Store Name </h3>
          <input
            type="checkbox"
            useRef="minus"
            id="Setstorename"
            name="Store Name"
            value={"Curries Pc World"}
            onChange={(e) => {
              Setstorename(e.target.value);
            }}
          />{" "}
          Currys PC World
          <input
            type="checkbox"
            useRef="minus"
            id="Setstorename"
            name="Store Name"
            value={"JD Sports"}
            onChange={(e) => {
              Setstorename(e.target.value);
            }}
          />{" "}
          JD Sports
          <input
            type="checkbox"
            useRef="minus"
            id="Setstorename"
            name="Store Name"
            value={"Nike Store"}
            onChange={(e) => {
              Setstorename(e.target.value);
            }}
          />{" "}
          Nike Store
          <input
            type="checkbox"
            useRef="minus"
            id="Setstorename"
            name="Store Name"
            value={"M&MDirect"}
            onChange={(e) => {
              Setstorename(e.target.value);
            }}
          />{" "}
          M&M Direct
          <input
            type="checkbox"
            useRef="minus"
            id="Setstorename"
            name="Store Name"
            value={"Joe Browns"}
            onChange={(e) => {
              Setstorename(e.target.value);
            }}
          />{" "}
          Joe Browns
          <input
            type="checkbox"
            useRef="minus"
            id="Setstorename"
            name="Store Name"
            value={"Cotton Traders"}
            onChange={(e) => {
              Setstorename(e.target.value);
            }}
          />{" "}
          Cotton Traders
          <input
            type="checkbox"
            useRef="minus"
            id="Setstorename"
            name="Store Name"
            value={"H Samuel"}
            onChange={(e) => {
              Setstorename(e.target.value);
            }}
          />{" "}
          H Samuel
          <br />
          <br />
         
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <div class="boxed">
            <h3>Product Type</h3>
            <input
              type="checkbox"
              id="Setstorename"
              name="product type"
              value={"Shirts"}
              onChange={(e) => {
                Setproducttype(e.target.value);
              }}
            />{" "}
            Shirts/T-Shirts
            <input
              type="checkbox"
              id="Setstorename"
              name="product type"
              value={"Trousers"}
              onChange={(e) => {
                Setproducttype(e.target.value);
              }}
            />{" "}
            Trousers/Shorts
            <input
              type="checkbox"
              id="Setstorename"
              name="product type"
              value={"Knitwear"}
              onChange={(e) => {
                Setproducttype(e.target.value);
              }}
            />{" "}
            Knitwear/Jackets
            <input
              type="checkbox"
              id="Setstorename"
              name="product type"
              value={"Sweatshirts"}
              onChange={(e) => {
                Setproducttype(e.target.value);
              }}
            />{" "}
            Sweatshirts/Tops
            <input
              type="checkbox"
              id="Setstorename"
              name="product type"
              value={"Shoes"}
              onChange={(e) => {
                Setproducttype(e.target.value);
              }}
            />{" "}
            Shoes & Boots
            <input
              type="checkbox"
              id="Setstorename"
              name="product type"
              value={"Accessories"}
              onChange={(e) => {
                Setproducttype(e.target.value);
              }}
            />{" "}
            Accessories
            <input
              type="checkbox"
              id="Setstorename"
              name="product type"
              value={"Electrical"}
              onChange={(e) => {
                Setproducttype(e.target.value);
              }}
            />{" "}
            Electrical
            <br />
            <br />
            <input
              type="checkbox"
              id="Setstorename"
              name="product type"
              value={"Tv & Audio"}
              onChange={(e) => {
                Setproducttype(e.target.value);
              }}
            />{" "}
            TV & Audio
            <input
              type="checkbox"
              id="Setstorename"
              name="product type"
              value={"Gaming"}
              onChange={(e) => {
                Setproducttype(e.target.value);
              }}
            />{" "}
            Gaming & Games
            <input
              type="checkbox"
              id="Setstorename"
              name="product type"
              value={"Health & Beauty"}
              onChange={(e) => {
                Setproducttype(e.target.value);
              }}
            />{" "}
            Health & Beauty
            <input
              type="checkbox"
              id="Setstorename"
              name="product type"
              value={"Jewellery"}
              onChange={(e) => {
                Setproducttype(e.target.value);
              }}
            />{" "}
            Jewellery
            <input
              type="checkbox"
              id="Setstorename"
              name="product type"
              value={"Watches"}
              onChange={(e) => {
                Setproducttype(e.target.value);
              }}
            />{" "}
            Watches
            <br />
            <br />
            <input
              type="text"
              placeholder="product heading"
              value={val}
              onChange={(e) => {
                Settitle(e.target.value);
              }}
            />
            <br />
            <br />
            <input
              type="text"
              placeholder="product info"
              value={val}
              onChange={(e) => {
                Setprodinfo(e.target.value);
              }}
            />
            <br />
            <br />
            <input
              type="text"
              placeholder="price"
              value={val}
              onChange={(e) => {
                Setprice(Number(e.target.value).toFixed(2));
              }}
            />
            <br />
            <br />
            <input
              type="text"
              placeholder="image link"
              value={val}
              onChange={(e) => {
                Setimage(e.target.value);
              }}
            />
            <br />
            <br />
            <input
              type="text"
              placeholder="colour value"
              value={val}
              onChange={(e) => {
                Setcolour(e.target.value);
              }}
            />
            <br />
            <br />
          </div>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <div class="boxed">
            <h3>Sizes</h3>
            <input
              type="checkbox"
              id="Setstorename"
              name="One Size Fits All"
              value={"Yes"}
              onChange={(e) => {
                Setonefitall(e.target.value);
              }}
            />{" "}
            One size fits all
            <h3>Trousers/Jeans</h3>
            <input
              type="checkbox"
              id="Settrouser32L"
              name="32L"
              value={"yes"}
              onChange={(e) => {
                Settrouser32L(e.target.value);
              }}
            />{" "}
            32 L
            <input
              type="checkbox"
              id="Settrouser32R"
              name="32R"
              value={"yes"}
              onChange={(e) => {
                Settrouser32R(e.target.value);
              }}
            />{" "}
            32 R
            <input
              type="checkbox"
              id="Settrouser32S"
              name="32S"
              value={"yes"}
              onChange={(e) => {
                Settrouser32S(e.target.value);
              }}
            />{" "}
            32 S
            <input
              type="checkbox"
              id="Settrouser34L"
              name="34L"
              value={"yes"}
              onChange={(e) => {
                Settrouser34L(e.target.value);
              }}
            />{" "}
            34 L
            <input
              type="checkbox"
              id="Settrouser34R"
              name="34R"
              value={"yes"}
              onChange={(e) => {
                Settrouser34R(e.target.value);
              }}
            />{" "}
            34 R
            <input
              type="checkbox"
              id="Settrouser34S"
              name="34S"
              value={"yes"}
              onChange={(e) => {
                Settrouser34S(e.target.value);
              }}
            />{" "}
            34 S
            <input
              type="checkbox"
              id="Settrouser36L"
              name="36L"
              value={"yes"}
              onChange={(e) => {
                Settrouser36L(e.target.value);
              }}
            />{" "}
            36 L
            <input
              type="checkbox"
              id="Settrouser36R"
              name="36R"
              value={"yes"}
              onChange={(e) => {
                Settrouser36R(e.target.value);
              }}
            />{" "}
            36 R
            <input
              type="checkbox"
              id="Settrouser36S"
              name="36S"
              value={"yes"}
              onChange={(e) => {
                Settrouser36S(e.target.value);
              }}
            />{" "}
            36 S
            <br />
            <br />
            <input
              type="checkbox"
              id="Settrouser38L"
              name="38L"
              value={"yes"}
              onChange={(e) => {
                Settrouser38L(e.target.value);
              }}
            />{" "}
            38 L
            <input
              type="checkbox"
              id="Settrouser38R"
              name="38R"
              value={"yes"}
              onChange={(e) => {
                Settrouser38R(e.target.value);
              }}
            />{" "}
            38 R
            <input
              type="checkbox"
              id="Settrouser38S"
              name="38S"
              value={"yes"}
              onChange={(e) => {
                Settrouser38S(e.target.value);
              }}
            />{" "}
            38 S
            <input
              type="checkbox"
              id="Settrouser40L"
              name="40L"
              value={"yes"}
              onChange={(e) => {
                Settrouser40L(e.target.value);
              }}
            />{" "}
            40 L
            <input
              type="checkbox"
              id="Settrouser40R"
              name="40R"
              value={"yes"}
              onChange={(e) => {
                Settrouser40R(e.target.value);
              }}
            />{" "}
            40 R
            <input
              type="checkbox"
              id="Settrouser40S"
              name="40S"
              value={"yes"}
              onChange={(e) => {
                Settrouser40S(e.target.value);
              }}
            />{" "}
            40 S
            <input
              type="checkbox"
              id="Settrouser42L"
              name="42L"
              value={"yes"}
              onChange={(e) => {
                Settrouser42L(e.target.value);
              }}
            />{" "}
            42L
            <input
              type="checkbox"
              id="Settrouser42R"
              name="42R"
              value={"yes"}
              onChange={(e) => {
                Settrouser42R(e.target.value);
              }}
            />{" "}
            42 R
            <input
              type="checkbox"
              id="Settrouser42S"
              name="42S"
              value={"yes"}
              onChange={(e) => {
                Settrouser42S(e.target.value);
              }}
            />{" "}
            42 S
            <input
              type="checkbox"
              id="Settrouser44L"
              name="44L"
              value={"yes"}
              onChange={(e) => {
                Settrouser44L(e.target.value);
              }}
            />{" "}
            44 L
            <input
              type="checkbox"
              id="Settrouser44R"
              name="44R"
              value={"yes"}
              onChange={(e) => {
                Settrouser44R(e.target.value);
              }}
            />{" "}
            44 R
            <input
              type="checkbox"
              id="Settrouser44S"
              name="44S"
              value={"yes"}
              onChange={(e) => {
                Settrouser44S(e.target.value);
              }}
            />{" "}
            44 S
            <br />
            <br />
            <h3>TShirts/T-Shirts/Sweatshirts/Tops/Knitwear/Jackets</h3>
            <input
              type="checkbox"
              id="Setsizexs"
              name="X Small"
              value={"yes"}
              onChange={(e) => {
                Setsizexs(e.target.value);
              }}
            />{" "}
            X Small
            <input
              type="checkbox"
              id="Setsizesm"
              name="Small"
              value={"yes"}
              onChange={(e) => {
                Setsizesm(e.target.value);
              }}
            />{" "}
            Small
            <input
              type="checkbox"
              id="Setsizemed"
              name="Medium"
              value={"yes"}
              onChange={(e) => {
                Setsizemed(e.target.value);
              }}
            />{" "}
            Medium
            <input
              type="checkbox"
              id="Setsizelarge"
              name="Large"
              value={"yes"}
              onChange={(e) => {
                Setsizelarge(e.target.value);
              }}
            />{" "}
            Large
            <input
              type="checkbox"
              id="Setsizexl"
              name="XL"
              value={"yes"}
              onChange={(e) => {
                Setsizexl(e.target.value);
              }}
            />{" "}
            XL
            <input
              type="checkbox"
              id="Setsizexxl"
              name="XXL"
              value={"yes"}
              onChange={(e) => {
                Setsizexxl(e.target.value);
              }}
            />{" "}
            XXL
            <input
              type="checkbox"
              id="Setsizexxxl"
              name="XXXL"
              value={"yes"}
              onChange={(e) => {
                Setsizexxxl(e.target.value);
              }}
            />{" "}
            XXXL
            <input
              type="checkbox"
              id="Setsizexxxxl"
              name="XXXXL"
              value={"yes"}
              onChange={(e) => {
                Setsizexxxxl(e.target.value);
              }}
            />{" "}
            XXXXL
            <br />
            <br />
            <h3>Shoes/Boots</h3>
            <input
              type="checkbox"
              id="Setshoe6"
              name="Size 6"
              value={"yes"}
              onChange={(e) => {
                Setshoe6(e.target.value);
              }}
            />{" "}
            6
            <input
              type="checkbox"
              id="Setshoe7"
              name="Size 7"
              value={"yes"}
              onChange={(e) => {
                Setshoe7(e.target.value);
              }}
            />{" "}
            7
            <input
              type="checkbox"
              id="Setshoe8"
              name="Size 8"
              value={"yes"}
              onChange={(e) => {
                Setshoe8(e.target.value);
              }}
            />{" "}
            8
            <input
              type="checkbox"
              id="Setshoe9"
              name="Size 9"
              value={"yes"}
              onChange={(e) => {
                Setshoe9(e.target.value);
              }}
            />{" "}
            9
            <input
              type="checkbox"
              id="Setshoe10"
              name="Size 10"
              value={"yes"}
              onChange={(e) => {
                Setshoe10(e.target.value);
              }}
            />{" "}
            10
            <input
              type="checkbox"
              id="Setshoe11"
              name="Size 11"
              value={"yes"}
              onChange={(e) => {
                Setshoe11(e.target.value);
              }}
            />{" "}
            11
            <input
              type="checkbox"
              id="Setshoe12"
              name="Size 12"
              value={"yes"}
              onChange={(e) => {
                Setshoe12(e.target.value);
              }}
            />{" "}
            12
            <input
              type="checkbox"
              id="Setshoe13"
              name="Size 13"
              value={"yes"}
              onChange={(e) => {
                Setshoe13(e.target.value);
              }}
            />{" "}
            13

            <br />
            <br />
            <input
              type="checkbox"
              id="Setshoe6half"
              name="Size 6.5"
              value={"yes"}
              onChange={(e) => {
                Setshoe6half(e.target.value);
              }}
            />{" "}
            6.5
            <input
              type="checkbox"
              id="Setshoe7half"
              name="Size 7.5"
              value={"yes"}
              onChange={(e) => {
                Setshoe7half(e.target.value);
              }}
            />{" "}
            7.5
            <input
              type="checkbox"
              id="Setshoe8half"
              name="Size 8.5"
              value={"yes"}
              onChange={(e) => {
                Setshoe8half(e.target.value);
              }}
            />{" "}
            8.5
            <input
              type="checkbox"
              id="Setshoe9half"
              name="Size 9.5"
              value={"yes"}
              onChange={(e) => {
                Setshoe9half(e.target.value);
              }}
            />{" "}
            9.5
            <input
              type="checkbox"
              id="Setshoe10half"
              name="Size 10.5"
              value={"yes"}
              onChange={(e) => {
                Setshoe10half(e.target.value);
              }}
            />{" "}
            10.5
            <input
              type="checkbox"
              id="Setshoe11half"
              name="Size 11.5"
              value={"yes"}
              onChange={(e) => {
                Setshoe11half(e.target.value);
              }}
            />{" "}
            11.5
            <input
              type="checkbox"
              id="Setshoe12half"
              name="Size 12.5"
              value={"yes"}
              onChange={(e) => {
                Setshoe12half(e.target.value);
              }}
            />{" "}
            12.5
            <input
              type="checkbox"
              id="Setshoe13half"
              name="Size 13.5"
              value={"yes"}
              onChange={(e) => {
                Setshoe13half(e.target.value);
              }}
            />{" "}
            13.5


          </div>
          <br />
          <br />
       
          <br />
          <br />
          <div class="boxed">
            <h3>Eletrical Item</h3>
            <input
              type="checkbox"
              id="Setelectricalitem"
              name="electricalitem"
              value={"yes"}
              onChange={(e) => {
                setelectricalitem(e.target.value);
              }}
            />{" "}
            Electrical Item
          </div>
          <br />
          <br />
          <div class="boxed">
            <h3>🌟 Rating</h3>
            <input
              type="checkbox"
              id="Setrating"
              placeholder="rating"
              name="Rating"
              value={"1"}
              onChange={(e) => {
                Setrating(e.target.value);
              }}
            />{" "}
            1
            <input
              type="checkbox"
              id="Setrating"
              placeholder="rating"
              name="Rating"
              value={"2"}
              onChange={(e) => {
                Setrating(e.target.value);
              }}
            />{" "}
            2
            <input
              type="checkbox"
              id="Setrating"
              placeholder="rating"
              name="Rating"
              value={"3"}
              onChange={(e) => {
                Setrating(e.target.value);
              }}
            />{" "}
            3
            <input
              type="checkbox"
              id="Setrating"
              placeholder="rating"
              name="Rating"
              value={"4"}
              onChange={(e) => {
                Setrating(e.target.value);
              }}
            />{" "}
            4
            <input
              type="checkbox"
              id="Setrating"
              placeholder="rating"
              name="Rating"
              value={"5"}
              onChange={(e) => {
                Setrating(e.target.value);
              }}
            />{" "}
            5
          </div>
          <br />
          <br />
          <div class="boxed">
            <h3>Added By</h3>

            
        
            <input
              type="checkbox"
              id="Setaddedby"
              placeholder="addedby"
              name="addedby"
              value={"Graham"}
              onChange={(e) => {
                Setaddedby(e.target.value);
              }}
            />{" "}
            Graham

            <input
              type="checkbox"
              id="Setaddedby"
              placeholder="addedby"
              name="addedby"
              value={"Nicola"}
              onChange={(e) => {
                Setaddedby(e.target.value);
              }}
            />{" "}
            Nicola
        
          </div>
          <br />
          <br />
          <br />
          <br />
          <button onClick={() => setVal(() => "")}>Submit</button>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </form>

        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </center>
    </div>
  );
};
export default Prods;

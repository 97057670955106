import { React,useEffect, useState } from "react";
import { useStateValue } from "./StateProvider";
import { db } from "./firebase";
import { auth } from "./firebase";
import "./Searchstuff.css";
import ImageModal from './Modal';
import {Helmet} from "react-helmet";
import NikeBanner from "./images/screen/NikeBanner.png";


function NikeTrousers() {
   
     // db.collection("Trousers").where('StoreName', 'in' , ["Nike Store"])
     const [images, setImages] = useState([]);
    const [imageUrl, setImageUrl] = useState('');  
    const [selectedImage, setSelectedImage] = useState(null);
     
    useEffect(() => {
      // Fetch image data from Firestore
      const fetchImages = async () => {
        try {
          const snapshot = await db.collection("Trousers").where('StoreName', 'in' , ["Nike Store"]).get()//db.collection('images').get();
          const imageData = snapshot.docs.map((doc) => ({
            id: doc.image,
            ...doc.data(),
          }));
          setImages(imageData);
  
          if (imageData) {
            // Get the image URL from Firebase Storage
            const imageRef = (imageData.imagePath);
            const url = await imageRef.getDownloadURL();
  
            setImageUrl(url);
          }
  
  
        } catch (error) {
          console.error('Error fetching images: ', error);
        }
      };
  
      fetchImages();
    }, []);
  
     const openModal = (image) => {
      setSelectedImage(image);
    };
  
    const closeModal = () => {
      console.log("its closed now")
      setSelectedImage(null);
    };
  
    const handleImageClick = (id , size,storename,title,image,price,rating,prodinfo,colour) => {
    
      // Handle image click event
      // You can open a larger version of the image here
      console.log('Image clicked:');
      console.log(size);
      console.log(id);
      console.log(prodinfo);
      addtocart(id , size,storename,title,image,price,rating,prodinfo,colour)
  
    };
  
  
  
   
    const [{ user }] = useStateValue();
  function addtocart(id , size,storename,title,image,price,rating,prodinfo,colour) {
    if (user) {
      //  this creates a random id for the purposes of cart deletion items
      let mynumber = Math.floor(100000 + Math.random() * 900000);
        console.log(user?.uid)
        console.log(id)
        console.log(storename)
        console.log(title)
        console.log(image)
        console.log(price)
        console.log(rating)
        console.log(prodinfo)
        console.log(colour)
        console.log(size)
        
        
  
  
  
  
  
  
  
       
      db.collection("cart")
        .doc(user?.uid)
        .collection("orders")
        .add({
      
          idAndCode: mynumber,
          id: id,
          storename: storename,
          title: title,
          image: image,
          price: price,
          rating: rating,
          prodinfo: prodinfo,
          colour: colour,
          size: size,
          quantity: Number(1),
          user: user.uid,
          status: "Added To basket",
        
        
         })
  
        .then((docRef) => {
             
          alert( size + " Item Added to Basket" );
        })
        .catch((error) => {
          console.error("Error adding document: ", error);
        });
    } else {
      <script>
        function myFunction(){" "}
        {alert(
          "Please Sign in before trying to add items to the Basket! Thank you"
        )}
      </script>;
    }
     
  }
  
  
    
  
    return (
  
      
     
      <div className="thumbnail-gallery">
       
        {images.map((image) => (
          <div
          
            className="thumbnail"
            key={images.image}
            onClick={() => openModal(image)}
           // onClick={() => handleImageClick(image.Id)}
          >
           <img src={image.Image} alt={image.Id} />
          
  
  
            <div className="container_descory">  
           
         
          <strong>{(image.StoreName)}</strong>
             </div>
       
          </div>
  
          
        ))}
          {selectedImage && (
          <ImageModal
            isOpen={true}
            closeModal={closeModal}
            handleImageClick={handleImageClick}
            image={selectedImage.Image}
            Prodinfo={selectedImage.Prodinfo}
            rating={selectedImage.Rating}
            id={selectedImage.Id}
            storename={selectedImage.StoreName}
            price={selectedImage.Price}
            title={selectedImage.Title}
            colour={selectedImage.Colour}
  
          shoe6={selectedImage.Shoe6}
          shoe7={selectedImage.Shoe7}
          shoe8={selectedImage.Shoe8}
          shoe9={selectedImage.Shoe9}
          shoe9={selectedImage.Shoe9}
          shoe10={selectedImage.Shoe10}
          shoe11={selectedImage.Shoe11}
          shoe12={selectedImage.Shoe12}
          shoe13={selectedImage.Shoe13}
          shoe6half={selectedImage.Shoe6half}
          shoe7half={selectedImage.Shoe7half}
          shoe8half={selectedImage.Shoe8half}
          shoe9half={selectedImage.Shoe9half}
          shoe10half={selectedImage.Shoe10half}
          shoe11half={selectedImage.Shoe11half}
          shoe12half={selectedImage.Shoe12half}
          shoe13half={selectedImage.Shoe13half}
          trouser32L={selectedImage.Trouser32L}
          trouser32R={selectedImage.Trouser32R}
          trouser32S={selectedImage.Trouser32S}
          trouser34L={selectedImage.Trouser34L}
          trouser34R={selectedImage.Trouser34R}
          trouser34S={selectedImage.Trouser34S}
          trouser36L={selectedImage.Trouser36L}
          trouser36R={selectedImage.Trouser36R}
          trouser36S={selectedImage.Trouser36S}
          trouser38L={selectedImage.Trouser38L}
          trouser38R={selectedImage.Trouser38R}
          trouser38S={selectedImage.Trouser38S}
          trouser40L={selectedImage.Trouser40L}
          trouser40R={selectedImage.Trouser40R}
          trouser40S={selectedImage.Trouser40S}
          trouser42L={selectedImage.Trouser42L}
          trouser42R={selectedImage.Trouser42R}
          trouser42S={selectedImage.Trouser42S}
          trouser44S={selectedImage.Trouser44S}
          trouser44R={selectedImage.Trouser44R}
          trouser44L={selectedImage.Trouser44L}
          sizelarge={selectedImage.Sizelarge}
          sizemed={selectedImage.Sizemed}
          sizesm={selectedImage.Sizesm}
          sizexs={selectedImage.Sizexs}
          sizexl={selectedImage.Sizexl}
          sizexxl={selectedImage.Sizexxl}
          sizexxxl={selectedImage.Sizexxxl}
          sizexxxxl={selectedImage.Sizexxxxl}
           onefitall={selectedImage.Onefitall}
  
  
  
  
  
  
  
  
          />
        )}
    
      </div>
    );
  }
export default NikeTrousers
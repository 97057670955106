import React, { useState } from "react";
import "./DailyMedicationChart.css";
import { Line } from 'react-chartjs-2';

const DailyMedicationChart = () => {
  const [medications, setMedications] = useState([
    { name: "Medication A", dosage: "10mg", morning: true, noon: false, evening: true, bedtime: false },
    { name: "Medication A", dosage: "10mg", morning: true, noon: false, evening: true, bedtime: false },
    { name: "Medication C", dosage: "120mg", morning: true, noon: true, evening: true, bedtime: false },
    { name: "Medication B", dosage: "20mg", morning: true, noon: true, evening: false, bedtime: false },
    { name: "Medication A", dosage: "10mg", morning: true, noon: false, evening: true, bedtime: false },
    { name: "Medication A", dosage: "10mg", morning: true, noon: false, evening: true, bedtime: false },
    { name: "Medication A", dosage: "110mg", morning: true, noon: false, evening: true, bedtime: false },


  ]);

  const handleMedicationChange = (index, field) => {
    const newMedications = [...medications];
    newMedications[index][field] = !newMedications[index][field];
    setMedications(newMedications);
  };

  const [medications2, setMedications2] = useState([
    { id2: 1, name2: 'Medication A', time2: '9:00 AM', isTaken: false },
    { id2: 2, name2: 'Medication B', time2: '1:00 PM', isTaken: false },
    { id2: 3, name2: 'Medication C', time2: '5:00 PM', isTaken: false },
    { id2: 4, name2: 'Medication D', time2: '9:00 PM', isTaken: false }
  ]);

  const handleCheckboxChange = (id2) => {
    setMedications2(medications2.map(med => {
      if (med.id2 === id2) {
        return { ...med, isTaken: !med.isTaken };
      }
      return med;
    }));
  };

  

  return (
    <div className="daily-medication-chart">
      <table>
        <thead>
          <tr>
            <th></th>
            <th>Mon</th>
            <th>Tue</th>
            <th>Wed</th>
            <th>Thu</th>
            <th>Fri</th>
            <th>Sat</th>
            <th>Sun</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>Morning</th>
            {medications.map((medication, index) => (
              <td
                key={`morning-${index}`}
                className={medication.morning ? "active" : ""}
                onClick={() => handleMedicationChange(index, "morning")}
              >
                {medication.morning && (
                  <span>
                    {medication.name} ({medication.dosage})
                  </span>
                )}
              </td>
            ))}
          </tr>
          <tr>
            <th>Noon</th>
            {medications.map((medication, index) => (
              <td
                key={`noon-${index}`}
                className={medication.noon ? "active" : ""}
                onClick={() => handleMedicationChange(index, "noon")}
              >
                {medication.noon && (
                  <span>
                    {medication.name} ({medication.dosage})
                  </span>
                )}
              </td>
            ))}
          </tr>
          <tr>
            <th>Evening</th>
            {medications.map((medication, index) => (
              <td
                key={`evening-${index}`}
                className={medication.evening ? "active" : ""}
                onClick={() => handleMedicationChange(index, "evening")}
              >
                {medication.evening && (
                  <span>
                    {medication.name} ({medication.dosage})
                  </span>
                )}
              </td>
            ))}
          </tr>
          <tr>
            <th>Bedtime</th>
            {medications.map((medication, index) => (
              <td
                key={`bedtime-${index}`}
                className={medication.bedtime ? "active" : ""}
                onClick={() => handleMedicationChange(index, "bedtime")}
              >
                {medication.bedtime && (
                  <span>
                    {medication.name} ({medication.dosage})
                  </span>
                )}
              </td>
            ))}
          </tr>
        </tbody>
      </table>
  
  
      <div className="medication-chart">
      <h2>Daily Medication Chart</h2>
      <table>
        <thead>
          <tr>
            <th>Medication</th>
            <th>Time</th>
            <th>Taken</th>
          </tr>
        </thead>
        <tbody>
          {medications2.map(med => (
            <tr key={med.id2}>
              <td>{med.name2}</td>
              <td>{med.time2}</td>
              <td>
                <label className="checkbox-container">
                  <input type="checkbox" checked={med.isTaken} onChange={() => handleCheckboxChange(med.id2)} />
                  <span className="checkmark"></span>
                </label>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>


    
    </div>





  );
  
};

export default DailyMedicationChart;

import React from 'react';
import "./CheckoutProduct.css"
import { useStateValue } from './StateProvider';




function CheckoutProduct({id, storename,image, title, price, rating ,colour, user,trouser32L,trouser32R,trouser32S,trouser34L,trouser34R,trouser34S,trouser36L
    ,trouser36R ,trouser36S ,trouser38L ,trouser38R ,trouser38S ,trouser40L ,trouser40R ,trouser40S ,trouser42L ,trouser42R,trouser42S ,trouser44S ,trouser44L ,trouser44R
    , hideButton ,sizelarge,sizemed,sizesm,prodinfo,status,
    sizexs,sizexl,sizexxl,sizexxxl,sizexxxxl ,size,created ,  process}) {
    const [{basket} , dispatch] = useStateValue();
    
    const removeFromBasket = () => {
        //remove the item from basket
        alert("Data Successfully Submitted");

    }
    const info1 = () => {
        //remove the item from basket
     
        alert("Data smit Submitted");
    }


   
    return (
        <div className="checkoutProduct">
            <p className="checkoutProduct__title"> Status - {process} </p>
            <img className="checkoutProduct__image"  src={image} />
            <div className="checkoutProduct__info">
            <p className="checkoutProduct__title">{title} </p>
            <p className="checkoutProduct__colour">{colour} </p>
            <p className="checkoutProduct__user">{user} </p>
            <p className="checkoutProduct__prodinfo">{prodinfo} </p>
            



           
            {trouser32L === 'selected' ? <p className="checkoutProduct__trouser">{trouser32L && 'Size 32L'} </p> : null}
            {trouser32R === 'selected' ? <p className="checkoutProduct__trouser">{trouser32R && 'Size 32R'} </p> : null}
            {trouser32S === 'selected' ? <p className="checkoutProduct__trouser">{trouser32S && 'Size 32S'} </p> : null}
            {trouser34L === 'selected' ? <p className="checkoutProduct__trouser">{trouser34L && 'Size 34L'} </p> : null}
            {trouser34R === 'selected' ? <p className="checkoutProduct__trouser">{trouser34R && 'Size 34R'} </p> : null}
            {trouser34S === 'selected' ? <p className="checkoutProduct__trouser">{trouser34S && 'Size 34S'} </p> : null}
            {trouser36L === 'selected' ? <p className="checkoutProduct__trouser">{trouser36L && 'Size 36L'} </p> : null}
            {trouser36R === 'selected' ? <p className="checkoutProduct__trouser">{trouser36R && 'Size 36R'} </p> : null}
            {trouser36S === 'selected' ? <p className="checkoutProduct__trouser">{trouser36S && 'Size 36S'} </p> : null}
            {trouser38L === 'selected' ? <p className="checkoutProduct__trouser">{trouser38L && 'Size 38L'} </p> : null}
            {trouser38R === 'selected' ? <p className="checkoutProduct__trouser">{trouser38R && 'Size 38R'} </p> : null}
            {trouser38S === 'selected' ? <p className="checkoutProduct__trouser">{trouser38S && 'Size 38S'} </p> : null}
            {trouser40L === 'selected' ? <p className="checkoutProduct__trouser">{trouser40L && 'Size 40L'} </p> : null}
            {trouser40R === 'selected' ? <p className="checkoutProduct__trouser">{trouser40R && 'Size 40R'} </p> : null}
            {trouser40S === 'selected' ? <p className="checkoutProduct__trouser">{trouser40S && 'Size 40S'} </p> : null}
            {trouser42L === 'selected' ? <p className="checkoutProduct__trouser">{trouser42L && 'Size 42L'} </p> : null}
            {trouser42R === 'selected' ? <p className="checkoutProduct__trouser">{trouser42R && 'Size 42R'} </p> : null}
            {trouser42S === 'selected' ? <p className="checkoutProduct__trouser">{trouser42S && 'Size 42S'} </p> : null}
            {trouser44L === 'selected' ? <p className="checkoutProduct__trouser">{trouser44L && 'Size 44L'} </p> : null}
            {trouser44R === 'selected' ? <p className="checkoutProduct__trouser">{trouser44R && 'Size 44R'} </p> : null}
            {trouser44S === 'selected' ? <p className="checkoutProduct__trouser">{trouser44S && 'Size 44S'} </p> : null}

            {sizexs === 'selected' ? <p className="checkoutProduct__trouserxs">{sizexs && 'Size XS'} </p> : null}
            {sizesm === 'selected' ? <p className="checkoutProduct__trousersm">{sizesm && 'Size Small'} </p> : null}
            {sizemed === 'selected' ? <p className="checkoutProduct__trouser">{sizemed && 'Size Medium'} </p> : null}
            {sizelarge === 'selected' ? <p className="checkoutProduct__trouser">{sizelarge && 'Size Large'} </p> : null}
            {sizexl === 'selected' ? <p className="checkoutProduct__trouser">{sizexl && 'Size XL'} </p> : null}
            {sizexxl === 'selected' ? <p className="checkoutProduct__trouser">{sizexxl && 'Size XXL'} </p> : null}
            {sizexxxl === 'selected' ? <p className="checkoutProduct__trouser">{sizexxxl && 'Size XXXL'} </p> : null}
            {sizexxxxl === 'selected' ? <p className="checkoutProduct__trouser">{sizexxxxl && 'Size XXXXL'} </p> : null}
            {sizexxxxl === 'selected' ? <p className="checkoutProduct__trouser">{sizexxxxl && 'Size XXXXL'} </p> : null}
          

           

            <p > 
            <strong>{storename}</strong>
            </p>
            <p > 
            <strong>{user}</strong>
            </p>
            <p > 
            <strong>{size}</strong>
            </p>

            <p className="checkoutProduct__price">
          
            <strong>£{price ,Number(price).toFixed(2) }</strong>
            </p>

           
            <div className="checkoutProduct__rating">
                {Array(rating)
                .fill()
                //map or render through
                .map((i) => (<p>🌟</p>))}
            </div>

            {!hideButton && (
              <div className="checkoutProduct__info" ><button onClick={info1}> Goods Arrived</button>    </div>   
              
              )}

            {!hideButton && (
              <div className="checkoutProduct__backinfo" ><button onClick={removeFromBasket}> Goods Sent Back to supplier</button>    </div>     

            )}
                    
          </div>

        </div>
    )
}

export default CheckoutProduct

import React, { useEffect, useState } from 'react'
import './Orders.css';
import { db } from "./firebase";
import {useStateValue} from "./StateProvider";
import Order from "./Order";
import {Helmet} from 'helmet'

function Orders() {
    const [{ basket,user}, dispatch] = useStateValue();
    const [orders, setOrders] = useState([]);


    useEffect(() => {
        if(user) {

        db.collection('AllpaidNewItems')
        .doc(user?.uid)
        .collection('orders')
        .onSnapshot (snapshot => (
            setOrders(snapshot.docs.map(doc => ({
                id: doc.id,
                data: doc.data()

            })))


        ))
    } else {

        
    }


    }, [user])
    return (
        <div className="orders">
            <h1>Your Orders</h1>

        <div classname= 'orders__order'> 
         {orders?.map (order => (
        <Order order={order} />

         ))}
        
        </div>

        </div>
    )
}

export default Orders

import React from "react";
import "./AllAcceptedOrders.css";
import moment from "moment";
import AllAcceptedProducts from "./AllAcceptedProducts";
import CurrencyFormat from "react-currency-format";
import { Helmet } from "helmet";
import { db } from "./firebase";

function AllAcceptedOrders({ order }) {


    var formatter = new Intl.NumberFormat('gbp', {
        style: 'currency',
        currency: 'GBP',
      
        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });



//<p>{(order.data.colour)}</p>
//<p>{(order.data.storename)}</p>
      
var prodid = order.id;
var idandcode = order.data.idAndCode;
var paymentintentvalue = order.data.paymentintentvalue;
var title = order.data.title;
var whostheboss = order.data.prodinfo;

//<p>{(order.data.colour)}</p>
//<p>{(order.data.storename)}</p>








      
function updateAcceptedDetails(num){

             console.log(whostheboss);
             console.log(prodid)
             console.log(idandcode)
              console.log(paymentintentvalue)
              console.log(num)
              console.log(order.id)
  
         // db.collection("OrdersSettled").doc(whostheboss).collection('OrdersRecieved').doc(order.id)
       
         db.collection("OrdersSettled")
      .doc(num)
      .collection("OrdersRecieved")
         
  .where('idAndCode', 'in' , [idandcode])
           
            .get()
            .then((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                doc.ref.update({
                  process: "Goods Accepted and Finalised", 


                


                  
                 
        
              }).catch(function(error) {
                  console.error("Error updating document: ", error);
                });
              });
            
            alert("Order has been finalised");
            gotthisok(num)

       
       
  })      
}


                function gotthisok(num) {
                  const reference = db.collection("AllpaidItems")
                                  .doc(num)
                                  .collection("orders").doc(paymentintentvalue)
                                 // reference.where(val, "==", idAndCode)
                                 reference.update({
                            
                                  process:  " - " + title + " UPDATE -- Order finalised" + '\n' + " , ",
                                  
                                 
                                 })
                                  console.log("nothing happened did it?")
                               
                                
                               
                }
                





  return (
    <div class="boxedNew">
    <div className="order">
     
      <h2>Order</h2>
      <p>{moment.unix(order.data.datepaid).format("Do MMMM YYYY, h:mma")}</p>

      <p className="order__id">
        <small>Order id - {order.id}</small>
      </p>
      <p className="order__id2">
        <strong>Status - {order.data.process}</strong>
      </p>
    
     
      <img className="allPaidProducts2__image2" src={(order.data.image)} />
      <p>Item Name - {(order.data.title)}</p>
      <p>Store Name - {(order.data.storename)}</p>
      <p>Colour - {(order.data.colour)}</p>
      <p>Size - {(order.data.size)}</p>
      <p>User Id - {(order.data.prodinfo)}</p>
    
     
      <p><small>Item id - {(order.data.id)}</small></p>
 
     
      <p className="order__id">
        <small>{order.storename}</small>
      </p>
      <button
                    onClick={() => updateAcceptedDetails(order.data.prodinfo)}
                    className="btn-btn-danger"
                  >
                    Order Finalised
                  </button>
     
      </div>
      
      {order.data.basket?.map((item) => (
        <AllAcceptedProducts
          id={item.id}
          storename={item.storename}
          title={item.title}
          size={item.size}
          image={item.image}
          price={item.price}
          rating={item.rating}
          colour={item.colour}
          prodinfo={item.user}
          hideButtons
         
        
        />
      ))}
     

      <CurrencyFormat
        renderText=  {(value) => (
          <h3 className="order__total">Order Total: { value}</h3>
        )}
        decimalScale={2}
        value={ formatter.format (order.data.price )}
        displayType={"text"}
        thousandSeparator={true}
        prefix={"£"}
      />
    </div>
  );
}


export default AllAcceptedOrders
import React, { useEffect, useState } from 'react'
import './Orders.css';
import { db } from "./firebase";
import {useStateValue} from "./StateProvider";
import OutOfStockOrder from "./OutOfStockOrder";
import {Helmet} from 'helmet'
import CurrencyFormat from "react-currency-format";

function OutOfStock() {
    const [{ basket,user}, dispatch] = useStateValue();
    const [orders, setOrders] = useState([]);

    var formatter = new Intl.NumberFormat("gbp", {
        style: "currency",
        currency: "GBP",
    
        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });
    useEffect(() => {
      
        db.collection("All")
        .get()
        .then((querySnapshot) => {
          //db.collection("All").get().then((querySnapshot) => {
          // Loop through the data and store
          // it in array to display
          querySnapshot.forEach((element) => {
            var data = element.data();
            setOrders((arr) => [...arr, data]);
          })
          
          ;
          
          
    }
        )})
  

    return (

        
        <div className="orders">
        <h2>Order you have placed but is out of stock</h2>
        <p>{orders.data.dateview}</p>
  
        <img className="img-wrap" src={orders.data.image} />
        <p className="order__id">
          <small>Item id - {orders.id}</small>
        </p>
        <p>
          <h3>{orders.data.storeName}</h3>
        </p>
        <p>
          <small>{orders.data.prodinfo}</small>
        </p>
  
        <CurrencyFormat
          renderText={(value) => (
            <h3 className="order__total">Item Price: {value}</h3>
          )}
          decimalScale={2}
          value={formatter.format(orders.data.price)}
          displayType={"text"}
          thousandSeparator={true}
          prefix={"£"}
        />
        <h5 className="order__total">
          Check Stock in a few days to see if product may become available again.
        </h5>
      </div>
    );
}

export default OutOfStock
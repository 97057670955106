import { React ,useEffect, useState } from "react";
import "./Product.css";
import { useStateValue } from "./StateProvider";
import { db } from "./firebase";
import { auth } from "./firebase";
import {Helmet} from "react-helmet";
import currysBanner from "./images/screen/currysBanner.png";
import ImageModal from './Modal';


function CurrysAll() {
const [images, setImages] = useState([]);
  const [imageUrl, setImageUrl] = useState('');  
  const [selectedImage, setSelectedImage] = useState(null);
   
  useEffect(() => {
    // Fetch image data from Firestore
    const fetchImages = async () => {
      try {
        const snapshot = await  db.collection("All").where('StoreName', 'in' , ['Curries Pc World']).get()//db.collection('images').get();
        const imageData = snapshot.docs.map((doc) => ({
          id: doc.image,
          ...doc.data(),
        }));
        setImages(imageData);

        if (imageData) {
          // Get the image URL from Firebase Storage
          const imageRef = (imageData.imagePath);
          const url = await imageRef.getDownloadURL();

          setImageUrl(url);
        }


      } catch (error) {
        console.error('Error fetching images: ', error);
      }
    };

    fetchImages();
  }, []);

   const openModal = (image) => {
    setSelectedImage(image);
  };

  const closeModal = () => {
    console.log("its closed now")
    setSelectedImage(null);
  };

  const handleImageClick = (id , size,storename,title,image,price,rating,prodinfo,colour) => {
  
    // Handle image click event
    // You can open a larger version of the image here
    console.log('Image clicked:');
    console.log(size);
    console.log(id);
    console.log(prodinfo);
    addtocart(id , size,storename,title,image,price,rating,prodinfo,colour)

  };



 
  const [{ user }] = useStateValue();
function addtocart(id , size,storename,title,image,price,rating,prodinfo,colour) {
  if (user) {
    //  this creates a random id for the purposes of cart deletion items
    let mynumber = Math.floor(100000 + Math.random() * 900000);
      console.log(user?.uid)
      console.log(id)
      console.log(storename)
      console.log(title)
      console.log(image)
      console.log(price)
      console.log(rating)
      console.log(prodinfo)
      console.log(colour)
      console.log(size)
      
      







     
    db.collection("cart")
      .doc(user?.uid)
      .collection("orders")
      .add({
    
        idAndCode: mynumber,
        id: id,
        storename: storename,
        title: title,
        image: image,
        price: price,
        rating: rating,
        prodinfo: prodinfo,
        colour: colour,
        size: size,
        quantity: Number(1),
        user: user.uid,
        status: "Added To basket",
      
      
       })

      .then((docRef) => {
           
        alert( size + " Item Added to Basket" );
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });
  } else {
    <script>
      function myFunction(){" "}
      {alert(
        "Please Sign in before trying to add items to the Basket! Thank you"
      )}
    </script>;
  }
   
}


  

  return (

    
   
    <div className="thumbnail-gallery">
     
      {images.map((image) => (
        <div
        
          className="thumbnail"
          key={images.image}
          onClick={() => openModal(image)}
         // onClick={() => handleImageClick(image.Id)}
        >
         <img src={image.Image} alt={image.Id} />
        


          <div className="container_descory">  
         
       
        <strong>£ {(image.Price, Number(image.Price).toFixed(2))}</strong>
           </div>
     
        </div>

        
      ))}
        {selectedImage && (
        <ImageModal
          isOpen={true}
          closeModal={closeModal}
          handleImageClick={handleImageClick}
          image={selectedImage.Image}
          Prodinfo={selectedImage.Prodinfo}
          rating={selectedImage.Rating}
          id={selectedImage.Id}
          storename={selectedImage.StoreName}
          price={selectedImage.Price}
          title={selectedImage.Title}
          colour={selectedImage.Colour}

        shoe6={selectedImage.Shoe6}
        shoe7={selectedImage.Shoe7}
        shoe8={selectedImage.Shoe8}
        shoe9={selectedImage.Shoe9}
        shoe9={selectedImage.Shoe9}
        shoe10={selectedImage.Shoe10}
        shoe11={selectedImage.Shoe11}
        shoe12={selectedImage.Shoe12}
        shoe13={selectedImage.Shoe13}
        shoe6half={selectedImage.Shoe6half}
        shoe7half={selectedImage.Shoe7half}
        shoe8half={selectedImage.Shoe8half}
        shoe9half={selectedImage.Shoe9half}
        shoe10half={selectedImage.Shoe10half}
        shoe11half={selectedImage.Shoe11half}
        shoe12half={selectedImage.Shoe12half}
        shoe13half={selectedImage.Shoe13half}
        trouser32L={selectedImage.Trouser32L}
        trouser32R={selectedImage.Trouser32R}
        trouser32S={selectedImage.Trouser32S}
        trouser34L={selectedImage.Trouser34L}
        trouser34R={selectedImage.Trouser34R}
        trouser34S={selectedImage.Trouser34S}
        trouser36L={selectedImage.Trouser36L}
        trouser36R={selectedImage.Trouser36R}
        trouser36S={selectedImage.Trouser36S}
        trouser38L={selectedImage.Trouser38L}
        trouser38R={selectedImage.Trouser38R}
        trouser38S={selectedImage.Trouser38S}
        trouser40L={selectedImage.Trouser40L}
        trouser40R={selectedImage.Trouser40R}
        trouser40S={selectedImage.Trouser40S}
        trouser42L={selectedImage.Trouser42L}
        trouser42R={selectedImage.Trouser42R}
        trouser42S={selectedImage.Trouser42S}
        trouser44S={selectedImage.Trouser44S}
        trouser44R={selectedImage.Trouser44R}
        trouser44L={selectedImage.Trouser44L}
        sizelarge={selectedImage.Sizelarge}
        sizemed={selectedImage.Sizemed}
        sizesm={selectedImage.Sizesm}
        sizexs={selectedImage.Sizexs}
        sizexl={selectedImage.Sizexl}
        sizexxl={selectedImage.Sizexxl}
        sizexxxl={selectedImage.Sizexxxl}
        sizexxxxl={selectedImage.Sizexxxxl}
         onefitall={selectedImage.Onefitall}

         Stddvd={selectedImage.Stddvd}
         Bluedvd={selectedImage.Bluedvd}
         MusicCd={selectedImage.MusicCd}
         Electricalitem={selectedImage.Electricalitem}






        />
      )}
  
    </div>
  );
}


export default CurrysAll



































/*



function CurrysAll() {
    const [info, setInfo] = useState([]);

    window.onload = function () {
      Fetchdata();
    };
  
  
    // Fetch the required data using the get() method
    const Fetchdata = () => {
      db.collection("All").where('StoreName', 'in' , ['Curries Pc World'])
      .get().then((querySnapshot) => {
          //db.collection("All").get().then((querySnapshot) => {
          // Loop through the data and store
          // it in array to display
          querySnapshot.forEach((element) => {
            var data = element.data();
            setInfo((arr) => [...arr, data]);
          });
          unsub();
        });
    };
    const unsub = db.collection("All").onSnapshot(() => {});
  
    <div id="some-id"></div>;
  
  
  
  
    // Display the result on the page
    return (
      <div className="img-grid">
      {info.map((data) => (
        <Frame
          storename={data.StoreName}
          id={data.Id}
          image={data.Image}
          price={data.Price}
          title={data.Title}
          prodinfo={data.Prodinfo}
          rating={data.Rating}
          colour={data.Colour}
          shoe6={data.Shoe6}
          shoe7={data.Shoe7}
          shoe8={data.Shoe8}
          shoe9={data.Shoe9}
          shoe9={data.Shoe9}
          shoe10={data.Shoe10}
          shoe11={data.Shoe11}
          shoe12={data.Shoe12}
          shoe13={data.Shoe13}
          shoe6half={data.Shoe6half}
          shoe7half={data.Shoe7half}
          shoe8half={data.Shoe8half}
          shoe9half={data.Shoe9half}
          shoe10half={data.Shoe10half}
          shoe11half={data.Shoe11half}
          shoe12half={data.Shoe12half}
          shoe13half={data.Shoe13half}
          trouser32L={data.Trouser32L}
          trouser32R={data.Trouser32R}
          trouser32S={data.Trouser32S}
          trouser34L={data.Trouser34L}
          trouser34R={data.Trouser34R}
          trouser34S={data.Trouser34S}
          trouser36L={data.Trouser36L}
          trouser36R={data.Trouser36R}
          trouser36S={data.Trouser36S}
          trouser38L={data.Trouser38L}
          trouser38R={data.Trouser38R}
          trouser38S={data.Trouser38S}
          trouser40L={data.Trouser40L}
          trouser40R={data.Trouser40R}
          trouser40S={data.Trouser40S}
          trouser42L={data.Trouser42L}
          trouser42R={data.Trouser42R}
          trouser42S={data.Trouser42S}
          trouser44S={data.Trouser44S}
          trouser44R={data.Trouser44R}
          trouser44L={data.Trouser44L}
          sizelarge={data.Sizelarge}
          sizemed={data.Sizemed}
          sizesm={data.Sizesm}
          sizexs={data.Sizexs}
          sizexl={data.Sizexl}
          sizexxl={data.Sizexxl}
          sizexxxl={data.Sizexxxl}
          sizexxxxl={data.Sizexxxxl}
          onefitall={data.Onefitall}
          Stddvd={data.Stddvd}
          Bluedvd={data.Bluedvd}
          MusicCd={data.MusicCd}
          Electricalitem={data.Electricalitem}
          
          
        />
      ))}
    </div>
  );
}

// Define how each display entry will be structured
const Frame = ({
  id,
  idAndCode,
  image,
  price,
  rating,
  shoe6,
  title,
  prodinfo,
  colour,
  trouser32L,
  trouser32R,
  trouser32S,
  trouser34L,
  trouser34R,
  trouser34S,
  trouser36L,
  trouser36R,
  trouser36S,
  trouser38L,
  trouser38R,
  trouser38S,
  trouser40L,
  trouser40R,
  trouser40S,
  trouser42L,
  trouser42R,
  trouser42S,
  trouser44S,
  trouser44L,
  trouser44R,
  sizelarge,
  sizemed,
  sizesm,
  sizexs,
  sizexl,
  sizexxl,
  sizexxxl,
  sizexxxxl,
  size,
  storename,
  shoe7,
  shoe8,
  shoe9,
  shoe10,
  shoe11,
  shoe12,
  shoe13,
  shoe6half,
  shoe7half,
  shoe8half,
  shoe9half,
  shoe10half,
  shoe11half,
  shoe12half,
  shoe13half,
  onefitall,
  Stddvd,
  Bluedvd,
  MusicCd,
  Electricalitem,
  status,
    
  }) => {
    // const [{basket,user}, dispatch] = useStateValue();
    const [{ user }] = useStateValue();
    const selectShortlistedApplicant = (e) => {
      const checked = e.target.checked;
  
      if (checked) {
        //checked
  
        addtocart();
  
        //alert("Selected");
      } else {
        console.log(id);
          let collectionRef = db
          .collection("cart")
          .doc(user?.uid)
          .collection("orders");
    
        collectionRef
          .where("id", "==", id)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              doc.ref
                .delete()
                .then(() => {
                  console.log("Document successfully deleted!");
                })
                .catch(function (error) {
                  console.error("Error removing document: ", error);
                });
            });
          });



        //unchecked  setDisable(e.target.value);
        alert("Removed from basket!");
        // alert(" Successfully unticked");
      }
    };
  
    function addtocart(item) {
      if (user) {
        //  this creates a random id for the purposes of cart deletion items
        let mynumber = Math.floor(100000 + Math.random() * 900000);
  
        db.collection("cart")
          .doc(user?.uid)
          .collection("orders")
          .add({
            idAndCode: id && mynumber,
            id: id,
            storename: storename,
            title: title,
            image: image,
            price: price,
            rating: rating,
            prodinfo: prodinfo,
            colour: colour,
            size: size,
            quantity: Number(1),
            user: user.uid,
            status: "Added To basket"
          
          })
  
          .then((docRef) => {
            alert( size + " Item Added to Basket" );
          })
          .catch((error) => {
            console.error("Error adding document: ", error);
          });
      } else {
        <script>
          function myFunction(){" "}
          {alert(
            "Please Sign in before trying to add items to the Basket! Thank you"
          )}
        </script>;
      }
    }
  
    const handleAuthentication = () => {
      if (user) {
        auth.signOut();
      } else {
      }
    };
  
    
  
    return (
      <div className="container_234">
        <Helmet>
          <title>Secure Shopping| Home</title>
        </Helmet>
        <div className="container_234"></div>
        <div className="item">
        <img className="imgsourse1" src={currysBanner} alt="" />
        </div>
      <div className="item">
        <p>{storename}</p>
      </div>
      <div className="item">
        <p>{title}</p>
      </div>
      <div className="item">
        <small>Product Code:{id}</small>
      </div>
      <div className="item_info">
        <p>{prodinfo}</p>
      </div>

      <div className="item_price">
        <small>£</small>
        <strong>{(price, Number(price).toFixed(2))}</strong>
      </div>

      <div className="item_colour">
        <p>Colour : {colour} </p>
      </div>
      <div> </div>
      <div className="addtobasketlogo">
        {" "}
        <strong>Click box to add to your Basket</strong>
      </div>

      <div className="item_size1">
        {onefitall === "Yes" ? (
          <p>
            {" "}
            One Size Fits All{" "}
            <input
              type="checkbox"
              className="checkbox_values"
              value="1"
              onClick={(e) => {
                size = "One Size Fits All";
                selectShortlistedApplicant(e);
              }}
            />{" "}
          </p>
        ) : null}

       
        <td>
          {" "}
          {Stddvd === "yes" ? (
            <p>
              {" "}
              <a2>Standard Dvd: </a2>
              <input
                type="checkbox"
                className="checkbox_values"
                value="1"
                onClick={(e) => {
                  size = "Standard Dvd";
                  selectShortlistedApplicant(e);
                }}
              />{" "}
              ← Press Here to add to basket{" "}
            </p>
          ) : null}
        </td>

        <td>
          {" "}
          {Bluedvd === "yes" ? (
            <p>
              {" "}
              Blu-Ray Dvd:{" "}
              <input
                type="checkbox"
                className="item"
                value="1"
                onClick={(e) => {
                  size = "Standard Dvd";
                  selectShortlistedApplicant(e);
                }}
              />{" "}
              ← Press Here to add to basket{" "}
            </p>
          ) : null}
        </td>

        <td>
          {" "}
          {MusicCd === "yes" ? (
            <p>
              {" "}
              Music Cd:{" "}
              <input
                type="checkbox"
                className="item"
                value="1"
                onClick={(e) => {
                  size = "Music Cd";
                  selectShortlistedApplicant(e);
                }}
              />{" "}
              ← Press Here to add to basket{" "}
            </p>
          ) : null}
        </td>

        {Electricalitem === "yes" ? (
          <p>
            {" "}
            <strong> Add electrical item: </strong>
            <input
              type="checkbox"
              className="checkbox_values1"
              value="1"
              onClick={(e) => {
                size = "electrical item";
                selectShortlistedApplicant(e);
              }}
            />{" "}
            ← Press Here to add to basket{" "}
          </p>
        ) : null}
      </div>

      <div className="item_rating">
        {Array(rating)
          .fill()
          .map((_, i) => (
            <p>🌟</p>
          ))}
      </div>

      <img className="img-wrap" src={image} alt="" />
    </div>
  );
};

export default CurrysAll


*/
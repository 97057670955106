import React from 'react';
import './Imagecodeofman.css'; // import CSS file with styles for the image
import codeofman from "./images/CodeOfMan.png"

function imagecodeofman() {
  return (
    <div className="image-container">
     <img src={codeofman} alt="codeofman" />
    </div>
  );
}

export default imagecodeofman;
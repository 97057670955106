import React from 'react';
import { Line } from 'react-chartjs-2';

const MedicationChart = ({ data }) => {
  const chartData = {
    labels: data.map(entry => entry.date),
    datasets: [
      {
        label: 'Medication',
        data: data.map(entry => entry.medicationTaken ? 1 : 0), // Assuming medicationTaken is a boolean
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'rgba(75,192,192,1)',
        borderWidth: 1,
      },
    ],
  };

  return <Line data={chartData} />;
};

export default MedicationChart;

import React, {useState} from 'react';
import './Login.css';
import { Link, useHistory } from "react-router-dom";
import {auth,db} from "./firebase";
import { useStateValue } from './StateProvider';
import securelogo from './images/Securelogo.jpg'

function FinanceLogin() {
  //this is where you can change email to patient number
  const history = useHistory();
  const [email, setemail] = useState('');
  const [password, setpassword] = useState('');
  const [newPassword ,setnewPassword] = useState('');
  const [userid , SET_USER] = useState("");
  const [id , Setid] = useState("");
  const [producttype , Setproducttype] = useState("");
 
  const [{user} ] = useStateValue();

 /* function writeUserData(userId, name, email, imageUrl) {
      db.collection('users/' + email).set({
        username: name,
        email: email,
        userid: userid
      });
    }
    */ 
   
   
  const signIn = e => {
     
      e.preventDefault()
      //this is where the firbase login stuff goes
      auth.signInWithEmailAndPassword(email, password)
      .then(auth => {
       //   writeUserData()
          history.push("/Finance")

      })
      .catch(error => alert(error.message))

  }

 


  return (
      <div className= 'login'>
       
          <Link to='/Product'>
         <img 
         className= "login__logo"
         src={securelogo} alt="securelogo"/>
         </Link>
        <div className= "login__container">
          <h1>Sign-in</h1>
         
          <form name="MyForm" >
              
              <h5>E-mail</h5>
              <input type='text' value={email} onChange=
              {e => setemail(e.target.value)} />

              <h5>Password</h5>
              <input type="password" value={password} onChange=
              {e => setpassword(e.target.value)  }/>

          

              <button type='submit' onClick={signIn}
              className= "login__signInButton">Sign In</button>

          </form>

          <p>
              By signing-in you agree to our terms and conditions of Use & Sale.
              You also state that the person signing in is the actual user and not someone else.
          </p>
         


        </div>
      </div>
  )
}

export default FinanceLogin
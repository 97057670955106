import { React, useState } from "react";
import "./FinanceHeader.css";
import { useStateValue } from "./StateProvider";
import { db, auth } from "./firebase";
import { Link, useHistory } from "react-router-dom";
import Accepted from "./images/AcceptedItems1.png";
import returnicon from "./images/returnitems.png";
import placeorder from "./images/placeorder1.png";
import allpaid from "./images/allpaid1.png";

function FinanceHeader() {
  const [{ basket }, dispatch] = useStateValue();

  //this code is used to pull all the info through
  const [{ user }] = useStateValue();
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [cart, setCart] = useState([]);

  // const toggling = () => setIsOpen(!isOpen);
  const button = document.querySelector("#button");
  const tooltip = document.querySelector("#tooltip");

  const onOptionClicked = (value) => () => {
    setSelectedOption(value);
    <Link to={"/Finance"}> </Link>;
    setIsOpen(false);
    console.log(selectedOption);
  };

  const handleAuthentication1 = () => {
    if (user) {
      auth.signOut();
      history.replace("/Finance");
    }
  };

  function total1() {
    let x1 = 0;
    cart.map((i) => {
      x1 += i.quantity;
    });
    return x1;
  }

  return (
    <div>
      <ul
        className="navinatorfine"
       
      >
            
       

        <li class="drop-itemfine" >
          <div id="profile11">
            <div className="header__optionfine">
              <Link to="/AllPaidNewItems">
                <img
                  className="header__optionBasketfine"
                  src={allpaid}
                  alt="AllPaidNewItems"
                />
              </Link>
              <div style={{ paddingRight: "0px" }}>All Paid Items New</div>
            </div>
          </div>
        </li>
      
        <li class="drop-itemfine" >
          <div id="profile11">
            <div className="header__optionfine">
              <Link to="/Finance">
                <img
                  className="header__optionBasketfine"
                  src={placeorder}
                  alt="Shopping"
                />
              </Link>

              <Link to="/Finance" className="header__optionfine">
                <span className="header__optionLineonefine">Place Order, </span>
              </Link>
              <Link to="/Finance" className="header__optionfine2">
                <span className="header__optionLinetwofine">Payments</span>
              </Link>
            </div>
          </div>
        </li>

        <li class="drop-itemfine" >
          <div id="profile11">
            <div
              style={{
                float: "left",
                paddingBottom: "5px",
                paddingRight: "5px",
              }}
            >
              <div
                onClick={handleAuthentication1}
                className="header__optionfine"
              >
                <span className="header__optionLineonefine">
                  Hello, {user ? user?.email.split("@")[0] : "Guest"}{" "}
                </span>

                <span className="header__optionLinetwofine">
                  {user ? "Sign Out" : "Sign In"}
                </span>
              </div>
            </div>
            <div style={{ float: "left" }} className="arrow-down"></div>
          </div>
          <div
            style={{ paddingTop: "125px" }}
            className="dropdown"
          >
            <li>
              <a className="anchor" href="FinanceLogin">
                <div
                  onClick={handleAuthentication1}
                  className="header__optionfine"
                >
                  <span className="header__optionLineonefine">
                    Hello, {user ? user?.email.split("@")[0] : "Guest"}{" "}
                  </span>

                  <span className="header__optionLinetwofine">
                    {user ? "Sign Out ?" : "Sign In"}
                  </span>
                </div>
              </a>
            </li>

            <li>
              <a
                className="anchor"
                href="FinanceNewPassword"
              >
                <span className="header__optionLinetwofine">
                  {user ? "Change Password" : ""}
                </span>
              </a>
            </li>
          </div>
        </li>

        <li class="drop-itemfine" >
          <div id="profile11">
            <div className="header__optionfine">
              <Link to="/Validation">
                <img
                  className="header__optionBasketfine"
                  src={Accepted}
                  alt="Validation"
                />
              </Link>
              <div style={{ paddingRight: "0px" }}>Validate Items</div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
}

export default FinanceHeader;



/*
 <li class="drop-itemfine" >
          <div id="profile11">
            <div className="header__optionfine">
              <Link to="/AllPaidItems">
                <img
                  className="header__optionBasketfine"
                  src={allpaid}
                  alt="AllPaidItems"
                />
              </Link>
              <div style={{ paddingRight: "0px" }}>All Paid Items</div>
            </div>
          </div>
        </li>
                 
*/
import React from "react";
import "./Home.css";
import Marquee from "react-fast-marquee";
import headershop2 from "./images/screen/dvdtitle.png";
import headershop3 from "./images/screen/dvd1.webp";
import headershop4 from "./images/screen/dvd2.webp";
import headershop5 from "./images/screen/dvd3.webp";
import headershop6 from "./images/screen/dvd4.webp";
import headershop7 from "./images/screen/dvd5.webp";
import headershop8 from "./images/screen/dvd6.webp";
import headershop9 from "./images/screen/dvd7.webp";
import headershop10 from "./images/screen/dvd8.webp";
import headershop11 from "./images/screen/dvd9.webp";
import headershop12 from "./images/screen/dvd10.webp";
import headershop13 from "./images/screen/dvd11.webp";
import headershop14 from "./images/screen/dvd12.webp";
import headershop15 from "./images/screen/dvd13.webp";
import jdsportssummer from "./images/nike-071-af1-womens-hub-middle-banner-mens-3bb1f.webp";
import joebrownsSummer from "./images/joebrownswintersale.jpg.webp";
import mandmdirect from "./images/screen/mandmDirect.png";
import nikeStore from "./images/screen/nikeStore.png";
import hsamuel from "./images/screen/hsamuelSale.png";
import currys from "./images/screen/cottont.jpg";
import {Helmet} from "react-helmet";
import summer from "./images/screen/th2.jpeg";
import kingtrain from "./images/kingoftrainers.png";
import codeofman from "./images/CodeOfMan.png"
import homeicon from "./images/screen/homeicon.png"


function Home() {
  return (
    <div className="home">
       <Helmet>
          <title>Secure Shopping| Home</title>
        </Helmet>
      <div className="home__container">
        <Marquee speed={40} gradientWidth={160} pauseOnHover={true}>
          <a href={"/SalesDVD"}>
            <img className="home__image" src={headershop2} alt="headershop2" />
          </a>
          <a href={"/SalesDVD"}>
            <img className="home__image" src={headershop3} alt="headershop3" />
          </a>

          <a href={"/SalesDVD"}>
            <img className="home__image" src={headershop4} alt="headershop4" />
          </a>

          <a href={"/SalesDVD"}>
            <img className="home__image" src={headershop7} alt="headershop5" />
          </a>
          
          <a href={"/SalesDVD"}>
            <img className="home__image" src={headershop6} alt="headershop6" />
          </a>
          <a href={"/home"}>
            <img className="home__image" src={summer} alt="summer" />
          </a>
          <a href={"/SalesDVD"}>
            <img className="home__image" src={headershop7} alt="headershop7" />
          </a>
          <a href={"/SalesDVD"}>
            <img className="home__image" src={headershop8} alt="headershop8" />
          </a>  
          <a href={"/SalesDVD"}>
            <img className="home__image" src={headershop7} alt="headershop7" />
          </a>  
          <a href={"/SalesDVD"}>
            <img className="home__image" src={headershop12} alt="headershop12" />
          </a>  
          <a href={"/SalesDVD"}>
            <img className="home__image" src={headershop13} alt="headershop13" />
          </a>  
         
          <a href={"/SalesDVD"}>
            <img
              className="home__image"
              src={headershop14}
              alt="headershop14"
            />
          </a>
          <a href={"/SalesDVD"}>
            <img
              className="home__image"
              src={headershop15}
              alt="headershop15"
            />
          </a>
          <a href={"/SalesDVD"}>
            <img
              className="home__image"
              src={headershop12}
              alt="headershop12"
            />
          </a>

          <a href={"/SalesDVD"}>
            <img
              className="home__image"
              src={headershop13}
              alt="headershop13"
            />
          </a>

          <a href={"/SalesDVD"}>
            <img className="home__image" src={headershop5} alt="headershop5" />
          </a>
        </Marquee>

        <div className="home__row">
          <a href={"/JoeBAll"}>
            {
              <img
                className="home__imageadvert"
                src={joebrownsSummer}
                alt="joebrownsSummer"
              />
            }
          </a>
          <a href={"/MandmAll"}>
            {
              <img
                className="home__imageadvert2"
                src={mandmdirect}
                alt="mandmdirect"
              />
            }
          </a>
          <a href={"/CottonAll"}>
            {<img className="home__imageadvert" src={currys} alt="currys" />}
          </a>
        </div>

        <div className="home__row">
          <a href={"/HSamuelAll"}>
            {<img className="home__imageadvert" src={hsamuel} alt="hsamuel" />}
          </a>
          <a href={"/NikeAll"}>
            {
              <img
                className="home__imageadvert"
                src={nikeStore}
                alt="nikeStore"
              />
            }
          </a>
          
          <a href={"/JDSportsAll"}>
            {<img className="home__imageadvert" src={kingtrain} alt="kingtrain" />}
          </a>
        </div>
        <div className="home__row">
          <a href={"/JDSportsAll"}>
            {
              <img
                className="home__imageadvert1"
                src={jdsportssummer}
                alt="jdsportssummer"
              />
            }
          </a>
          <a href={"/NikeAll"}>
            {
              <img
                className="home__imageadvert"
                src={nikeStore}
                alt="nikeStore"
              />
            }
          </a>
        </div>
      </div>
    </div>
  );
}

export default Home;

// <Product forceRefresh={true}> </Product>

import { React, useState, useEffect, Component } from "react";
import "./Authorisation.css";
import { useStateValue } from "./StateProvider";
import { db, firebase } from "./firebase";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";


function PostalAuth() {
   // const [info, setInfo] = useState([]);
    const [{ user }] = useStateValue();
    const [cart, setCart] = useState([]);
    const [cart1, setCart1] = useState([]);
   
   // const [products, setProducts] = useState([]);
  
    function sayHello(i) {
      console.log(i);
  
      var variable1 = i;
  
      Fetchdata(variable1);
    }
  
    useEffect(() => {
      if (user) {

        let collectionRef = db
        .collection("Authorisation");
        collectionRef = collectionRef.where("authyn", "in", ["Yes"])
        .where("postalauth", "==", "No");
       
         collectionRef.onSnapshot((querySnapshot1) => {    
         var p = [];
          querySnapshot1.forEach((doc) => {
            p.push(doc.data());
          });
  
          setCart1(p);
        });
      }
    }, [user]);
  
    const Fetchdata = (val) => {
      var variableFunction1 = val;
      console.log(val);
  
      let collectionRef2 = db.collection("cart").doc(val).collection("orders");
  
      collectionRef2.onSnapshot((querySnapshot) => {
        var p = [];
        querySnapshot.forEach((doc) => {
          p.push(doc.data());
        });
  
        setCart(p);
       
      });
    };
  
    function updateauth(val) {
      console.log(val);
  
      db.collection("Authorisation")
        .where("userid", "in", [val])
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            doc.ref
              .update({
              postalauth: "Yes",
              postalName: user.email,
              })
              .catch(function (error) {
                console.error("Error updating document: ", error);
              });
          });
          SendforAuth(val)
        });
  
     
    }

    function SendforAuth(val) {
      let collectionRef = db
        .collection("cart")
        .doc(val)
        .collection("orders");
  
      collectionRef.get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          doc.ref
            .update({
              authorised: "Sent to Finance",
              
              
            })
            .catch(function (error) {
              console.error("Error removing document: ", error);
            });
        });

      });
     
    }
  
   
  
    function user1() {
      cart.map((i) => {
        console.log(i.doc);
  
        var variable1 = i.user;
  
        updateauth(variable1);
       
      });
      Fetchdata();
  
      alert("Shopping Basket sent to Finance");
      
    }

    function removetocart(item) {
      console.log(item)
      console.log(item.user);
      console.log(item.idAndCode);
      let spanishflu = item.user;
          let collectionRef = db
            .collection("cart")
            .doc(item.user)
            .collection("orders");
            console.log(item.user);
            console.log(item.idAndCode);
      
          collectionRef
            .where("idAndCode", "==", item.idAndCode)
            .get()
            .then((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                doc.ref
                  .delete()
                  .then(() => {
                    console.log("Document successfully deleted!");
                    var spinme = total1();
                    console.log(spinme);
                    console.log(spanishflu);
                    if ( spinme <= 1 ){
                      updateauthspanishflu(spanishflu)
                    }
                  })
                  .catch(function (error) {
                    console.error("Error removing document: ", error);
                  });
              });
            });
        }

        function updateauthspanishflu(variable1) {
          console.log(variable1);
         
          db.collection("Authorisation")
            .where("userid", "in", [variable1])
            .get()
            .then((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                doc.ref
                  .delete()
                  .then(() => {
                  
                    console.log("Authorisation successfully deleted!");
                    console.log(variable1);
                    
                    /////////////////////////////////////////////////
                    /// history.replace("/finance");
                  })
                  .catch(function (error) {
                    console.error("Error removing document: ", error);
                  });
              });
            });
        }
  
  
    function total1() {
      let x1 = 0;
      cart.map((i) => {
        x1 += i.quantity;
      });
      return x1;
    }
  
    function total() {
      let x = 0;
      cart.map((i) => {
        x += i.price;
      });
      return x;
    }
  
    return (
      <div className="container_auth">
        <Helmet>
          <title>Secure Shopping| Postal Monitors</title>
        </Helmet>
  
        <div className="container_auth23">
          <td>{}</td>
          <td>{}</td>
          <h2>Postal Monitors Authorisations </h2>
          <h4>Basket Orders Needing Authorisation </h4>
        </div>
        <div className="container_auth23">
          <table className="table  text-center">
            <thead>
              <tr>
              <th scope="col">#</th>
              <th scope="col">Baskets</th>
              <th scope="col">User Code</th>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col">Patient Number</th>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col">Ward</th>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col">Check</th>
            </tr>
          </thead>
          <tbody>
            {cart1.map((i, index) => (
              <tr key={i.authyn}>
                <th scope="row">{index + 1}</th>
                <th scope="row"></th>
                <td>{i.userid}</td>
                <td>{}</td>
                <td>{}</td>
                <td>{}</td>
                <td>{}</td>
                <td>{i.email.split("@")[0]}</td>
                <td>{}</td>
                <td>{}</td>
                <td>{}</td>
                <td>{i.WardName}</td>
                <td>{}</td>
                <td>{}</td>
              
                <td>{}</td>
                  <td>
                    <button
                      onClick={() => sayHello(i.userid)}
                      className="btn-btn-danger"
                    >
                      Check Basket
                    </button>
                  </td>
                  <td>{}</td>
                  <td>{}</td>
                  <td>
                    <small>{i.id}</small>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
  
        <div className="container_auth123">
          <td>{}</td>
  
          <div className="row mt-3">
            <table className="table  text-center">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col"></th>
                  <th scope="col"></th>
                  <th scope="col"></th>
                  <th scope="col">Product</th>
                  <th scope="col"></th>
                  <th scope="col">Product Name</th>
                  <th scope="col"></th>
                  <th scope="col"></th>
                  <th scope="col">Price</th>
                  <th scope="col"></th>
                  <th scope="col"></th>
                  <th scope="col"></th>
                  <th scope="col"></th>
                  <th scope="col">Size</th>
                  <th scope="col"></th>
                  <th scope="col"></th>
                  <th scope="col"></th>
                  <th scope="col">Store Name</th>
                </tr>
              </thead>
              <tbody>
                {cart.map((i, index) => (
                  <tr key={i.id}>
                    <th scope="row">{index + 1}</th>
                    <td>{}</td>
                    <td>{}</td>
                    <td>{}</td>
                    <th scope="row">
                      <img src={i.image} style={{ width: "8rem" }} />
                    </th>
                    <td>{}</td>
                    <td>{i.title}</td>
                    <td>{}</td>
                    <td>{}</td>
                    <td>{(i.price, Number(i.price).toFixed(2))}</td>
                    <td>{}</td>
                    <td>{}</td>
                    <td>{}</td>
                    <td>{}</td>
                    <td>{i.size}</td>
                    <td>{}</td>
                    <td>{}</td>
                    <td>{}</td>
                    <td>
                      <strong>{i.storename}</strong>
                    </td>
                    <button
                      onClick={() => removetocart(i)}
                      className="btn-btn-danger"
                    >
                      Remove
                    </button>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="row">
            <div className="col text-center">
              <h4>Basket Items Total: {total1()}</h4>
            </div>
            <div className="col text-center">
              <h4>TOTAL: £ {total().toFixed(2)}</h4>
            </div>
  
            <button onClick={() => user1()} className="btn-btn-danger">
              Authorise Items
            </button>
          </div>
        </div>
      </div>
    );
  } //updateauth
export default PostalAuth
import { React, useState } from "react";
import "./FinanceHeader.css";
import { useStateValue } from "./StateProvider";
import { db, auth } from "./firebase";
import { Link, useHistory } from "react-router-dom";

function AuthHeader() {

    const [{ basket }, dispatch] = useStateValue();

  //this code is used to pull all the info through
  const [{ user }] = useStateValue();
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [cart, setCart] = useState([]);

  // const toggling = () => setIsOpen(!isOpen);
  const button = document.querySelector("#button");
  const tooltip = document.querySelector("#tooltip");

  const onOptionClicked = (value) => () => {
    setSelectedOption(value);
    <Link to={"/Authorisation"}> </Link>;
    setIsOpen(false);
    console.log(selectedOption);
  };

  const handleAuthentication1 = () => {
    if (user) {
      auth.signOut();
      history.replace("/Authorisation");
    }
  };

  function total1() {
    let x1 = 0;
    cart.map((i) => {
      x1 += i.quantity;
    });
    return x1;
  }

  return (
    <div>
      <ul
        className="navinatorfine"
       
      >
     
      
       

        <li class="drop-itemfine" >
          <div id="profile11">
            <div
              style={{
                float: "left",
                paddingBottom: "5px",
                paddingRight: "5px",
              }}
            >
              <div
                onClick={handleAuthentication1}
                className="header__optionfine"
              >
                <span className="header__optionLineonefine">
                  Hello, {user ? user?.email.split("@")[0] : "Guest"}{" "}
                </span>

                <span className="header__optionLinetwofine">
                  {user ? "Sign Out" : "Sign In"}
                </span>
              </div>
            </div>
            <div style={{ float: "left" }} className="arrow-down"></div>
          </div>
          <div
            style={{ paddingTop: "125px" }}
            className="dropdown"
          >
            <li>
              <a className="anchor" href="AuthLogin">
                <div
                  onClick={handleAuthentication1}
                  className="header__optionfine"
                >
                  <span className="header__optionLineonefine">
                    Hello, {user ? user?.email.split("@")[0] : "Guest"}{" "}
                  </span>

                  <span className="header__optionLinetwofine">
                    {user ? "Sign Out ?" : "Sign In"}
                  </span>
                </div>
              </a>
            </li>

            <li>
              <a
                className="anchor"
                href="AuthNewPassword"
              >
                <span className="header__optionLinetwofine">
                  {user ? "Change Password" : ""}
                </span>
              </a>
            </li>
          </div>
        </li>

       
      </ul>
    </div>
  );
}


export default AuthHeader;